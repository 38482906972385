import {
  receivedCompanyProfileData,
  getCompanyProfile,
} from 'src/Resources/CompanyProfile/Data'
import { NEW_TESTIMONIAL_UUID_PLACEHOLDER } from '../EditTestimonialsList'
import { didUpdateContent } from '../../../../Edit/Redux/Actions'
import {
  updateTestimonialApi,
  deleteTestimonialApi,
  createTestimonialApi,
} from './Api'

export function addTestimonialCreationFields() {
  return (dispatch, getState) => {
    const newTestimonials = [...getCompanyProfile(getState()).testimonials]
    newTestimonials.push({ id: NEW_TESTIMONIAL_UUID_PLACEHOLDER })

    dispatch(didUpdateContent('testimonials', newTestimonials))
  }
}

export function removeTestimonialCreationFields() {
  return (dispatch, getState) => {
    const newTestimonials = [
      ...getCompanyProfile(getState()).testimonials,
    ].filter(test => test.id !== NEW_TESTIMONIAL_UUID_PLACEHOLDER)
    dispatch(didUpdateContent('testimonials', newTestimonials))
  }
}

export function createTestimonial(testimonial) {
  return (dispatch, getState) => {
    return createTestimonialApi(testimonial)
  }
}

export function updateTestimonial(testimonial) {
  return (dispatch, getState) => {
    return updateTestimonialApi(testimonial)
  }
}

export function deleteTestimonial(testimonialId) {
  return (dispatch, getState) => {
    return deleteTestimonialApi(testimonialId).then(response => {
      if (response.data.deleted === true) {
        const newTestimonials = [
          ...getCompanyProfile(getState()).testimonials,
        ].filter(test => test.id !== testimonialId)
        dispatch(didUpdateContent('testimonials', newTestimonials))
      }
    })
  }
}
