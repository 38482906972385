import React from 'react'
import Grid from '@material-ui/core/Grid'
import { PropTypes } from 'prop-types'
import {
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  container: {
    float: 'right',
    display: 'inline-flex',
    marginBottom: 8,
  },
  title: {
    paddingTop: 14,
    paddingRight: 8,
  },
})
function BookingBufferRow({ classes, availabilityBufferInDays, onChange }) {
  return (
    <Grid container alignItems="flex-end">
      <Grid item xs>
        <div className={classes.container}>
          <Typography variant="subtitle1" className={classes.title}>
            Soonest bookable day
          </Typography>
          <Select
            value={availabilityBufferInDays}
            onChange={event => onChange(event.target.value)}
            input={<OutlinedInput labelWidth={0} />}
          >
            <MenuItem value={0}>Same day</MenuItem>
            <MenuItem value={1}>Next day</MenuItem>
            <MenuItem value={2}>2 days out</MenuItem>
            <MenuItem value={3}>3 days out</MenuItem>
            <MenuItem value={7}>1 week out</MenuItem>
          </Select>
        </div>
      </Grid>
    </Grid>
  )
}

BookingBufferRow.propTypes = {
  availabilityBufferInDays: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(BookingBufferRow)
