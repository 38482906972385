import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { DragSource, DropTarget } from 'react-dnd'
import { compose } from 'redux'
import { updateViewPhotoOrdering, updatePhoto } from './Redux/Actions'
import {
  photoDragSource,
  dragCollect,
  photoDropSource,
  dropCollect,
  DRAGGABLE_PHOTO,
  hoverStyle,
} from './PhotoDragAndDrop'

const styles = {
  tileButton: {
    cursor: 'pointer',
    align: 'center',
  },
  tileButtonHovered: hoverStyle,
  tileImageDragging: {
    opacity: 0.5,
  },
}

function DraggablePhotoItem({
  classes,
  children,
  connectDragSource,
  isDragging,
  connectDropTarget,
  isOver,
}) {
  return connectDropTarget(
    connectDragSource(
      <div
        className={classNames(classes.tileButton, {
          [classes.tileButtonHovered]: isOver,
          [classes.tileImageDragging]: isDragging,
        })}
      >
        {children}
      </div>
    )
  )
}

DraggablePhotoItem.propTypes = {
  photo: PropTypes.shape({
    altText: PropTypes.string,
    original_url: PropTypes.string,
    thumb_url: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onDropBegin: PropTypes.func.isRequired,
  onDropComplete: PropTypes.func.isRequired,
}

const HOCs = compose(
  connect(
    null,
    { updateViewPhotoOrdering, updatePhoto }
  ),
  DropTarget(DRAGGABLE_PHOTO, photoDropSource, dropCollect),
  DragSource(DRAGGABLE_PHOTO, photoDragSource, dragCollect),
  withStyles(styles)
)

export default HOCs(DraggablePhotoItem)
