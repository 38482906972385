import RestAPI from 'src/common/RestApi'

export function uploadPhotoApi(photo) {
  let formData = new FormData()
  formData.append('file', photo)
  return RestAPI.postWithFormData(`/alpha/organization/photos`, formData)
}

export function deletePhotoApi(photoUuid) {
  return RestAPI.deleteWithFormData(`/alpha/organization/photos/${photoUuid}`)
}

export function updatePhotoApi(photoUuid, index) {
  return RestAPI.updateWithFormData(`/alpha/organization/photos/${photoUuid}`, { index })
}

export function getPhotosApi() {
  return RestAPI.get('/alpha/organization/photos/')
}
