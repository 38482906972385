import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import AboutUs from './AboutUs'
import CompanyHighlightsParent from './CompanyHighlights/CompanyHighlightsParent'
import CharityBoxParent from './CharityBoxParent'

function CompanyDescription({ description, inEditMode, descriptionTitle }) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <AboutUs
          description={description}
          inEditMode={inEditMode}
          descriptionTitle={descriptionTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyHighlightsParent inEditMode={inEditMode} />
      </Grid>
      <Grid item xs={12}>
        <CharityBoxParent inEditMode={inEditMode} />
      </Grid>
    </Grid>
  )
}

CompanyDescription.propTypes = {
  description: PropTypes.string,
  descriptionTitle: PropTypes.string,
  inEditMode: PropTypes.bool.isRequired,
}

CompanyDescription.defaultProps = {
  description: '',
  descriptionTitle: '',
  inEditMode: false,
}

export default React.memo(CompanyDescription)
