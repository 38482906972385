import RestAPI from 'src/common/RestApi'

export function updateOrganizationProfileApi(profile) {
  return RestAPI.updateWithFormData('/alpha/organization/profile', profile)
}

export function updateHighlightsApi(highlights) {
  return RestAPI.updateWithFormData('/alpha/organization/website_highlights', { highlights })
}

export function updateCharityApi(charity) {
  return RestAPI.updateWithFormData('/alpha/organization/charity', charity)
}

export function deleteCharityApi() {
  return RestAPI.delete('/alpha/organization/charity')
}
