import React from 'react'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid'
import { formatStartAndEndTime } from 'src/common/StartEndDateTimeParser'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  bookingWindowButton: {
    borderRadius: 4,
  },
})

function BookingWindow({
  selected,
  bookingWindow,
  onClick,
  disabled,
  classes,
}) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={!bookingWindow.available || disabled}
          onClick={() => onClick()}
          className={classes.bookingWindowButton}
        >
          {formatStartAndEndTime(
            bookingWindow.start_time,
            bookingWindow.end_time
          )}
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(BookingWindow)
