import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import Book from './Book'
import { connect } from 'react-redux'
import { updateWebsiteContent } from 'src/Resources/WebsiteContent/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { withStyles } from '@material-ui/core'
import OnlineBookingEnableConfirmationDialog from './OnlineBookingEnableConfirmationDialog'
import OnlineBookingDisableConfirmationDialog from './OnlineBookingDisableConfirmationDialog'
import { publishWebsite } from 'src/components/Edit/Header/Redux/Actions'

const styles = {
  cardMobile: {
    position: 'static',
  },
}

function BookParent({
  inEditMode,
  displayErrorDialog,
  isMobile,
  title,
  showBookingCard,
  updateWebsiteContent,
  publishWebsite,
  classes,
}) {
  const [isEditing, setIsEditting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updatedTitle, setUpdatedTitle] = useState(title)
  const [showEnableConfirmDialog, setShowEnableConfirmDialog] = useState(false)
  const [
    showDisableConfirmationDialog,
    setShowDisableConfirmationDialog,
  ] = useState(false)

  function onSaveClick() {
    setSaving(true)
    updateWebsiteContent({ booking_title: updatedTitle })
      .then(() => {
        setSaving(false)
        setIsEditting(false)
      })
      .catch(() => {
        setSaving(false)
        displayErrorDialog('Failed to update booking title. Please try again.')
      })
  }

  const updateWebsiteContentAndPublish = showBookingCard => {
    updateWebsiteContent({ show_booking_card: showBookingCard }).then(() =>
      publishWebsite()
    )
  }

  const onSwitchToggle = () => {
    if (showBookingCard) {
      setShowDisableConfirmationDialog(true)
    } else {
      updateWebsiteContentAndPublish(true)
      setShowEnableConfirmDialog(true)
    }
  }

  return (
    <div className={isMobile ? classes.cardMobile : ''}>
      <Book
        title={title}
        inEditMode={inEditMode}
        isEditing={isEditing}
        isSaving={saving}
        onEditClick={() => setIsEditting(true)}
        onUpdateTitle={event => setUpdatedTitle(event.target.value)}
        onCancelClick={() => setIsEditting(false)}
        onSaveClick={() => onSaveClick()}
        showBookingCard={showBookingCard}
        onSwitchToggle={onSwitchToggle}
      />
      <OnlineBookingEnableConfirmationDialog
        open={showEnableConfirmDialog}
        onConfirm={() => setShowEnableConfirmDialog(false)}
      />
      <OnlineBookingDisableConfirmationDialog
        open={showDisableConfirmationDialog}
        onCancel={() => setShowDisableConfirmationDialog(false)}
        onConfirm={() => {
          setShowDisableConfirmationDialog(false)
          updateWebsiteContentAndPublish(false)
        }}
      />
    </div>
  )
}

BookParent.propTypes = {
  title: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
}

BookParent.defaultProps = {
  title: 'Book a service call',
  inEditMode: false,
  isMobile: false,
}

export default connect(
  null,
  { updateWebsiteContent, displayErrorDialog, publishWebsite }
)(withStyles(styles)(BookParent))
