import React, { PureComponent } from 'react'
import { Dialog } from '@material-ui/core'
import { connect } from 'react-redux'
import EditCharityBox from './EditCharityBox'
import PropTypes from 'prop-types'
import { updateCharity } from './Redux/Actions.js'

@connect(
  null,
  { updateCharity }
)
export default class EditCharityBoxDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
    }
  }

  submitForm(values) {
    this.setState({ isSaving: true })
    this.props
      .updateCharity(values)
      .then(() => {
        this.props.onSave()
      })
      .finally(() => {
        this.setState({ isSaving: false })
      })
  }

  render() {
    const { open, onClose, initialValues} = this.props

    return (
      <Dialog open={open} onClose={() => onClose()} disableBackdropClick>
        <EditCharityBox
          onClose={() => onClose()}
          isSaving={this.state.isSaving}
          submitForm={values => this.submitForm(values)}
          initialValues={initialValues}
        />
      </Dialog>
    )
  }
}

EditCharityBoxDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditCharityBoxDialog.defaultProps = {
  open: false,
  onClose: () => {}
}
