import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CharityBoxIcon = props => (
  <SvgIcon
    width="34"
    height="34"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-3h30v30H-2z" />
      <path
        d="M18.625.75C16.45.75 14.363 1.763 13 3.362 11.637 1.762 9.55.75 7.375.75 3.525.75.5 3.775.5 7.625.5 12.35 4.75 16.2 11.188 22.05L13 23.687l1.813-1.65C21.25 16.2 25.5 12.35 25.5 7.626c0-3.85-3.025-6.875-6.875-6.875zm-5.5 19.438l-.125.125-.125-.125C6.925 14.8 3 11.238 3 7.624c0-2.5 1.875-4.375 4.375-4.375 1.925 0 3.8 1.237 4.463 2.95h2.337c.65-1.713 2.525-2.95 4.45-2.95 2.5 0 4.375 1.875 4.375 4.375 0 3.613-3.925 7.175-9.875 12.563z"
        fill="#000"
      />
      <g>
        <path
          d="M17.59 19.89c.7-.71 1.4-1.35 1.71-1.22.5.2 0 1.03-.3 1.52-.25.42-2.86 3.89-2.86 6.31 0 1.28.48 2.34 1.34 2.98.75.56 1.74.73 2.64.46 1.07-.31 1.95-1.4 3.06-2.77 1.21-1.49 2.83-3.44 4.08-3.44 1.63 0 1.65 1.01 1.76 1.79-3.78.64-5.38 3.67-5.38 5.37 0 1.7 1.44 3.09 3.21 3.09 1.63 0 4.29-1.33 4.69-6.1H34v-2.5h-2.47c-.15-1.65-1.09-4.2-4.03-4.2-2.25 0-4.18 1.91-4.94 2.84-.58.73-2.06 2.48-2.29 2.72-.25.3-.68.84-1.11.84-.45 0-.72-.83-.36-1.92.35-1.09 1.4-2.86 1.85-3.52.78-1.14 1.3-1.92 1.3-3.28 0-2.17-1.64-2.86-2.51-2.86-1.32 0-2.47 1-2.72 1.25-.36.36-.66.66-.88.93l1.75 1.71zm9.29 11.66c-.31 0-.74-.26-.74-.72 0-.6.73-2.2 2.87-2.76-.3 2.69-1.43 3.48-2.13 3.48z"
          fill="#000"
        />
        <path d="M13 13h24v24H13z" />
      </g>
    </g>
  </SvgIcon>
)

export default CharityBoxIcon
