import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { cityStateFormatter } from './CityStateFormatter'

const styles = {
  quoteText: {
    fontStyle: 'italic',
    paddingBottom: 24,
  },
  authorText: {
    fontWeight: 'bold',
  },
}

function SingleTestimonial({ classes, testimonial }) {
  return (
    <div>
      <Typography className={classes.quoteText} variant="body1" align="center">
        "{testimonial.quote}"
      </Typography>
      <Typography align="center" variant="h6" className={classes.authorText}>
        {testimonial.author}
      </Typography>
      <Typography align="center" variant="body1">
        {cityStateFormatter(testimonial.city, testimonial.state)}
      </Typography>
    </div>
  )
}

SingleTestimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
}

export default withStyles(styles)(SingleTestimonial)
