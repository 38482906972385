import RestAPI from 'src/common/RestApi'

export function createFAQApi(data) {
  return RestAPI.postWithFormData(`/alpha/organization/faqs`, data)
}

export function updateFAQApi(data) {
  return RestAPI.updateWithFormData(
    `/alpha/organization/faqs/${data.uuid}`,
    data
  )
}

export function deleteFAQApi(faqUuid) {
  return RestAPI.deleteWithFormData(
    `/alpha/organization/faqs/${faqUuid}`
  )
}
