import { reducerRegistry } from 'data-fetcher'

export const EPHEMERAL_NAMESPACE = 'emphemeralUI'

const LAUNCH_ACTION = `${EPHEMERAL_NAMESPACE}/LAUNCH_COMPONENT`
const CLEAR_ACTION = `${EPHEMERAL_NAMESPACE}/CLEAR_COMPONENT`
export const ERROR_DIALOG = 'errorDialog'

export function displayEphemeralComponent(name, data) {
    if (reducerRegistry.isReducerPresent(EPHEMERAL_NAMESPACE) === false) {
        reducerRegistry.register(EPHEMERAL_NAMESPACE, createReducer)
    }

    const payload = {}

    payload[name] = data

    return {
        type: LAUNCH_ACTION,
        payload: payload
    }
}

export function clearComponent(name) {
    return {
        type: CLEAR_ACTION,
        name: name
    }
}

export const displayErrorDialog = message =>
  displayEphemeralComponent(ERROR_DIALOG, { message })

const createReducer = (state = {}, action) => {
    switch (action.type) {
        case LAUNCH_ACTION:
            return Object.assign({}, state, action.payload)
        case CLEAR_ACTION:
            const newState = Object.assign({}, state)

            delete newState[action.name]

            return newState
        default:
            return state
    }
}