import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withMobileDialog,
  withStyles,
} from '@material-ui/core'
import { CancelSaveButtons } from 'housecall-ui'
import { isPresent } from 'src/common/ObjectHelper'
import { ImageCarouselWithNewImageCropper } from 'housecall-ui'
import 'cropperjs/dist/cropper.css'
import PhotographyRequest from 'src/common/PhotographyRequest'
import HousecallDoor from '../../../../images/HousecallDoor'
import { newPhotoRequest } from '../../../Redux/SendToSlack/Actions'

const styles = {
  cropper: {
    paddingTop: 16
  },
  icon: {
    width: 32,
    height: 32,
  },
}

@connect(null, { newPhotoRequest })
@withStyles(styles)
class EditBannerDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedImageFromChild: {},
      saving: false,
      disableBannerRequestButton: null,
    }
  }

  componentDidMount() {
    if (window && window.localStorage) {
      this.setState({
        disableBannerRequestButton: !!window.localStorage.getItem(
          'disableBannerRequestButton'
        )
      })
    }
  }

  handleSaveCroppedImage(croppedImageBlob, orginalImageBlob) {
    this.setState({ saving: true })
    const bannerToUpdate = isPresent(croppedImageBlob)
      ? croppedImageBlob
      : this.state.selectedImageFromChild.original_url
    this.props
      .updateBanner(bannerToUpdate)
      .then(() => this.setState({ saving: false }))
      .then(() => {
        if (!this.props.hasError) {
          this.props.onClose(orginalImageBlob)
        }
      })
  }

  render() {
    const {
      aspectRatio,
      open,
      onClose,
      defaultIndustryPhotos,
      fullScreen,
      bannerUrls,
      classes,
      newPhotoRequest
    } = this.props

    const { saving, disableBannerRequestButton } = this.state

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={() => onClose()}
          maxWidth="md"
          fullWidth
          fullScreen={fullScreen}
          disableBackdropClick
        >
          <DialogTitle>Hero image</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <PhotographyRequest
                  icon={<HousecallDoor className={classes.icon} />}
                  textTitle="Get a professional photo pack"
                  textBody="Hire a photographer to take professional photos of you and your team doing what you do best."
                  disabled={disableBannerRequestButton}
                  ctaText="Book now"
                  onClick={() => {
                    this.setState({ disableBannerRequestButton: true })
                    newPhotoRequest()
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.cropper}>
                <ImageCarouselWithNewImageCropper
                  aspectRatio={aspectRatio}
                  currentSelectedImage={bannerUrls}
                  defaultIndustryPhotos={defaultIndustryPhotos}
                  onSaveCroppedImage={(croppedImageBlob, orginalImageBlob) =>
                    this.handleSaveCroppedImage(croppedImageBlob, orginalImageBlob)
                  }
                  onSelectImage={selectedImageFromChild => {
                    this.setState({ selectedImageFromChild })
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CancelSaveButtons
              saveButtonVariant="text"
              onCancelClick={() => onClose()}
              onSaveClick={() => this.handleSaveCroppedImage()}
              isSaving={saving}
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

EditBannerDialog.propTypes = {
  bannerOriginalUrl: PropTypes.string,
  bannerThumbUrl: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  aspectRatio: PropTypes.number
}

EditBannerDialog.defaultProps = {
  open: false,
}

export default withMobileDialog()(EditBannerDialog)
