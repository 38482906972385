import {
  updateOrganizationProfileApi,
  updateHighlightsApi,
  updateCharityApi,
  deleteCharityApi,
} from './Api'
import { didUpdateContent } from 'src/components/Edit/Redux/Actions'
import { validatePresence } from 'src/common/Form/FormValidations'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'

export function validate(values) {
  const errors = {}
  const { description, title } = values

  errors.description = validatePresence(description)
  errors.title = validatePresence(title)

  return errors
}

export function updateProfile(profile) {
  return dispatch => {
    return updateOrganizationProfileApi(profile).then(response => {
      dispatch(didUpdateContent('organization', response.data))
    })
  }
}

export function updateHighlights(highlights) {
  return dispatch => {
    return updateHighlightsApi(highlights)
      .then(response => {
        dispatch(didUpdateContent('highlights', response.data))
      })
      .catch(error => {
        dispatch(
          displayErrorDialog('Failed to update highlights. Please try again.')
        )
      })
  }
}

export function dispatchHighlightsDidUpdate(highlights) {
  return dispatch => {
    return dispatch(didUpdateContent('highlights', highlights))
  }
}

export function updateCharity(charity) {
  return dispatch => {
    return updateCharityApi(charity)
      .then(response => {
        dispatch(didUpdateContent('charity', response.data))
      })
      .catch(() => {
        dispatch(
          displayErrorDialog('Failed to update this charity. Please try again.')
        )
      })
  }
}

export function deleteCharity() {
  return dispatch => {
    return deleteCharityApi()
      .then(response => {
        dispatch(didUpdateContent('charity', response.data))
      })
      .catch(() => {
        dispatch(
          displayErrorDialog('Failed to delete this charity. Please try again.')
        )
      })
  }
}
