import { FetchData } from 'data-fetcher'
import { connect } from 'react-redux'
import { compose } from 'redux'
import EditBannerDialog from './EditBannerDialog'
import { updateBanner } from '../Redux/Actions'
import {
  fetchDefaultIndustryPhotos,
  getDefaultIndustryBannerPhotosData,
  BANNER_KIND_DESKTOP,
} from '../Redux/Data'
import { getErrorDialogMessage } from '../../../../common/ErrorDialog/Data'

export default compose(
  FetchData(fetchDefaultIndustryPhotos, true),
  connect(
    state => ({
      defaultIndustryPhotos: getDefaultIndustryBannerPhotosData(state),
      hasError: !!getErrorDialogMessage(state),
    }),
    dispatch => ({
      updateBanner: bannerFile =>
        dispatch(updateBanner(bannerFile, BANNER_KIND_DESKTOP)),
    })
  )
)(EditBannerDialog)
