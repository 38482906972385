import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import SectionWithoutHeader from '../Sections/SectionWithoutHeader'
import CharityBox from './CharityBox'
import EditCharityBoxDialog from './EditCharityBoxDialog'
import { connect } from 'src/common/connectWithPageContext'
import { isBlank } from 'src/common/ObjectHelper'
import { deleteCharity } from './Redux/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import {
  getCharityDescription,
  getCharityTitle,
  getCharityUrl,
} from 'src/Resources/CompanyProfile/Data'
import { ConfirmationDialog } from 'housecall-ui'

@connect(
  state => ({
    initialValues: {
      description: getCharityDescription(state),
      title: getCharityTitle(state),
      url: getCharityUrl(state),
    },
  }),
  { deleteCharity, displayErrorDialog }
)
export default class CharityBoxParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editDialogOpen: false,
      showConfirmation: false,
    }
  }

  render() {
    const { inEditMode, initialValues, deleteCharity } = this.props
    const { editDialogOpen, showConfirmation } = this.state
    const showAddButton = isBlank(initialValues.title)

    return (
      <Fragment>
        <EditCharityBoxDialog
          open={editDialogOpen}
          onClose={() => this.setState({ editDialogOpen: false })}
          onSave={() =>
            this.setState({ editDialogOpen: false })
          }
          initialValues={initialValues}
        />
        <SectionWithoutHeader
          backgroundVariant="light"
          showDivider={true}
          addButtonText="ADD A CHARITY"
          showAddButton={inEditMode && showAddButton}
          deleteEnable={true}
          onAddButtonClick={() => this.setState({ editDialogOpen: true })}
          canEdit={inEditMode && !showAddButton}
          onEditClick={() => this.setState({ editDialogOpen: true })}
          onDeleteClick={() => this.setState({ showConfirmation: true })}
        >
          {!showAddButton && (
            <CharityBox
              description={initialValues.description}
              title={initialValues.title}
              url={initialValues.url}
            />
          )}
        </SectionWithoutHeader>
        <ConfirmationDialog
          open={showConfirmation}
          message_title="Are you sure you want to delete this charity?"
          message_body=""
          cancelButtonMessage="No"
          confirmButtonMessage="Yes"
          onCancelClick={() => this.setState({ showConfirmation: false })}
          onConfirmClick={() => {
            deleteCharity()
            this.setState({ showConfirmation: false })
          }}
        />
      </Fragment>
    )
  }
}

CharityBoxParent.propTypes = {
  inEditMode: PropTypes.bool,
}

CharityBoxParent.defaultProps = {
  inEditMode: false,
}
