import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { getCurrentPro, proIsLoggedIn } from './Data'
import { fetchCurrentProApi } from './Api'
import { reducerRegistry } from 'data-fetcher'
import { isBlank, isPresent } from '../ObjectHelper'
import LoginDialog from '../../components/Edit/Login/LoginDialog'
import { getOrganizationProfile } from '../../Resources/Organization/Actions'

const CURRENT_PRO = 'currentPro'
const DATA_FETCHED = 'DATA_FETCHED'

export default function CurrentPro(renderAfterLoading) {
  return WrappedComponent => {
    @connect(
      state => ({ pro: getCurrentPro(state), proIsLoggedIn: proIsLoggedIn(state) }),
      { updatePro, fetchPro, loadingPro }
    )
    class CurrentPro extends PureComponent {
      constructor(props) {
        super(props)
        if (reducerRegistry.isReducerPresent(CURRENT_PRO) === false) {
          reducerRegistry.register(CURRENT_PRO, createReducer({}, CURRENT_PRO))
        }
      }

      componentDidMount() {
        if (isBlank(this.props.pro)) {
          this.props.fetchPro()
        }
      }

      render() {
        const { pro, proIsLoggedIn, ...rest } = this.props
        if (isPresent(pro) && pro.loading && renderAfterLoading) {
          return <div />
        } else if (!proIsLoggedIn) {
          return <LoginDialog />
        } else {
          return <WrappedComponent loading={true} {...rest} />
        }
      }
    }

    return CurrentPro
  }
}

function fetchPro() {
  return dispatch => {
    dispatch(loadingPro())
    fetchCurrentProApi()
      .then(response => {
        return dispatch(getOrganizationProfile())
          .then(() => dispatch(updatePro(response.data)))
      })
      .catch(error => {
        dispatch(unauthedPro())
      })
  }
}

export function updatePro(pro) {
  return {
    type: `${DATA_FETCHED}_${CURRENT_PRO}`,
    pro: { loading: false, ...pro },
  }
}

export function loadingPro() {
  return { type: `${DATA_FETCHED}_${CURRENT_PRO}`, pro: { loading: true } }
}

export function unauthedPro() {
  return {
    type: `${DATA_FETCHED}_${CURRENT_PRO}`,
    pro: { uuid: 0, loading: false },
  }
}

const createReducer = data => (state = data, action) => {
  switch (action.type) {
    case `${DATA_FETCHED}_${CURRENT_PRO}`:
      return Object.assign({}, state, action.pro)
    default:
      return state
  }
}
