import { withStyles } from '@material-ui/core'
import React from 'react'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import Divider from 'housecall-ui/dist/library/DataDisplay/Divider'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import EditIcon from '@material-ui/icons/Edit'
import Grid from 'housecall-ui/dist/library/Layout/Grid'

const styles = theme => ({
  elementSpacing: {
    marginRight: theme.spacing.unit,
  },
  rightAlignItem: {
    marginLeft: 'auto'
  }
})

function EditBannerPreview({ children, onClick, classes, icon, title }) {
  return (
    <Grid container alignItems="center">
      <Grid item className={classes.elementSpacing}>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid item>
        {icon}
      </Grid>
      <Grid item className={classes.rightAlignItem}>
        <Button
          color="primary"
          onClick={onClick}
        >
          <EditIcon className={classes.elementSpacing} />
          Edit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(EditBannerPreview)
