import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import { SERVICE_AREA_FORM } from './ServiceAreaInEdit'
import PropTypes from 'prop-types'
import { Typography, Grid, Button } from '@material-ui/core'
import FormTextField from 'src/common/Form/FormTextField'

@connect(
  state => ({
    radius: formValueSelector(SERVICE_AREA_FORM)(state, 'radius'),
  }),
  { change }
)
export default class ServiceAreaEditRadius extends PureComponent {
  handleRadiusChange(newRadius) {
    const { onRadiusChange, change } = this.props
    change(SERVICE_AREA_FORM, 'radius', Number(newRadius))
    onRadiusChange(Number(newRadius))
  }

  render() {
    const { isPolygonEdited, onClearMap } = this.props
    return (
      <Grid container spacing={16} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="subtitle1">Enter service radius</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormTextField
            name="radius"
            type="number"
            disabled={isPolygonEdited}
            onChange={event => {
              this.handleRadiusChange(event.target.value)
            }}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle1">miles</Typography>
        </Grid>
        {isPolygonEdited && (
          <Grid item xs={6} container justify="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClearMap()}
              >
                Clear map
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

ServiceAreaEditRadius.propTypes = {
  onRadiusChange: PropTypes.func.isRequired,
  onClearMap: PropTypes.func.isRequired,
  isPolygonEdited: PropTypes.bool,
}

ServiceAreaEditRadius.defaultProps = {
  isPolygonEdited: false,
}
