import { isPresent } from '../ObjectHelper'

export function getErrorMessage(error) {
  if (
    isPresent(error.response) &&
    isPresent(error.response.data) &&
    isPresent(error.response.data.error)
  ) {
    return error.response.data.error.message || error.response.data.error
  } else {
    return error.message
  }
}
