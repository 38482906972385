import {
  validatePresence,
  emailFormValidator,
  phoneNumberFormValidator,
} from 'src/common/Form/FormValidations'
import { publishWebsiteApi } from './Api.js'
import { logoutApi } from './Api'

export function validate(values) {
  const errors = {}
  const { fullName, mobilePhone, email, companyName } = values

  errors.fullName = validatePresence(fullName)
  errors.mobilePhone =
    validatePresence(mobilePhone) || phoneNumberFormValidator(mobilePhone)
  errors.email = validatePresence(email) || emailFormValidator(email)
  errors.companyName = validatePresence(companyName)

  return errors
}

export function logout() {
  return logoutApi().then(() => {
    location.reload()
  })
}

export function publishWebsite() {
  return () => {
    return publishWebsiteApi()
  }
}
