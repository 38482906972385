import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import PaymentHighlights from './PaymentHighlights'
import PaymentHighlightsEditDialog from './PaymentHighlightsEditDialog'
import {
  getPaymentHighlights,
  getCompanyHighlights,
} from 'src/Resources/CompanyProfile/Highlights/Data'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import SectionWithoutHeader from '../../Sections/SectionWithoutHeader'
import { updateHighlights } from '../../CompanyDescription/Redux/Actions'

@connect(
  state => ({
    paymentHighlights: getPaymentHighlights(state),
    companyHighlights: getCompanyHighlights(state),
  }),
  { updateHighlights }
)
export default class PaymentHighlightsParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false,
    }
  }

  openEditDialog() {
    this.setState({ isDialogOpen: true })
  }

  closeEditDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { inEditMode, companyHighlights, paymentHighlights } = this.props
    return (
      <Fragment>
        <PaymentHighlightsEditDialog
          open={this.state.isDialogOpen}
          onClose={() => this.closeEditDialog()}
          companyHighlights={companyHighlights}
          paymentHighlights={paymentHighlights}
        />
        <SectionWithoutHeader
          showDivider={false}
          addButtonText="ADD A PAYMENT OPTION"
          showAddButton={inEditMode && isBlank(paymentHighlights)}
          id="payment options"
          onAddButtonClick={() => this.openEditDialog()}
          canEdit={inEditMode && isPresent(paymentHighlights)}
          onEditClick={() => this.openEditDialog()}
        >
          {isPresent(paymentHighlights) && (
            <PaymentHighlights paymentHighlights={paymentHighlights} />
          )}
        </SectionWithoutHeader>
      </Fragment>
    )
  }
}

PaymentHighlightsParent.propTypes = {
  companyHighlights: PropTypes.arrayOf(PropTypes.object),
  paymentHighlights: PropTypes.arrayOf(PropTypes.object),
  inEditMode: PropTypes.bool.isRequired,
}

PaymentHighlightsParent.defaultProps = {
  companyHighlights: [],
  paymentHighlights: [],
  inEditMode: false,
}
