import React from 'react'
import PropTypes from 'prop-types'
import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory'
import { stringifyLocation } from 'src/common/StringifyLocation'
import { Grid, Typography } from '@material-ui/core'

export default function PhysicalAddress({ address }) {
  let location = {...address, postal_code: address.zip}
  return (
    <Grid container>
      <Grid item xs={1}>
        <StoreMallDirectory />
      </Grid>
      <Grid item xs>
        <Typography>{stringifyLocation(location)}</Typography>
      </Grid>
    </Grid>
  )
}

PhysicalAddress.propTypes = {
  address: PropTypes.object.isRequired,
}
