import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isPresent } from 'src/common/ObjectHelper'
import { getErrorMessage } from 'src/common/ErrorDialog/ErrorHandler'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import UploadPhotoButtonWithDrop from './UploadPhotoButtonWithDrop'
import PhotoDialog from 'housecall-ui/dist/core/PhotoGridList/PhotoDialog'
import PhotoItem from './PhotoItem'
import DraggablePhotoItem from './DraggablePhotoItem'
import Photo from './Photo'

const styles = {
  progressContainer: {
    width: 110,
    height: 110,
  },
}

@withStyles(styles)
export default class PhotoGridListParent extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      focusedImage: {},
      focusedImageIndex: -1,
      disableBack: false,
      disableForward: false,
      isSubmitting: false,
    }
  }

  handlePhotoUpload(event) {
    this.setState({ isSubmitting: true })
    const photoList = Array.from(event.target.files)
    Promise.all(
      photoList.map(photo => {
        return this.props
          .uploadPhoto(photo)
          .then(() => undefined)
          .catch(error => ({ error: getErrorMessage(error) }))
      })
    )
      .then(results => results.filter(result => isPresent(result) && result.hasOwnProperty('error')))
      .then(errorMessages => errorMessages.map(errorMessage => errorMessage.error))
      .then(errors => {
        this.props.handlePhotoUploadError(errors)
        this.setState({ isSubmitting: false })
      })
  }

  handlePhotoDelete(photo) {
    this.setState({ isSubmitting: true })
    this.props.deletePhoto(photo).finally(() => this.setState({ isSubmitting: false }))
    this.setState({ focusedImage: {} })
  }

  onBackClick(photos, focusedImageIndex) {
    let newState = {
      disableBack: this.shouldBackBeDisabled(focusedImageIndex - 1),
      disableForward: this.shouldForwardBeDisabled(photos.length, focusedImageIndex - 1),
    }
    if (focusedImageIndex >= 0) {
      newState = Object.assign({}, newState, {
        focusedImage: photos[focusedImageIndex - 1],
        focusedImageIndex: focusedImageIndex - 1,
      })
    }
    this.setState(newState)
  }

  onForwardClick(photos, focusedImageIndex) {
    let newState = {
      disableBack: this.shouldBackBeDisabled(focusedImageIndex + 1),
      disableForward: this.shouldForwardBeDisabled(photos.length, focusedImageIndex + 1),
    }
    if (focusedImageIndex < photos.length - 1) {
      newState = Object.assign({}, newState, {
        focusedImage: photos[focusedImageIndex + 1],
        focusedImageIndex: focusedImageIndex + 1,
      })
    }
    this.setState(newState)
  }

  shouldBackBeDisabled(index) {
    return index <= 0 ? true : false
  }

  shouldForwardBeDisabled(length, index) {
    return index >= length - 1 ? true : false
  }

  buildPhotoTiles(photos) {
    let photoTiles = []
    photos.map((photo, index) => {
      photoTiles.push(
        <Grid
          item
          key={photo.id}
          onClick={() => {
            this.setState({
              disableBack: this.shouldBackBeDisabled(index),
              disableForward: this.shouldForwardBeDisabled(photos.length, index),
              focusedImage: photo,
              focusedImageIndex: index,
            })
          }}
        >
          {this.props.canEdit && !this.state.isSubmitting ? (
            <DraggablePhotoItem
              index={index}
              photo={photo}
              onDropBegin={() => this.setState({ isSubmitting: true })}
              onDropComplete={() => this.setState({ isSubmitting: false })}
            >
              <Photo photo={photo} />
            </DraggablePhotoItem>
          ) : (
            <PhotoItem isSubmitting={this.state.isSubmitting}>
              <Photo photo={photo} />
            </PhotoItem>
          )}
        </Grid>
      )
    })

    return photoTiles
  }

  render() {
    const { photos, canEdit, classes } = this.props
    const { focusedImage, focusedImageIndex, disableBack, disableForward, isSubmitting } = this.state

    return (
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} container spacing={40}>
          {this.buildPhotoTiles(photos)}
          {isSubmitting && (
            <Grid item>
              <Grid container alignItems='center' justify='center' className={classes.progressContainer}>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          )}
          {canEdit && !isSubmitting && (
            <Grid item>
              <UploadPhotoButtonWithDrop
                index={photos.length}
                handlePhotoUpload={event => {
                  this.handlePhotoUpload(event)
                }}
                onDropBegin={() => this.setState({ isSubmitting: true })}
                onDropComplete={() => this.setState({ isSubmitting: false })}
              />
            </Grid>
          )}
        </Grid>
        <PhotoDialog
          focusedImage={focusedImage}
          open={isPresent(focusedImage)}
          onClose={() => this.setState({ focusedImage: {} })}
          onDelete={photo => this.handlePhotoDelete(photo)}
          canEdit={canEdit}
          onBackClick={() => this.onBackClick(photos, focusedImageIndex)}
          onForwardClick={() => this.onForwardClick(photos, focusedImageIndex)}
          disableBack={disableBack}
          disableForward={disableForward}
        />
      </Grid>
    )
  }
}

PhotoGridListParent.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      altText: PropTypes.string,
      original_url: PropTypes.string,
      thumb_url: PropTypes.string,
    })
  ),
  canEdit: PropTypes.bool,
  uploadPhoto: PropTypes.func,
  deletePhoto: PropTypes.func,
  handlePhotoUploadError: PropTypes.func,
}

PhotoGridListParent.defaultProps = {
  photos: [],
  canEdit: false,
  uploadPhoto: () => {},
  deletePhoto: () => {},
  handlePhotoUploadError: () => {},
}
