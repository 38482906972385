import React from 'react'
import {
  Toolbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { PropTypes } from 'prop-types'
import { isPresent } from './ObjectHelper'

const styles = {
  dialog: {
    zIndex: 100,
  },
  content: {
    paddingTop: 24,
  },
}

function FullScreenDialog({
  classes,
  dialogOpen,
  onClose,
  dialogContent,
  dialogTitle,
  actions,
}) {
  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={dialogOpen}
      onClose={() => onClose()}
    >
      <Toolbar>
        <IconButton onClick={() => onClose()}>
          <CloseIcon color="inherit" />
        </IconButton>
      </Toolbar>
      {isPresent(dialogTitle) && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent className={classes.content}>{dialogContent}</DialogContent>
      {isPresent(actions) && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

FullScreenDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  actions: PropTypes.element,
  dialogContent: PropTypes.element,
}

export default withStyles(styles)(FullScreenDialog)
