import RestAPI from 'src/common/RestApi'

export function publishWebsiteApi() {
  return RestAPI.postWithFormData(`/alpha/organization/website/deploys`, {})
}

export function logoutApi() {
  return RestAPI.postWithFormData('/alpha/pro/logout', {})
}

