import { createSelector } from 'reselect'
import { getCompanyProfile } from '../../CompanyProfile/Data'
import { getCompanyName, getAddressCity } from '../../Organization/Data'

const getPhotos = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.photos || []
  }
)

export const getPhotosWithAltText = createSelector(
  getCompanyName,
  getAddressCity,
  getPhotos,
  (companyName, city, photos) => {
    const updatePhotosList = photos.map((photo, index) => {
      photo.altText = `${companyName} ${city} ${index}`
      return photo
    })
    return updatePhotosList
  }
)
