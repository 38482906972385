import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { COMPANY_HIGHLIGHTS } from '../Redux/Data'

function chooseDisplayText(highlight) {
  const highlightType = highlight.name
  let displayText = ''
  if (highlightType === 'start_year') {
    const currentYear = new Date().getFullYear()
    const startYear = Number(highlight.highlight_value)
    if (startYear == currentYear.toString()) {
      displayText = '< 1 year in business'
    } else if (startYear == (currentYear - 1).toString()) {
      displayText = '1 year in business'
    } else {
      displayText = `${currentYear - startYear} years in business`
    }
  } else if (highlightType === 'license') {
    displayText = `License # ${highlight.highlight_value}`
  } else {
    displayText = COMPANY_HIGHLIGHTS[highlightType].text
  }
  return displayText
}

function CompanyHighlight({ highlight }) {
  let icon = COMPANY_HIGHLIGHTS[highlight.name].icon
  let displayText = chooseDisplayText(highlight)

  return (
    <Grid container alignItems="center" spacing={8} wrap="nowrap">
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography variant="body1" noWrap>{displayText}</Typography>
      </Grid>
    </Grid>
  )
}

CompanyHighlight.propTypes = {
  highlight: PropTypes.object,
}

CompanyHighlight.defaultProps = {
  highlight: {},
}

export default React.memo(CompanyHighlight)
