import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import BookParent from './Book/BookParent'
import PaymentHighlightsParent from './Book/PaymentHighlights/PaymentHighlightsParent'
import { isIE } from 'src/common/DetectBrowser'

const styles = {
  rightPanePosition: {
    position: 'relative',
    top: -120,
  },
}

function RightPaneContent({ inEditMode, title, showBookingCard, classes }) {
  return (
    <Grid
      container
      className={isIE() ? '' : classes.rightPanePosition}
      spacing={40}
    >
      <Grid item xs={12}>
        <BookParent
          title={title}
          inEditMode={inEditMode}
          showBookingCard={showBookingCard}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentHighlightsParent inEditMode={inEditMode} />
      </Grid>
    </Grid>
  )
}

RightPaneContent.propTypes = {
  title: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
}

RightPaneContent.defaultProps = {
  title: '',
  inEditMode: false,
  isMobile: false,
}

export default React.memo(withStyles(styles)(RightPaneContent))
