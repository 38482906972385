import React, { PureComponent } from 'react'
import FormTextField from 'src/common/Form/FormTextField'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { validate } from './Redux/Actions.js'
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { CancelSaveButtons } from 'housecall-ui'

const CHARITY_BOX_FORM = 'CHARITY_BOX_FORM'

@reduxForm({
  form: CHARITY_BOX_FORM,
  validate,
})
export default class EditCharityBox extends PureComponent {
  render() {
    const { isSaving, submitForm, handleSubmit, onClose } = this.props
    return (
      <form onSubmit={handleSubmit(values => submitForm(values))}>
        <DialogTitle>Your charity</DialogTitle>
        <DialogContent>
          <FormTextField
            name="title"
            label="Charity title"
            margin="normal"
            variant="outlined"
            disabled={isSaving}
            inputProps={{ maxLength: 80 }}
            fullWidth
            autoFocus
          />
          <FormTextField
            name="url"
            label="Charity url"
            margin="normal"
            variant="outlined"
            disabled={isSaving}
            fullWidth
          />
          <FormTextField
            name="description"
            label="Charity description"
            margin="normal"
            variant="outlined"
            disabled={isSaving}
            inputProps={{ maxLength: 240 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <CancelSaveButtons
            saveButtonVariant="text"
            onCancelClick={() => onClose()}
            disabled={isSaving}
          />
        </DialogActions>
      </form>
    )
  }
}

EditCharityBox.propTypes = {
  onClose: PropTypes.func,
  isSaving: PropTypes.bool,
  submitForm: PropTypes.func,
  initialValues: PropTypes.object,
}

EditCharityBox.defaultProps = {
  submitForm: () => {},
  onClose: () => {},
  isSaving: false,
  initialValues: {},
}
