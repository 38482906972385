import { loginApi, resetPasswordApi } from './Api'
import { receivedServerData } from 'data-fetcher'
import { updatePro } from 'src/common/CurrentPro/CurrentPro'
import { getOrganizationProfile } from '../Organization/Actions'

const LOGIN_DIALOG_STATE = 'loginDialogOpen'

export const login = (email, password) => (dispatch) => (
  loginApi(email, password)
    .then(response => (
      dispatch(getOrganizationProfile())
        .then(() => dispatch(updatePro(response.data)))
        .then(() => dispatch(closeLoginDialog()))
    ))
)

export function resetPassword(email) {
  return dispatch => {
    return resetPasswordApi(email).catch(() => {})
  }
}

export function closeLoginDialog() {
  return dispatch => {
    dispatch(receivedServerData({ open: false }, LOGIN_DIALOG_STATE))
  }
}

export function openLoginDialog() {
  return dispatch => {
    dispatch(receivedServerData({ open: true }, LOGIN_DIALOG_STATE))
  }
}
