import { uploadPhotoApi, deletePhotoApi, updatePhotoApi, getPhotosApi } from './Api'
import { didUpdateContent } from '../../../Edit/Redux/Actions'
import { getCompanyProfile } from 'src/Resources/CompanyProfile/Data'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { getErrorMessage } from 'src/common/ErrorDialog/ErrorHandler'

export function uploadPhoto(photo) {
  return (dispatch, getState) => {
    return uploadPhotoApi(photo).then(response => {
      var newPhotos = [...getCompanyProfile(getState()).photos]
      newPhotos.push(response.data)
      dispatch(didUpdateContent('photos', newPhotos))
    })
  }
}

export function deletePhoto(photo) {
  return (dispatch, getState) => {
    return deletePhotoApi(photo.id)
      .then(response => {
        const newPhotos = [...getCompanyProfile(getState()).photos].filter(element => element.id != photo.id)
        dispatch(didUpdateContent('photos', newPhotos))
      })
      .catch(error => {
        dispatch(displayErrorDialog('Failed to delete image. Please try again.'))
      })
  }
}

export function updatePhoto(photo, index) {
  return dispatch => {
    return updatePhotoApi(photo.id, index)
      .catch(error => {
        dispatch(displayErrorDialog(getErrorMessage(error)))
      })
      .finally(() => {
        return getPhotosApi().then(response => {
          dispatch(didUpdateContent('photos', response.data.data))
        })
      })
  }
}

export function updateViewPhotoOrdering(photo, index) {
  return (dispatch, getState) => {
    const orderedPhotos = [...getCompanyProfile(getState()).photos].filter(element => element.id != photo.id)
    orderedPhotos.splice(index, 0, photo)
    dispatch(didUpdateContent('photos', orderedPhotos))
  }
}
