import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PoweredIcon = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="150"
    viewBox="0 0 462.48 95.81"
    {...props}
  >
    <title>Powered by Housecall Pro</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon points="66.18 60.75 88.52 60.75 88.52 37.94 96.33 37.94 96.33 89.78 88.52 89.78 88.52 68.09 66.18 68.09 66.18 89.78 58.37 89.78 58.37 37.94 66.18 37.94 66.18 60.75" />
        <g className="cls-2">
          <path
            d="M103.72,73.22a16.24,16.24,0,0,1,5.06-12,17.85,17.85,0,0,1,24.75,0,16.63,16.63,0,0,1,5,12.27A16.56,16.56,0,0,1,133.5,85.8,17.3,17.3,0,0,1,121,90.73a16.61,16.61,0,0,1-12.31-5,16.92,16.92,0,0,1-5-12.48m7.71.14a11,11,0,0,0,2.62,7.74,10.16,10.16,0,0,0,14.15,0,10.77,10.77,0,0,0,2.65-7.61,10.73,10.73,0,0,0-2.65-7.61A9.2,9.2,0,0,0,121.12,63a9.08,9.08,0,0,0-7,2.89,10.48,10.48,0,0,0-2.69,7.44"
            transform="translate(-0.7 0)"
          />
          <path
            d="M152.8,57.13V75.87q0,8.11,6.4,8.12t6.41-8.12V57.13h7.54V76a21.06,21.06,0,0,1-1,6.78,11.46,11.46,0,0,1-3.25,4.59,15.86,15.86,0,0,1-19.42,0,11.65,11.65,0,0,1-3.32-4.59,18.83,18.83,0,0,1-.94-6.78V57.13Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M201,62.7,194.8,66c-1-2-2.2-3-3.66-3a2.49,2.49,0,0,0-1.77.69,2.25,2.25,0,0,0-.74,1.76q0,1.88,4.36,3.72,6,2.58,8.08,4.76a8.17,8.17,0,0,1,2.08,5.87,10.29,10.29,0,0,1-3.49,7.91,11.89,11.89,0,0,1-8.18,3q-8.22,0-11.64-8l6.44-3a13.88,13.88,0,0,0,2.05,3A4.69,4.69,0,0,0,191.61,84q3.82,0,3.82-3.49,0-2-3-3.75-1.14-.57-2.28-1.11l-2.31-1.11a14.1,14.1,0,0,1-4.63-3.22,7.9,7.9,0,0,1-1.71-5.26,9.29,9.29,0,0,1,2.92-7,10.21,10.21,0,0,1,7.24-2.79q6.27,0,9.32,6.48"
            transform="translate(-0.7 0)"
          />
          <path
            d="M241.17,75.24h-23.4a9.92,9.92,0,0,0,2.61,6.4A8,8,0,0,0,226.32,84,7.74,7.74,0,0,0,231,82.65a19.24,19.24,0,0,0,4.13-5l6.37,3.56a26,26,0,0,1-3.12,4.31,16.58,16.58,0,0,1-3.52,3,14.36,14.36,0,0,1-4.06,1.69,19.62,19.62,0,0,1-4.73.54q-7.31,0-11.73-4.7t-4.43-12.54q0-7.74,4.29-12.54a14.82,14.82,0,0,1,11.47-4.73q7.22,0,11.4,4.6t4.16,12.64Zm-7.74-6.17q-1.58-6-7.61-6a7.76,7.76,0,0,0-2.58.42,7.49,7.49,0,0,0-3.89,3.1,9,9,0,0,0-1.08,2.52Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M306.22,57.13h7.58V89.79h-7.58V86.37A14.48,14.48,0,0,1,285,85.83a18.19,18.19,0,0,1-4.39-12.47A17.62,17.62,0,0,1,285,61.12a14.17,14.17,0,0,1,11-4.9,13.87,13.87,0,0,1,10.22,4.7Zm-17.9,16.23A11.33,11.33,0,0,0,290.84,81a8.21,8.21,0,0,0,6.5,3,8.64,8.64,0,0,0,6.77-2.89,11.16,11.16,0,0,0,2.58-7.57A11.16,11.16,0,0,0,304.11,66a8.51,8.51,0,0,0-6.7-2.92,8.33,8.33,0,0,0-6.51,3,10.89,10.89,0,0,0-2.58,7.38"
            transform="translate(-0.7 0)"
          />
          <rect x="321.71" y="37.95" width="7.54" height="51.84" />
          <rect x="337.85" y="37.95" width="7.54" height="51.84" />
          <path
            d="M377,68.77v21h-7.82V38H378a40.13,40.13,0,0,1,9.82.9,13.27,13.27,0,0,1,5.9,3.42,14.71,14.71,0,0,1,4.46,11q0,7.11-4.76,11.27t-12.84,4.16Zm0-7.25h2.91q10.77,0,10.77-8.28,0-8-11.1-8H377Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M404.8,57.13h7.54v2.92a14.5,14.5,0,0,1,3.69-3,8.45,8.45,0,0,1,3.89-.84,12.18,12.18,0,0,1,6.24,2l-3.46,6.91a7.18,7.18,0,0,0-4.19-1.55q-6.17,0-6.17,9.33V89.79H404.8Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M428.34,73.22a16.24,16.24,0,0,1,5.06-12,17.85,17.85,0,0,1,24.75,0,16.63,16.63,0,0,1,5,12.27,16.53,16.53,0,0,1-5.07,12.31,17.26,17.26,0,0,1-12.5,4.93,16.61,16.61,0,0,1-12.31-5,16.92,16.92,0,0,1-5-12.48m7.71.14a11,11,0,0,0,2.61,7.74,10.17,10.17,0,0,0,14.16,0,10.76,10.76,0,0,0,2.64-7.61,10.73,10.73,0,0,0-2.64-7.61,10,10,0,0,0-14.09,0,10.47,10.47,0,0,0-2.68,7.44"
            transform="translate(-0.7 0)"
          />
          <path
            d="M265.85,63.05c.33,0,.65,0,1,.06-.32,0-.65,0-1-.06"
            transform="translate(-0.7 0)"
          />
          <path
            d="M265.48,63h.37c.33,0,.66,0,1,.06a18.87,18.87,0,0,1,8,2.25V58.54a19,19,0,0,0-9.12-2.32,17.5,17.5,0,0,0-12.68,5,16.58,16.58,0,0,0-5.16,12.34A16.32,16.32,0,0,0,253,85.83a17.55,17.55,0,0,0,12.67,4.9,19.91,19.91,0,0,0,9.19-2.38V81.58a20.27,20.27,0,0,1-8,2.3,10.62,10.62,0,0,1-1.51.11,9.15,9.15,0,0,1-7-2.95,10.73,10.73,0,0,1-2.72-7.55A10.49,10.49,0,0,1,258.41,66a9.34,9.34,0,0,1,7.07-3"
            transform="translate(-0.7 0)"
          />
          <path d="M265.72,63h0" transform="translate(-0.7 0)" />
          <polygon points="0 42.92 0 84.82 20.44 95.81 20.44 89.86 20.44 83.44 20.44 44.61 20.44 38.19 20.44 30.88 0 42.92" />
          <polygon points="34.97 89.75 26.35 89.75 26.35 84.88 30.11 84.88 30.25 42.8 26.35 42.8 26.35 37.92 35.13 37.92 34.97 89.75" />
          <path
            d="M133.62,8.81a7,7,0,0,1-2.46,5.77q-2.46,2-7,2h-2.5v9h-3.93v-24h6.92q4.5,0,6.74,1.84a6.57,6.57,0,0,1,2.24,5.42m-12,4.47h2.08a7.48,7.48,0,0,0,4.44-1,3.84,3.84,0,0,0,1.41-3.29,3.74,3.74,0,0,0-1.26-3.09,6.27,6.27,0,0,0-3.95-1h-2.72Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M154.13,16.45a9.92,9.92,0,0,1-2.28,7,8.16,8.16,0,0,1-6.36,2.5,8.76,8.76,0,0,1-4.5-1.15,7.59,7.59,0,0,1-3-3.3,11.28,11.28,0,0,1-1-5,9.8,9.8,0,0,1,2.27-6.9,8.19,8.19,0,0,1,6.39-2.48,8,8,0,0,1,6.24,2.54,9.76,9.76,0,0,1,2.3,6.84m-13.23,0c0,4.2,1.55,6.29,4.65,6.29s4.61-2.09,4.61-6.29-1.55-6.23-4.64-6.23A4,4,0,0,0,142,11.83a8.21,8.21,0,0,0-1.1,4.62"
            transform="translate(-0.7 0)"
          />
          <path
            d="M173.47,25.57l-2.35-8.48c-.28-.9-.8-3-1.54-6.26h-.15q-1,4.44-1.51,6.29l-2.42,8.45h-4.27L156.14,7.39h3.94l2.32,9c.52,2.21.9,4.11,1.11,5.69h.1c.11-.8.28-1.72.5-2.76s.42-1.81.59-2.32l2.76-9.57h4.24L174.38,17c.16.54.37,1.36.61,2.47a19.39,19.39,0,0,1,.44,2.57h.13a50.26,50.26,0,0,1,1.15-5.65l2.35-9h3.88l-5.13,18.18Z"
            transform="translate(-0.7 0)"
          />
          <path
            d="M194,25.9a8.82,8.82,0,0,1-6.64-2.48A9.37,9.37,0,0,1,185,16.61a10.27,10.27,0,0,1,2.22-7,7.66,7.66,0,0,1,6.1-2.55A7.5,7.5,0,0,1,199,9.25a8.34,8.34,0,0,1,2.09,6v2.08H189a6,6,0,0,0,1.43,4.07,5,5,0,0,0,3.8,1.42,14.09,14.09,0,0,0,3-.3,15.73,15.73,0,0,0,3-1v3.13a11.75,11.75,0,0,1-2.86,1,18.14,18.14,0,0,1-3.3.28M193.29,10a3.87,3.87,0,0,0-2.95,1.17,5.54,5.54,0,0,0-1.32,3.4h8.25a5.08,5.08,0,0,0-1.09-3.41A3.71,3.71,0,0,0,193.29,10"
            transform="translate(-0.7 0)"
          />
          <path
            d="M214.67,7.07a9.37,9.37,0,0,1,1.92.16l-.38,3.6a7.41,7.41,0,0,0-1.71-.2,5,5,0,0,0-3.75,1.51,5.47,5.47,0,0,0-1.44,3.93v9.5h-3.86V7.39h3L209,10.6h.2A7.25,7.25,0,0,1,211.54,8a5.64,5.64,0,0,1,3.13-.95"
            transform="translate(-0.7 0)"
          />
          <path
            d="M227.89,25.9a8.81,8.81,0,0,1-6.63-2.48,9.37,9.37,0,0,1-2.39-6.81,10.27,10.27,0,0,1,2.22-7,7.66,7.66,0,0,1,6.1-2.55,7.5,7.5,0,0,1,5.68,2.18,8.38,8.38,0,0,1,2.09,6v2.08H222.85a5.94,5.94,0,0,0,1.43,4.07,5,5,0,0,0,3.79,1.42,14.12,14.12,0,0,0,3-.3,15.73,15.73,0,0,0,3-1v3.13a11.75,11.75,0,0,1-2.86,1,18.18,18.18,0,0,1-3.31.28M227.19,10a3.87,3.87,0,0,0-2.95,1.17,5.61,5.61,0,0,0-1.33,3.4h8.25a5,5,0,0,0-1.08-3.41A3.71,3.71,0,0,0,227.19,10"
            transform="translate(-0.7 0)"
          />
          <path
            d="M245.48,25.9a6.32,6.32,0,0,1-5.31-2.47,11.07,11.07,0,0,1-1.91-6.91,11.16,11.16,0,0,1,1.93-7,6.39,6.39,0,0,1,5.35-2.49A6.28,6.28,0,0,1,251,9.71h.2a22.76,22.76,0,0,1-.29-3.09V0h3.88V25.57h-3l-.67-2.38h-.19a6.17,6.17,0,0,1-5.43,2.71m1-3.12A4.21,4.21,0,0,0,250,21.44a7.16,7.16,0,0,0,1.12-4.35v-.54c0-2.29-.38-3.92-1.12-4.88a4.13,4.13,0,0,0-3.5-1.45,3.55,3.55,0,0,0-3.14,1.65,8.52,8.52,0,0,0-1.1,4.71,8.27,8.27,0,0,0,1.07,4.61,3.6,3.6,0,0,0,3.2,1.59"
            transform="translate(-0.7 0)"
          />
          <path
            d="M278.34,7.07a6.32,6.32,0,0,1,5.3,2.46,11.16,11.16,0,0,1,1.9,6.92,11.16,11.16,0,0,1-1.93,7,7,7,0,0,1-10.69,0h-.26L272,25.57h-2.89V0h3.86V6.08c0,.45,0,1.12-.07,2s-.08,1.45-.1,1.69h.17a6.11,6.11,0,0,1,5.42-2.71m-1,3.15A3.9,3.9,0,0,0,274,11.6c-.69.91-1,2.44-1.06,4.59v.26A8.55,8.55,0,0,0,274,21.26a3.9,3.9,0,0,0,3.43,1.48,3.5,3.5,0,0,0,3.12-1.62,8.7,8.7,0,0,0,1.06-4.7c0-4.13-1.42-6.2-4.24-6.2"
            transform="translate(-0.7 0)"
          />
          <path
            d="M287.26,7.39h4.21l3.69,10.31a19.19,19.19,0,0,1,1.12,4.14h.13a16.6,16.6,0,0,1,.55-2.19q.39-1.29,4.17-12.26h4.18L297.53,28q-2.11,5.67-7.07,5.67a10.86,10.86,0,0,1-2.49-.28v-3a9.11,9.11,0,0,0,2,.2q2.79,0,3.92-3.24l.68-1.71Z"
            transform="translate(-0.7 0)"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default PoweredIcon
