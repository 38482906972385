import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import EditModeSingleTestimonial from './EditModeSingleTestimonial'
import EditModeTestimonialHeader from './EditModeTestimonialHeader'

function EditModeTestimonial({
  testimonial,
  onEditClick,
  onDeleteClick,
  canEdit,
  canDelete,
}) {
  return (
    <Fragment>
      <EditModeTestimonialHeader
        editting={false}
        onEditClick={() => onEditClick()}
        onDeleteClick={() => onDeleteClick()}
        canEdit={canEdit}
        canDelete={canDelete}
      />
      <EditModeSingleTestimonial testimonial={testimonial} />
    </Fragment>
  )
}

EditModeTestimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
}

EditModeTestimonial.defaultProps = {
  canEdit: false,
  canDelete: false,
}

export default EditModeTestimonial
