import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import SplitPaneStickyRight from 'src/common/SplitPaneStickyRight'

const styles = theme => ({
  content: {
    width: '100%',
    maxWidth: theme.spacing.centerContentMaxWidth,
  },
})

function ContentTemplate({
  showRightPane,
  leftPane,
  rightPane,
  classes,
}) {
  if (showRightPane) {
    return (
      <Grid item xs={12} className={classes.content}>
        <SplitPaneStickyRight
          leftPane={leftPane}
          rightPane={rightPane}
          rightPaneHasNegativePosition={true}
        />
      </Grid>
    )
  } else {
    return (
      <Grid item xs={7}>
        {leftPane}
      </Grid>
    )
  }
}

ContentTemplate.propTypes = {
  showRightPane: PropTypes.bool.isRequired,
  leftPane: PropTypes.object.isRequired,
  rightPane: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContentTemplate)
