import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import { isPresent } from 'src/common/ObjectHelper'
import {
  Select,
  MenuItem,
  withStyles,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core'

const styles = theme => ({
  paper: {
    maxHeight: 250,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
})

function formatTime(time) {
  let timeArray = time.split(":")
  let hours = parseInt(timeArray[0])
  let minutes = timeArray[1]
  return hours + ":" + minutes
}

function TimeSelector({ classes, onChange, time, label }) {
  function windowForTime(hour, minutes) {
    let hourToDisplay = hour % 12
    if (hourToDisplay == 0) {
      hourToDisplay = 12
    }

    let meridian = hour < 12 ? 'am' : 'pm'
    let display = '' + hourToDisplay + ':' + minutes + meridian
    let value = '' + hour + ':' + minutes

    return (
      <MenuItem key={value} value={value}>
        {display}
      </MenuItem>
    )
  }

  function timeWindows() {
    let windows = []
    for (let hour = 0; hour <= 23; hour++) {
      windows.push(windowForTime(hour, '00'))
      windows.push(windowForTime(hour, '30'))
    }

    return windows
  }
  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
        }}
        value={formatTime(time)}
        input={<OutlinedInput labelWidth={70} />}
        onChange={event => {
          onChange(event.target.value)
        }}
      >
        {timeWindows()}
      </Select>
    </FormControl>
  )
}

TimeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  time: PropTypes.string,
  label: PropTypes.string.isRequired
}

export default withStyles(styles)(TimeSelector)
