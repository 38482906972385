import { isPresent } from 'src/common/ObjectHelper'

export function cityStateFormatter(city, state) {
  if (isPresent(city) && isPresent(state)) {
    return city + ', ' + state
  } else if (isPresent(city) && !isPresent(state)) {
    return city
  } else if (!isPresent(city) && isPresent(state)) {
    return state
  } else {
    return ''
  }
}

export function cityStateFormatterWithBracket(city, state) {
  const cityState = cityStateFormatter(city, state)
  if (isPresent(cityState)) {
    return '(' + cityState + ')'
  } else {
    return ''
  }
}
