import React, { PureComponent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DayColumn from './DayColumn'
import { Grid, DialogActions, DialogTitle } from '@material-ui/core'
import BookingBufferRow from './BookingBufferRow'
import { PropTypes } from 'prop-types'
import { CancelSaveButtons } from 'housecall-ui'

class EditDialog extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      availabilityBuffer: this.props.availabilityBuffer,
      monday: this.props.defaultDailyAvailabilities.monday,
      tuesday: this.props.defaultDailyAvailabilities.tuesday,
      wednesday: this.props.defaultDailyAvailabilities.wednesday,
      thursday: this.props.defaultDailyAvailabilities.thursday,
      friday: this.props.defaultDailyAvailabilities.friday,
      saturday: this.props.defaultDailyAvailabilities.saturday,
      sunday: this.props.defaultDailyAvailabilities.sunday,
    }
  }

  handleClose() {
    const { onClose, defaultDailyAvailabilities } = this.props
    this.setState({ 
      monday: defaultDailyAvailabilities.monday,
      tuesday: defaultDailyAvailabilities.tuesday,
      wednesday: defaultDailyAvailabilities.wednesday,
      thursday: defaultDailyAvailabilities.thursday,
      friday: defaultDailyAvailabilities.friday,
      saturday: defaultDailyAvailabilities.saturday,
      sunday: defaultDailyAvailabilities.sunday,
    })
    onClose()
  }

  render() {
    const { mobile, isSaving } = this.props
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.props.onClose()
        }}
        fullWidth={true}
        maxWidth={'xl'}
        fullScreen={mobile}
      >
        <DialogTitle>Booking windows</DialogTitle>
        <DialogContent>
          <BookingBufferRow
            availabilityBufferInDays={this.state.availabilityBuffer}
            onChange={buffer => {
              this.setState({ availabilityBuffer: buffer })
            }}
          />
          <Grid container direction={mobile ? 'column' : 'row'}>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Mon'}
                mobile={mobile}
                windows={this.state.monday}
                windowsDidUpdate={windows => this.setState({ monday: windows })}
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Tues'}
                mobile={mobile}
                windows={this.state.tuesday}
                windowsDidUpdate={windows =>
                  this.setState({ tuesday: windows })
                }
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Weds'}
                mobile={mobile}
                windows={this.state.wednesday}
                windowsDidUpdate={windows =>
                  this.setState({ wednesday: windows })
                }
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Thurs'}
                mobile={mobile}
                windows={this.state.thursday}
                windowsDidUpdate={windows =>
                  this.setState({ thursday: windows })
                }
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Fri'}
                mobile={mobile}
                windows={this.state.friday}
                windowsDidUpdate={windows => this.setState({ friday: windows })}
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                dayName={'Sat'}
                mobile={mobile}
                windows={this.state.saturday}
                windowsDidUpdate={windows => {
                  this.setState({ saturday: windows })
                }}
              />
            </Grid>
            <Grid item xs={12} md>
              <DayColumn
                disableAdd={isSaving}
                lastColumn={true}
                dayName={'Sun'}
                mobile={mobile}
                windows={this.state.sunday}
                windowsDidUpdate={windows => this.setState({ sunday: windows })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelSaveButtons
            onSaveClick={() => {
              this.props.onSave(this.state)
            }}
            onCancelClick={() => this.handleClose()}
            disabled={isSaving}
            saveButtonVariant="text"
          />
        </DialogActions>
      </Dialog>
    )
  }
}

EditDialog.propTypes = {
  defaultDailyAvailabilities: PropTypes.object.isRequired,
  availabilityBuffer: PropTypes.number.isRequired,
  open: PropTypes.bool,
  isSaving: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}
export default EditDialog
