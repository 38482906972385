import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import EditTestimonialsList from './Edit/EditTestimonialsList'
import TestimonialsCarousel from './TestimonialsCarousel'

const TESTIMONIALS = 'Testimonials'

export default class TestimonialsParent extends PureComponent {
  render() {
    const { testimonials, inEditMode } = this.props

    if (inEditMode) {
      return (
        <div id="testimonials">
          <EditTestimonialsList
            sectionTitle={TESTIMONIALS}
            testimonials={testimonials}
            exitBeingEdited={() => this.setState({ isBeingEdited: false })}
          />
        </div>
      )
    } else {
      return (
        <div id="testimonials">
          <TestimonialsCarousel
            sectionTitle={TESTIMONIALS}
            testimonials={testimonials}
            canEdit={inEditMode}
            onEditClick={() => this.setState({ isBeingEdited: true })}
          />
        </div>
      )
    }
  }
}

TestimonialsParent.propTypes = {
  testimonials: PropTypes.array.isRequired,
  inEditMode: PropTypes.bool,
}

TestimonialsParent.defaultProps = {
  testimonials: [],
  inEditMode: false,
}
