import React from 'react'
import AccessTime from '@material-ui/icons/AccessTime'
import Security from '@material-ui/icons/Security'
import Lock from '@material-ui/icons/Lock'
import People from '@material-ui/icons/People'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import FeaturedPlayList from '@material-ui/icons/FeaturedPlayList'
import Payment from '@material-ui/icons/Payment'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import Stars from '@material-ui/icons/Stars'
import Warning from '@material-ui/icons/Warning'
import ThumbUpAlt from '@material-ui/icons/ThumbUpAlt'

export const COMPANY_HIGHLIGHTS = {
  start_year: { icon: <AccessTime />, text: '' },
  license: { icon: <InsertDriveFile />, text: '' },
  insured: { icon: <Security />, text: 'Insured' },
  family_owned: { icon: <People />, text: 'Family owned' },
  bonded: { icon: <Lock />, text: 'Bonded' },
  veteran_owned: { icon: <Stars />, text: 'Veteran owned' },
  emergency_services: { icon: <Warning />, text: 'Emergency services' },
  repairs_guaranteed: { icon: <ThumbUpAlt />, text: 'Repairs guaranteed' },
}

export const PAYMENT_HIGHLIGHTS = {
  accepts_cash: { icon: <MonetizationOn />, text: 'Cash' },
  accepts_credit_card: { icon: <Payment />, text: 'Credit cards' },
  accepts_checks: { icon: <FeaturedPlayList />, text: 'Checks' },
}
