import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { updateWebsiteContent } from 'src/Resources/WebsiteContent/Actions'
import { CancelSaveButtons } from 'housecall-ui'
import { displayErrorDialog } from '../../../../common/ErrorDialog/Actions'

@connect(
  null,
  { updateWebsiteContent, displayErrorDialog }
)
export default class EditBannerTextDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      bannerTextTitle: this.props.initialValues.title,
      bannerTextDescription: this.props.initialValues.description,
    }
  }

  handleClose() {
    const { onClose, initialValues } = this.props
    this.setState({
      bannerTextTitle: initialValues.title,
      bannerTextDescription: initialValues.description,
    })
    onClose()
  }

  handleSave(title, description) {
    const { updateWebsiteContent, onSave } = this.props

    this.setState({ isSaving: true })
    updateWebsiteContent({
      banner_text_title: title,
      banner_text_description: description,
    })
      .then(() => this.setState({ isSaving: false }))
      .catch(error => {
        this.setState({
          isSaving: false,
        })
        this.props.displayErrorDialog(
          'Failed to update banner text. Please try again.'
        )
      })
      .finally(() => onSave())
  }

  render() {
    const { open } = this.props
    const { isSaving, bannerTextTitle, bannerTextDescription } = this.state

    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose()}
        disableBackdropClick
        fullWidth
      >
        <DialogTitle>Your Banner Text</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            fullWidth
            label="Title"
            defaultValue={bannerTextTitle}
            onChange={event =>
              this.setState({ bannerTextTitle: event.target.value })
            }
            inputProps={{ maxLength: 191 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            label="Description"
            rows={4}
            defaultValue={bannerTextDescription}
            onChange={event =>
              this.setState({ bannerTextDescription: event.target.value })
            }
            inputProps={{ maxLength: 191 }}
          />
        </DialogContent>
        <DialogActions>
          <CancelSaveButtons
            saveButtonVariant="text"
            onCancelClick={() => this.handleClose()}
            onSaveClick={() =>
              this.handleSave(bannerTextTitle, bannerTextDescription)
            }
            disabled={isSaving}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

EditBannerTextDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
}

EditBannerTextDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
  initialValues: {
    title: '',
    description: '',
  },
}
