import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'
import { CancelSaveButtons, VideoUrlTextBox, FeaturedVideo } from 'housecall-ui'

function EditBody({
  showEdit,
  videoId,
  onChange,
  disableSave,
  handleCancel,
  handleSave,
  disabled,
  isSaving,
}) {
  if (showEdit) {
    return (
      <Fragment>
        <form onSubmit={event => event.preventDefault()}>
          <VideoUrlTextBox
            oldId={videoId}
            onChange={youtubeId => {
              onChange(youtubeId)
            }}
            disableSave={disable => disableSave(disable)}
          />
          <CancelSaveButtons
            isSaving={isSaving}
            onCancelClick={() => handleCancel()}
            onSaveClick={() => handleSave()}
            disabled={disabled}
          />
        </form>
      </Fragment>
    )
  } else {
    if (isBlank(videoId)) {
      return null
    }
    return <FeaturedVideo videoId={videoId} />
  }
}
EditBody.propTypes = {
  videoId: PropTypes.string,
  showEdit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  disableSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSaving: PropTypes.bool,
}

export default EditBody
