import React, { PureComponent, Fragment } from 'react'
import { Hidden, withStyles, Grid, Typography } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'
import LinesEllipsis from 'react-lines-ellipsis'
import { EditAndDelete } from 'housecall-ui'
import EditBannerTextDialog from './Edit/EditBannerTextDialog'

const styles = theme => ({
  container: {
    background: 'rgba(0, 0, 0, 0.7)',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 16,
    paddingBottom: 16,
  },
  containerDesktop: {
    width: 750,
  },
  containerMobile: {
    width: 'calc(100% - 24px)',
    paddingLeft: 16,
  },
  titleText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 34,
    },
  },
  descriptionText: {
    color: 'white',
    fontFamily: theme.fontFamily,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
})

@withStyles(styles)
export default class BannerText extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
    }
  }

  hideTextComponent() {
    const { inEditMode, title, description } = this.props
    return !inEditMode && isBlank(title) && isBlank(description)
  }

  handleEditClick() {
    this.setState({ openDialog: true })
  }

  handleClose() {
    this.setState({ openDialog: false })
  }

  render() {
    const { classes, isMobile, title, description, inEditMode } = this.props
    const { openDialog } = this.state

    if (this.hideTextComponent()) {
      return null
    }

    let titleTextLeftPadding = isMobile ? 5 : 120

    return (
      <Fragment>
        <Grid
          container
          className={classNames(
            classes.container,
            isMobile ? classes.containerMobile : classes.containerDesktop
          )}
        >
          <Grid item xs={12} style={{ paddingLeft: titleTextLeftPadding }}>
            <Grid container>
              <Grid item xs={inEditMode ? 11 : 12}>
                <Typography
                  variant="h1"
                  color="secondary"
                  gutterBottom
                  className={classes.titleText}
                >
                  {isBlank(title) && inEditMode ? 'Title' : title}
                </Typography>
              </Grid>
              {inEditMode && (
                <Grid item xs={1}>
                  <EditAndDelete
                    canEdit={true}
                    onEditClick={() => this.handleEditClick()}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <LinesEllipsis
                  className={classes.descriptionText}
                  text={
                    isBlank(description) && inEditMode
                      ? 'Description'
                      : description
                  }
                  maxLine={2}
                  ellipsis="..."
                  basedOn="words"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EditBannerTextDialog
          open={openDialog}
          onClose={() => this.handleClose()}
          onSave={() => this.handleClose()}
          initialValues={{ title: title, description: description }}
        />
      </Fragment>
    )
  }
}

BannerText.propTypes = {
  isMobile: PropTypes.bool,
  inEditMode: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

BannerText.defaultProps = {
  isMobile: false,
  inEditMode: false,
  title: '',
  description: '',
}
