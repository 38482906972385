import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import { updateBusinessHours } from './Redux/Actions'
import { EditBusinessHoursForm, CancelSaveButtons } from 'housecall-ui'
import { isBlank } from 'src/common/ObjectHelper'

const styles = theme => ({
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 600,
    },
  },
})
@connect(
  null,
  {
    updateBusinessHours,
  }
)
@withStyles(styles)
export default class EditBusinessHoursDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
      businessHours: this.props.businessHours,
    }
  }

  submitForm(businessHours) {
    if (isBlank(businessHours)) {
      this.props.onClose()
    } else {
      this.setState({ updating: true })
      this.props
        .updateBusinessHours(businessHours)
        .then(() => {
          this.props.onClose()
        })
        .finally(() => {
          this.setState({ updating: false })
        })
    }
  }

  handleBusinessHoursChange = (day, openTime, closeTime) => {
    let hours = { openTime: openTime, closeTime: closeTime }
    let businessHours = this.state.businessHours
    businessHours[day] = hours
    this.setState({ businessHours: businessHours })
  }

  render() {
    const { open, onClose, businessHours, isMobile, classes } = this.props

    return (
      <Dialog
        open={open}
        disableEnforceFocus
        fullScreen={isMobile}
        onClose={() => onClose()}
      >
        <DialogTitle>Business hours</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <EditBusinessHoursForm
            businessHours={businessHours}
            onChange={(day, openTime, closeTime) => {
              this.handleBusinessHoursChange(day, openTime, closeTime)
            }}
          />
        </DialogContent>
        <DialogActions>
          <CancelSaveButtons
            saveButtonVariant="text"
            onCancelClick={() => onClose()}
            disabled={this.state.updating}
            onSaveClick={() => this.submitForm(this.state.businessHours)}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

EditBusinessHoursDialog.propTypes = {
  businessHours: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}

EditBusinessHoursDialog.defaultProps = {
  isMobile: false,
}
