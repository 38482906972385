import { createSelector } from 'reselect'
import { EPHEMERAL_NAMESPACE, ERROR_DIALOG } from './Actions'

export const getEphemeralData = (state) => (
    state[EPHEMERAL_NAMESPACE] || {}
)

const getErrorDialogData = createSelector(
    getEphemeralData,
    ephemeralData => ephemeralData[ERROR_DIALOG] || {}
  )

export const getErrorDialogMessage = createSelector(
    getErrorDialogData,
    errorDialog => errorDialog.message
  )
