import React, { Fragment, PureComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'
import Section from '../Sections/Section'
import EditModeFAQ from './Edit/EditModeFAQ'
import SingleFAQWithExpansionPanel from './SingleFAQWithExpansionPanel'

export default class FrequentlyAskedQuestionsParent extends PureComponent {
  buildFAQRows(faqs) {
    return (
      <div>
        {faqs.map((faq, index) => (
          <SingleFAQWithExpansionPanel faq={faq} key={index} />
        ))}
      </div>
    )
  }

  render() {
    const { faqs, inEditMode } = this.props
    if (isBlank(faqs) && !inEditMode) {
      return null
    }
    if (inEditMode) {
      return (
        <div id="faq">
          <EditModeFAQ faqs={faqs} sectionTitle="Frequently asked questions" />
        </div>
      )
    } else {
      return (
        <Section
          id="faq"
          title="Frequently asked questions"
          canEdit={inEditMode}
        >
          <Fragment>{this.buildFAQRows(faqs)}</Fragment>
        </Section>
      )
    }
  }
}

FrequentlyAskedQuestionsParent.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object),
}

FrequentlyAskedQuestionsParent.defaultProps = {
  faqs: [],
}
