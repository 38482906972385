import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Contact from './Contact'
import Section from '../CompanyProfile/Sections/Section'
import EditOrganizationDialog from '../EditProfileDialog/EditOrganizationDialog'

const styles = theme => ({
  container: {
    maxWidth: '80%',
  },
})

@withStyles(styles)
export default class ContactParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openOrganizationEditDialog: false,
    }
  }

  render() {
    const { classes, organization, inEditMode } = this.props
    const { openOrganizationEditDialog } = this.state

    return (
      <div className={classes.container}>
        <EditOrganizationDialog
          open={openOrganizationEditDialog}
          onClose={() => {
            this.setState({ openOrganizationEditDialog: false })
          }}
        />
        <Section
          title="Contact us"
          showDivider={false}
          canEdit={inEditMode}
          onEditClick={() =>
            this.setState({ openOrganizationEditDialog: true })
          }
        >
          <Contact
            printableAddress={organization.printable_address}
            phoneNumber={organization.phone_number}
            email={organization.email}
            showSocialIcons={true}
            inEditMode={inEditMode}
          />
        </Section>
      </div>
    )
  }
}

ContactParent.propTypes = {
  organization: PropTypes.object,
  inEditMode: PropTypes.bool,
}

ContactParent.defaultProps = {
  inEditMode: false,
}
