import { getReviewsApi } from './Api'
import { REVIEWS_NAMESPACE } from './Data'
import { receivedServerData } from 'data-fetcher'

export function getMoreReviews(pageData) {
  return dispatch => {
    return getReviewsApi(pageData).then(response => {
      dispatch(receivedServerData(response.data, REVIEWS_NAMESPACE))
    })
  }
}
