import { updateFAQApi, deleteFAQApi, createFAQApi } from './Api'
import { getCompanyProfile } from 'src/Resources/CompanyProfile/Data'
import { didUpdateContent } from '../../../../Edit/Redux/Actions'
const NEW_FAQ_UUID_PLACEHOLDER = 'NEW_FAQ_UUID_PLACEHOLDER'

export function addFAQCreationFields() {
  return (dispatch, getState) => {
    const newFAQs = [...getCompanyProfile(getState()).faqs]
    newFAQs.push({ id: NEW_FAQ_UUID_PLACEHOLDER })
    dispatch(didUpdateContent('faqs', newFAQs))
  }
}

export function removeFAQCreationFields() {
  return (dispatch, getState) => {
    var newFAQs = [...getCompanyProfile(getState()).faqs].filter(
      faq => faq.id !== NEW_FAQ_UUID_PLACEHOLDER
    )
    dispatch(didUpdateContent('faqs', newFAQs))
  }
}

export function createFAQ(question, answer) {
  const createdFAQ = {
    question: question,
    answer: answer,
  }

  return (dispatch, getState) => {
    return createFAQApi(createdFAQ).then(response => {
      var newFAQs = [...getCompanyProfile(getState()).faqs].filter(
        faq => faq.id !== NEW_FAQ_UUID_PLACEHOLDER
      )
      newFAQs.push(response.data)
      dispatch(didUpdateContent('faqs', newFAQs))
    })
  }
}

export function updateFAQ(uuid, question, answer) {
  const updatedFAQ = {
    uuid: uuid,
    question: question,
    answer: answer,
  }
  return (dispatch, getState) => {
    return updateFAQApi(updatedFAQ).then(response => {
      var newFAQs = [...getCompanyProfile(getState()).faqs]
      let index = newFAQs.findIndex(faq => faq.id === uuid)
      newFAQs[index] = response.data
      dispatch(didUpdateContent('faqs', newFAQs))
    })
  }
}

export function deleteFAQ(faqId) {
  return (dispatch, getState) => {
    return deleteFAQApi(faqId).then(response => {
      const newFAQs = [...getCompanyProfile(getState()).faqs].filter(
        test => test.id !== faqId
      )
      dispatch(didUpdateContent('faqs', newFAQs))
    })
  }
}
