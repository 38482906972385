import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import EditModeSection from '../../Sections/EditModeSection'
import {
  deleteTestimonial,
  addTestimonialCreationFields,
} from './Redux/Actions'
import IsEdittingTestimonial from './IsEdittingTestimonial'
import EditModeTestimonial from './EditModeTestimonial'

export const NEW_TESTIMONIAL_UUID_PLACEHOLDER =
  'NEW_TESTIMONIAL_UUID_PLACEHOLDER'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { ConfirmationDialog } from 'housecall-ui'

const styles = {
  spacing: {
    marginTop: 16,
  },
}

@withStyles(styles)
@connect(
  null,
  { addTestimonialCreationFields, deleteTestimonial, displayErrorDialog }
)
class EditTestimonialsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      uuidBeingEditted: null,
      deletedTestimonialId: '',
      showConfirmation: false,
    }
  }

  closeEditFields() {
    this.setState({ uuidBeingEditted: null })
  }

  handleDelete(testimonial) {
    this.setState({
      deletedTestimonialId: testimonial.id,
      showConfirmation: true,
    })
  }

  buildTestimonialsList() {
    return this.props.testimonials.map((testimonial, index) => {
      if (this.state.uuidBeingEditted === testimonial.id) {
        return (
          <Grid item xs={12} key={testimonial.id}>
            <IsEdittingTestimonial
              testimonial={testimonial}
              variant={
                testimonial.id === NEW_TESTIMONIAL_UUID_PLACEHOLDER
                  ? 'create'
                  : 'update'
              }
              onDeleteClick={() => this.handleDelete(testimonial)}
              onCancelClick={() => this.closeEditFields()}
              onSaveClick={() => this.closeEditFields()}
            />
          </Grid>
        )
      } else {
        return (
          <Grid item xs={12} key={testimonial.id}>
            {isPresent(this.state.uuidBeingEditted) && (
              <div className={this.props.classes.spacing} />
            )}
            <EditModeTestimonial
              number={index}
              testimonial={testimonial}
              onDeleteClick={() => this.handleDelete(testimonial)}
              onEditClick={() =>
                this.setState({ uuidBeingEditted: testimonial.id })
              }
              canEdit={isBlank(this.state.uuidBeingEditted)}
              canDelete={isBlank(this.state.uuidBeingEditted)}
            />
          </Grid>
        )
      }
    })
  }

  render() {
    return (
      <Fragment>
        <EditModeSection
          title={this.props.sectionTitle}
          showAddButton={isBlank(this.state.uuidBeingEditted)}
          onAddButtonClick={() => {
            this.props.addTestimonialCreationFields()
            this.setState({
              uuidBeingEditted: NEW_TESTIMONIAL_UUID_PLACEHOLDER,
            })
          }}
        >
          <Grid container spacing={24}>
            {this.buildTestimonialsList()}
          </Grid>
        </EditModeSection>
        <ConfirmationDialog
          open={this.state.showConfirmation}
          message_title="Are you sure you want to delete this testimonial?"
          message_body=""
          confirmButtonMessage="Delete"
          confirmButtonColor="secondary"
          onCancelClick={() => this.setState({ showConfirmation: false })}
          onConfirmClick={() => {
            this.props
              .deleteTestimonial(this.state.deletedTestimonialId)
              .catch(error => {
                this.props.displayErrorDialog(
                  'Failed to delete testimonial. Please try again.'
                )
              })
            this.closeEditFields()
            this.setState({ showConfirmation: false })
          }}
        />
      </Fragment>
    )
  }
}

EditTestimonialsList.propTypes = {
  exitBeingEdited: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.object),
}

EditTestimonialsList.defaultProps = {
  sectionTitle: '',
  testimonials: [],
}

export default EditTestimonialsList
