import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Hidden } from '@material-ui/core'
import EditDialog from './EditDialog'

function EditBookingWindows(props) {
  return (
    <Fragment>
      <Hidden smDown>
        <EditDialog {...props} />
      </Hidden>
      <Hidden mdUp>
        <EditDialog mobile={true} {...props} />
      </Hidden>
    </Fragment>
  )
}

export default EditBookingWindows
