import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { isPresent } from 'src/common/ObjectHelper'
import { CancelSaveButtons } from 'housecall-ui'
import Section from './Section'

function EditModeSection({
  id,
  title,
  children,
  showDivider,
  showAddButton,
  onCancelClick,
  onSaveClick,
  onAddButtonClick,
  typeSubmit,
}) {
  return (
    <Section
      title={title}
      id={id}
      showDivider={showDivider}
      addButtonText={'ADD A TESTIMONIAL'}
      showAddButton={showAddButton}
      onAddButtonClick={() => onAddButtonClick()}
    >
      <Grid container spacing={24}>
        <Grid item xs={12}>
          {children}
        </Grid>
        {isPresent(onCancelClick) && isPresent(onSaveClick) && (
          <Grid item xs={12}>
            <CancelSaveButtons
              onCancelClick={() => onCancelClick()}
              onSaveClick={() => onSaveClick()}
              typeSubmit={typeSubmit}
            />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

EditModeSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showDivider: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  typeSubmit: PropTypes.bool,
}

EditModeSection.defaultProps = {
  showDivider: true,
  typeSubmit: false,
}

export default EditModeSection
