export function convertBusinessHour(businessHour) {
  const businessHourArray = []
  businessHour.forEach(({day, open_time, close_time}) => {
    businessHourArray.push(
      `${day.substring(0, 2)} ${open_time.substring(0, 5)}-${close_time.substring(0, 5)}`
    )
  })
  return businessHourArray
}

export function convertPaymentMethod(paymentMethods) {
  const paymentMethodArray = []
  paymentMethods.forEach(({name}) => {
    if (name.startsWith("accept")) {
      const method = name.substring(8)
      paymentMethodArray.push(method.charAt(0).toUpperCase() + method.slice(1))
    }
  })
  return paymentMethodArray.toString().replace(/_/g, " ").replace(/,/g, ', ')
}