import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import { PAYMENT_HIGHLIGHTS } from '../../CompanyDescription/Redux/Data'

function existInCurrentPaymentHighlights(paymentName, paymentHighlights) {
  for (const payment of paymentHighlights) {
    if (paymentName == payment.name) {
      return true
    }
  }
  return false
}

function paymentHighlightsEditForm({
  paymentHighlights,
  handleCheckboxToggle,
}) {
  return (
    <Grid container justify="center">
      <Grid item xs={11} container>
        {Object.keys(PAYMENT_HIGHLIGHTS).map((paymentName, index) => {
          return (
            <Grid item xs={6} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={existInCurrentPaymentHighlights(
                      paymentName,
                      paymentHighlights
                    )}
                    label={paymentName}
                    color="primary"
                    value="isChecked"
                    onChange={() => handleCheckboxToggle(paymentName)}
                  />
                }
                label={`${PAYMENT_HIGHLIGHTS[paymentName].text}`}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

paymentHighlightsEditForm.propTypes = {
  handleCheckboxToggle: PropTypes.func.isRequired,
  paymentHighlights: PropTypes.arrayOf(PropTypes.object).isRequired,
}
paymentHighlightsEditForm.defaultProps = {
  paymentHighlights: [],
}

export default React.memo(paymentHighlightsEditForm)
