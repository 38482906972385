const NAVBAR_OFFSET = 50

const getBookingWidgetBottomPosition = () =>
  document.querySelector('#booking_widget').getBoundingClientRect().bottom

const bookingWidgetIsVisible = () =>
  getBookingWidgetBottomPosition() > NAVBAR_OFFSET

const handleVisibleBookingWidget = callback => {
  if (bookingWidgetIsVisible()) {
    callback()
  }
}

const handleInvisibleBookingWidget = callback => {
  if (!bookingWidgetIsVisible()) {
    callback()
  }
}

export const addScrollListenerForBookingButton = (
  showButtonCallback,
  hideButtonCallback
) => {
  window.addEventListener('scroll', () => {
    handleInvisibleBookingWidget(showButtonCallback)
    handleVisibleBookingWidget(hideButtonCallback)
  })
}

export const removeScrollListenerForBookingButton = (
  showButtonCallback,
  hideButtonCallback
) => {
  window.removeEventListener('scroll', () => {
    handleInvisibleBookingWidget(showButtonCallback)
    handleVisibleBookingWidget(hideButtonCallback)
  })
}
