import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { getServiceArea } from 'src/Resources/CompanyProfile/ServiceArea/Data'
import { getPhotosWithAltText } from 'src/Resources/CompanyProfile/Photos/Data'
import {
  getCompanyLogo,
  getCompanyPhoneNumber,
  getCompanyDescription,
  getOrganization,
  getAddress,
} from 'src/Resources/Organization/Data'
import {
  getBookingTitle,
  getDescriptionTitle,
  getBannerTextTitle,
  getBannerTextDescription,
  getShowReviews,
  getShowBookingCard,
} from 'src/Resources/WebsiteContent/Data'
import {
  getDesktopBannerUrls,
  getMobileBannerUrls,
  getFAQs,
  getBusinessHours,
  getYoutubeId,
  getTestimonials,
  getCompanyProfile,
} from 'src/Resources/CompanyProfile/Data'
import { getRatingsCount } from 'src/components/CompanyProfile/Reviews/Redux/Data'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Phone from '@material-ui/icons/Phone'
import HeaderMobile from '../Headers/HeaderMobile'
import Title from './Title'
import Content from './Content'
import NavigationActions from '../Headers/NavigationActions'
import BookParent from './Book/BookParent'
import Footer from '../Footers/Footer'
import { isPresent } from '../../common/ObjectHelper'
import BookButtonMobile from './Book/BookingButton/BookButtonMobile'
import {
  addScrollListenerForBookingButton,
  removeScrollListenerForBookingButton,
} from './Book/BookingButton/BookingButtonHelper'
import PaymentHighlightsParent from './Book/PaymentHighlights/PaymentHighlightsParent'

const displacedChatPosition = {
  bottom: 80,
  right: 10,
  transition: 'bottom 0.5s',
}
const defaultChatPosition = {
  bottom: 10,
  right: 10,
  transition: 'bottom 0.5s',
}

const styles = theme => ({
  stickyNavbar: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  headerForLogoAndPhone: {
    background: '#fff',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bookingCard: {
    paddingBottom: 30,
  },
  stickPhoneIcon: {
    position: 'sticky',
    height: 50,
    top: 0,
    zIndex: 101,
    background: '#fff',
    alignSelf: 'center',
  },
  stickyBookingButton: {
    position: 'sticky',
    bottom: 0,
    zIndex: 200,
    transition: 'bottom 0.5s',
  },
  hiddenBookingButton: {
    bottom: -100,
  },
})

function CompanyProfileMobile({
  classes,
  companyProfile,
  logoUrl,
  phoneNumber,
  desktopBannerUrls,
  mobileBannerUrls,
  description,
  faqs,
  photos,
  serviceArea,
  testimonials,
  businessHours,
  organization,
  inEditMode,
  youtubeId,
  bookingTitle,
  descriptionTitle,
  bannerText,
  showReviews,
  reviewsCount,
  address,
  showBookingCard,
}) {
  const [showBookingButton, setShowBookingButton] = useState(false)

  useEffect(
    () => {
      if (isPresent(window.HousecallChat) && !inEditMode) {
        const chatPosition = showBookingButton
          ? displacedChatPosition
          : defaultChatPosition
        window.HousecallChat.updateChatPosition(chatPosition)
      }

      if (showBookingCard && !inEditMode) {
        addScrollListenerForBookingButton(
          () => setShowBookingButton(true),
          () => setShowBookingButton(false)
        )
        return function cleanup() {
          removeScrollListenerForBookingButton(
            () => setShowBookingButton(true),
            () => setShowBookingButton(false)
          )
        }
      }
    },
    [showBookingButton, inEditMode, showBookingCard]
  )

  return (
    <Grid container justify="center">
      <Grid item xs className={classes.headerForLogoAndPhone}>
        <HeaderMobile
          logoUrl={logoUrl}
          companyName={organization.company_name}
          inEditMode={inEditMode}
        />
      </Grid>
      {isPresent(phoneNumber) && (
        <Grid item className={classes.stickPhoneIcon}>
          <IconButton href={'tel:' + phoneNumber}>
            <Phone />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12} className={classes.stickyNavbar}>
        <NavigationActions
          showFAQ={isPresent(faqs)}
          showPhotos={isPresent(photos)}
          hasAndShowReviews={!!reviewsCount && showReviews}
          showAboutUs={isPresent(description)}
          showServiceArea={isPresent(serviceArea)}
          showTestimonials={isPresent(testimonials)}
        />
      </Grid>
      <Grid item xs={12}>
        <Title
          desktopBannerUrls={desktopBannerUrls}
          mobileBannerUrls={mobileBannerUrls}
          inEditMode={inEditMode}
          isMobile={true}
          title={bannerText.title}
          description={bannerText.description}
        />
      </Grid>
      {(showBookingCard || inEditMode) && (
        <>
          <Grid item xs={11} className={classes.bookingCard}>
            <BookParent
              isMobile={true}
              title={bookingTitle}
              inEditMode={inEditMode}
              showBookingCard={showBookingCard}
            />
          </Grid>
          <Grid item xs={11}>
            <PaymentHighlightsParent inEditMode={inEditMode} />
          </Grid>
        </>
      )}
      <Grid item xs={11}>
        <Content
          companyProfile={companyProfile}
          description={description}
          inEditMode={inEditMode}
          faqs={faqs}
          photos={photos}
          youtubeId={youtubeId}
          descriptionTitle={descriptionTitle}
          showReviews={showReviews}
          address={address}
          isMobile
        />
      </Grid>
      <Grid item xs={12}>
        <Footer
          companyProfile={companyProfile}
          serviceArea={serviceArea}
          inEditMode={inEditMode}
          businessHours={businessHours}
          organization={organization}
          isMobile
        />
      </Grid>
      {showBookingCard && !inEditMode && (
        <Grid
          item
          xs={12}
          className={classNames(
            classes.stickyBookingButton,
            showBookingButton ? '' : classes.hiddenBookingButton
          )}
        >
          <BookButtonMobile />
        </Grid>
      )}
    </Grid>
  )
}

export default connect(state => ({
  companyProfile: getCompanyProfile(state),
  logoUrl: getCompanyLogo(state),
  phoneNumber: getCompanyPhoneNumber(state),
  desktopBannerUrls: getDesktopBannerUrls(state),
  mobileBannerUrls: getMobileBannerUrls(state),
  description: getCompanyDescription(state),
  faqs: getFAQs(state),
  photos: getPhotosWithAltText(state),
  serviceArea: getServiceArea(state),
  testimonials: getTestimonials(state),
  businessHours: getBusinessHours(state),
  organization: getOrganization(state),
  youtubeId: getYoutubeId(state),
  bookingTitle: getBookingTitle(state),
  descriptionTitle: getDescriptionTitle(state),
  showReviews: getShowReviews(state),
  reviewsCount: getRatingsCount(state),
  bannerText: {
    title: getBannerTextTitle(state),
    description: getBannerTextDescription(state),
  },
  address: getAddress(state),
  showBookingCard: getShowBookingCard(state),
}))(withStyles(styles)(CompanyProfileMobile))
