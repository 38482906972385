import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ContactParent from './ContactParent'
import BusinessHoursParent from './BusinessHoursParent'
import { isPresent } from 'src/common/ObjectHelper'
import PoweredIcon from './PoweredIcon'

const styles = theme => ({
  container: {
    backgroundColor: '#f7f7f7',
    paddingTop: 40,
    paddingBottom: 20,
    marginTop: 24,
  },
  content: {
    width: '100%',
    maxWidth: theme.spacing.centerContentMaxWidth,
  },
  icon: {
    opacity: 0.38,
    color: '#000000',
    width: '100%',
  },
  href: {
    textDecoration: 'none',
  },
})

function Footer({
  classes,
  businessHours,
  inEditMode,
  serviceArea,
  organization,
  showMap,
  isMobile,
}) {

  return (
    <Grid container item justify="center" className={classes.container}>
      <Grid container spacing={24} className={classes.content}>
        {(isPresent(businessHours) || inEditMode) && (
          <Grid item xs={12} sm={6} md={4}>
            <BusinessHoursParent
              isMobile={isMobile}
              businessHours={businessHours}
              inEditMode={inEditMode}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <ContactParent organization={organization} inEditMode={inEditMode} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PoweredIcon className={classes.icon} />
      </Grid>
    </Grid>
  )
}
Footer.propTypes = {
  companyProfile: PropTypes.object,
  serviceArea: PropTypes.object,
  organization: PropTypes.object,
  businessHours: PropTypes.object,
  isMobile: PropTypes.bool,
}

Footer.defaultProps = {
  isMobile: false,
}

export default withStyles(styles)(Footer)
