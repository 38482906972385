import { createSelector } from 'reselect'
import { getDefaultIndustryPhotosApi } from './Api'

export const DEFAULT_INDUSTRY_PHOTOS = 'defaultIndustryPhotos'
export const BANNER_KIND_MOBILE = 'MOBILE'
export const BANNER_KIND_DESKTOP = 'DESKTOP'
export const BANNER_KIND_TO_NAMESPACE = {
  DESKTOP: 'desktop_banner',
  MOBILE: 'mobile_banner',
}

const getDefaultIndustryPhotosNamespace = createSelector(
  state => state[DEFAULT_INDUSTRY_PHOTOS],
  photos => photos || {}
)

const getDefaultIndustryBannerPhotosObject = createSelector(
  state => getDefaultIndustryPhotosNamespace(state),
  photos => photos.industry_banner_photos || {}
)

export const getDefaultIndustryBannerPhotosData = createSelector(
  state => getDefaultIndustryBannerPhotosObject(state),
  industryBannerPhotos => industryBannerPhotos.data || []
)

export const getDefaultIndustryMobilePhotoUrls = createSelector(
  state => getDefaultIndustryBannerPhotosData(state),
  industryMobilePhotos =>
    industryMobilePhotos.map(({ mobile_url, ...rest }) => ({
      ...rest,
      original_url: mobile_url,
    }))
)

export const fetchDefaultIndustryPhotos = {
  fetchingApiFunc: getDefaultIndustryPhotosApi,
  selector: getDefaultIndustryPhotosNamespace,
  dataNamespace: DEFAULT_INDUSTRY_PHOTOS,
}
