import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Section from '../CompanyProfile/Sections/Section'
import BusinessHours from './BusinessHours'
import EditBusinessHoursDialog from './EditBusinessHoursDialog'

class BusinessHoursParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editDialogOpen: false,
    }
  }

  render() {
    const { businessHours, inEditMode, isMobile } = this.props
    return (
      <React.Fragment>
        <EditBusinessHoursDialog
          open={this.state.editDialogOpen}
          onClose={() =>
            this.setState({ isSaving: false, editDialogOpen: false })
          }
          isMobile={isMobile}
          businessHours={businessHours}
        />

        <Section
          id="business_hours"
          title="Business hours"
          showDivider={false}
          canEdit={inEditMode}
          onEditClick={() =>
            this.setState({ editDialogOpen: true, isSaving: false })
          }
        >
          <BusinessHours businessHours={businessHours} />
        </Section>
      </React.Fragment>
    )
  }
}

BusinessHoursParent.propTypes = {
  businessHours: PropTypes.object,
  inEditMode: PropTypes.bool,
}

BusinessHoursParent.defaultProps = {
  businessHours: {},
  inEditMode: false,
}

export default BusinessHoursParent
