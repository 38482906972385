import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  CardHeader,
  Switch,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { isPresent } from 'src/common/ObjectHelper'
import { CancelSaveButtons, EditAndDelete } from 'housecall-ui'
import BookContent from './BookContent'

const BOOKING_TITLE_LENGTH = 255

const styles = theme => ({
  cardWrapper: {
    paddingTop: 80, //This margin and padding top pair is for anchor tag compensating for the sticky header
    marginTop: -80,
  },
  card: {
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,-11.8), 0px 24px 38px 3px rgba(0,0,0,-0.86), 0px 9px 46px 8px rgba(0,0,0,0.12)',
    borderRadius: 7,
  },
  footer: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  defaultBookHeaderText: {
    paddingTop: 6,
  },
})

function Book({
  classes,
  title,
  inEditMode,
  isEditing,
  isSaving,
  onEditClick,
  onUpdateTitle,
  onCancelClick,
  onSaveClick,
  showBookingCard,
  onSwitchToggle,
}) {
  let headerComponent
  let defaultHeader = (
    <Typography
      gutterBottom
      variant="h5"
      align="center"
      className={classes.defaultBookHeaderText}
    >
      {title}
    </Typography>
  )
  if (inEditMode && isEditing) {
    headerComponent = (
      <Grid container justify="flex-end" spacing={8}>
        <Grid item xs={12}>
          <TextField
            inputProps={{
              maxLength: BOOKING_TITLE_LENGTH
            }}
            variant="outlined"
            onChange={event => onUpdateTitle(event)}
            defaultValue={title}
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item>
          <CancelSaveButtons
            isSaving={isSaving}
            onCancelClick={() => {
              if (isPresent(onCancelClick)) {
                onCancelClick()
              }
            }}
            onSaveClick={() => {
              if (isPresent(onSaveClick)) {
                onSaveClick()
              }
            }}
          />
        </Grid>
      </Grid>
    )
  } else if (inEditMode) {
    headerComponent = (
      <Grid container>
        <Grid item xs>
          {defaultHeader}
        </Grid>
        <Grid item>
          <EditAndDelete
            canEdit={true}
            onEditClick={() => {
              if (isPresent(onEditClick)) {
                onEditClick()
              }
            }}
          />
        </Grid>
      </Grid>
    )
  } else {
    headerComponent = defaultHeader
  }
  return (
    <div className={classes.cardWrapper} id="booking_widget">
      <Card className={classes.card} elevation={24}>
        {inEditMode && (
          <Switch
            color="primary"
            checked={showBookingCard}
            onChange={() => onSwitchToggle()}
          />
        )}
        <CardHeader title={headerComponent} />
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <BookContent
                onComplete={() => navigate('/checkout')}
                inEditMode={inEditMode}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

Book.propTypes = {
  title: PropTypes.string,
  inEditMode: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  onEditClick: PropTypes.func,
  onUpdateTitle: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
}

Book.defaultProps = {
  inEditMode: false,
  isEditing: false,
  isSaving: false,
}

export default withStyles(styles)(Book)
