import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { cityStateFormatterWithBracket } from './../CityStateFormatter'

const styles = {
  quoteTextInEdit: {
    fontStyle: 'italic',
    whiteSpace: 'pre-line',
    paddingTop: 8,
  },
  authorTextInEdit: {
    fontWeight: 'bold',
    display: 'inline-block',
  },
  cityStateTextInEdit: {
    display: 'inline-block',
    paddingLeft: 8,
  },
}

function EditModeSingleTestimonial({ classes, testimonial }) {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.authorTextInEdit}>
          {testimonial.author}
        </Typography>
        <Typography variant="h6" className={classes.cityStateTextInEdit}>
          {cityStateFormatterWithBracket(testimonial.city, testimonial.state)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.quoteTextInEdit} variant="body1">
          "{testimonial.quote}"
        </Typography>
      </Grid>
    </Grid>
  )
}

EditModeSingleTestimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditModeSingleTestimonial)
