import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Typography } from '@material-ui/core'
import Check from '@material-ui/icons/Check'

const styles = {
  check: {
    width: 30,
    height: 30,
    color: '#6CF9A7',
  },
}
function LineItemWithCheck({ text, classes }) {
  return (
    <Grid container alignItems="center" spacing={16}>
      <Grid item>
        <Check className={classes.check} />
      </Grid>
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  )
}

LineItemWithCheck.propTypes = {
  text: PropTypes.string.isRequired,
}

export default withStyles(styles)(LineItemWithCheck)
