import React, { PureComponent } from 'react'
import {
  Grid,
  TextField,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { login } from 'src/Resources/Login/Actions'
import { reduxForm } from 'redux-form'
import { hcWebUrl } from '../../../common/hcWebUrl'

const EDIT_LOGIN_FORM = 'EDIT_LOGIN_FORM'

const styles = {
  actionButtons: {
    paddingBottom: 16,
    paddingRight: 16,
  },
  forgotPasswordButton: {
    textTransform: 'none',
  },
}

@connect(
  null,
  { login }
)
@reduxForm({
  form: EDIT_LOGIN_FORM,
})
@withStyles(styles)
class LoginFragment extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { errorMessage: '', email: '', password: '', isSubmitting: false }
  }

  login() {
    const { email, password } = this.state

    if (password.length < 1 || email.length < 1) {
      this.setState({ errorMessage: 'Email and password required.' })
    } else {
      this.setState({isSubmitting: true, errorMessage: '' })
      this.props.login(this.state.email, this.state.password)
        .catch(() => {
          this.setState({
            errorMessage: 'Please enter a valid email and password.',
            isSubmitting: false
          })
        })
    }
  }

  render() {
    const { handleSubmit, classes } = this.props
    const { errorMessage, isSubmitting } = this.state

    return (
      <form onSubmit={handleSubmit(values => this.login(values))}>
        <DialogContent>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextField
                error={errorMessage}
                label="Email"
                autoFocus
                fullWidth
                onChange={event => {
                  this.setState({ email: event.target.value })
                }}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errorMessage}
                label="Password"
                type="password"
                fullWidth
                onChange={event => {
                  this.setState({ password: event.target.value })
                }}
                disabled={isSubmitting}
                helperText={errorMessage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <Button
            className={classes.forgotPasswordButton}
            href={`${hcWebUrl()}/service_pro/account/forgot_password`}
            disabled={isSubmitting}
          >
            Forgot your password?
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            Let's go
          </Button>
        </DialogActions>
      </form>
    )
  }
}

export default LoginFragment
