import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LoginFragment from './LoginFragment'
import { getLoginDialogOpen } from 'src/Resources/Login/Data'
import { Dialog, DialogTitle } from '@material-ui/core'

@connect(
  state => ({
    loginDialogOpen: getLoginDialogOpen(state),
  }),
  {}
)
export default class LoginDialog extends PureComponent {
  render() {
    const { loginDialogOpen } = this.props
    return (
      <Dialog open={loginDialogOpen} fullWidth>
        <DialogTitle id="simple-dialog-title">Log in</DialogTitle>
        <LoginFragment />
      </Dialog>
    )
  }
}
