import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import TurnOn from '../../../images/turn_on.png'

const styles = {
  button: {
    textTransform: 'none'
  },
  hintMessage: {
    color: 'rgba(0,0,0,0.54)'
  }
}

function OnlineBookingEnableConfirmationDialog({ open, onConfirm, classes }) {
  return (
    <Dialog open={open} onClose={onConfirm} fullWidth>
      <DialogTitle disableTypography>
        <Typography align="center" variant="h5">
          You're ready to be booked online
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={16}>
          <Grid item xs={10}>
            <img src={TurnOn} width="100%"/>
          </Grid>
          <Grid item>
            <Typography align="center">
              You will only be charged in the calender months <br /> when a job
              is booked from your website.
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center">
              Otherwise, your website is still completely free.
            </Typography>
          </Grid>
          <Grid item>
            <Button className={classes.button} variant="contained" color="primary" onClick={onConfirm}>
              Sounds great
            </Button>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography align="center" className={classes.hintMessage}>
              Any change will be reflected on your live site in a couple of minutes
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

OnlineBookingEnableConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default withStyles(styles)(OnlineBookingEnableConfirmationDialog)
