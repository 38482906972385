import RestAPI from 'src/common/RestApi'

export function loginApi(email, password) {
  return RestAPI.postWithFormData('/alpha/pro/login', {
    email,
    password,
  })
}

export function resetPasswordApi(email) {
  return RestAPI.postWithFormData('/pro/sessions/send_password_reset_email', {
    email,
  })
}
