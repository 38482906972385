import { createSelector } from 'reselect'
import { getCompanyProfile } from '../../CompanyProfile/Data'

export const getServiceArea = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.service_area || {}
  }
)

export const getServiceAreaPolygon = createSelector(
  state => getServiceArea(state),
  service_area => {
    return service_area.polygon || []
  }
)

export const getServiceAreaLatitude = createSelector(
  state => getServiceArea(state),
  service_area => {
    return Number(service_area.latitude) || 0
  }
)

export const getServiceAreaLongitude = createSelector(
  state => getServiceArea(state),
  service_area => {
    return Number(service_area.longitude) || 0
  }
)

export const getServiceAreaRadius = createSelector(
  state => getServiceArea(state),
  service_area => {
    return Number(service_area.radius) || 0
  }
)
