import RestAPI from 'src/common/RestApi'
import { DEFAULT_COUNT_PER_PAGE, DEFAULT_PAGE } from './Data'

export function getReviewsApi(page) {
  return RestAPI.getWithFormData(
    '/website_builder/reviews',
    {
      page: page || DEFAULT_PAGE,
      count: DEFAULT_COUNT_PER_PAGE
    }
  )
}

export function getRatingsApi() {
  return RestAPI.get('/website_builder/rating')
}
