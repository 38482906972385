import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import LineItemWithCheck from './LineItemWithCheck'

const styles = {
  defaultButton: {
    padding: '6px 21px',
    textTransform: 'none',
  },
  buttonText: {
    textTransform: 'none',
  },
  hintMessage: {
    color: 'rgba(0,0,0,0.54)'
  }
}

function OnlineBookingEnableConfirmationDialog({
  open,
  onCancel,
  onConfirm,
  classes,
}) {
  return (
    <Dialog open={open} fullWidth onClose={onCancel}>
      <DialogTitle disableTypography>
        <Typography variant="h5" align="center">
          Are you sure you want to turn off <br /> online booking?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={24}>
          <Grid item>
            <Typography variant="h6">Benefits of online booking:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <LineItemWithCheck text="Get more jobs" />
                <LineItemWithCheck text="Increase your SEO" />
                <LineItemWithCheck text="Look more professional" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={16}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCancel}
                  className={classes.buttonText}
                >
                  No, keep it on
                </Button>
              </Grid>
              <Grid item>
                <Button className={classes.defaultButton} onClick={onConfirm}>
                  Yes, turn off
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography align="center" className={classes.hintMessage}>
              Any change will be reflected on your live site in a couple of
              minutes
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

OnlineBookingEnableConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default withStyles(styles)(OnlineBookingEnableConfirmationDialog)
