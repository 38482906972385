import TablePagination from '@material-ui/core/TablePagination'
import { PropTypes } from 'prop-types'
import React, { Fragment } from 'react'

function PaginatedItems({ children, count, onChangePage, page, rowsPerPage }) {
  return (
    <Fragment>
      {children}
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_, nextPage) => onChangePage(nextPage)}
      />
    </Fragment>
  )
}

PaginatedItems.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default React.memo(PaginatedItems)
