import withStyles from '@material-ui/core/styles/withStyles'
import withMobileDialog from '@material-ui/core/withMobileDialog/withMobileDialog'
import DesktopWindows from '@material-ui/icons/DesktopWindows'
import SmartPhone from '@material-ui/icons/Smartphone'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import Grid from 'housecall-ui/dist/library/Layout/Grid'
import Dialog from 'housecall-ui/dist/library/PageElements/Dialog'
import DialogActions from 'housecall-ui/dist/library/PageElements/DialogActions'
import DialogContent from 'housecall-ui/dist/library/PageElements/DialogContent'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import EditBannerPreview from './EditBannerPreview'

const styles = theme => ({
  elementSpacing: {
    marginRight: theme.spacing.unit,
  },
  image: {
    padding: '20px 0 0',
    height: 180,
    maxWidth: '100%',
  },
  center: {
    textAlign: 'center',
  },
  titleText: {
    fontSize: 24,
    padding: '24px 24px 20px',
  },
})

function EditDesktopAndMobileBannerDialog({
  desktopBannerUrls,
  mobileBannerUrls,
  classes,
  fullScreen,
  onClose,
  onDesktopEditClick,
  onMobileEditClick,
  open,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      disableBackdropClick
    >
      <Typography className={classes.titleText}>Hero image</Typography>
      <DialogContent>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.center}>
            <EditBannerPreview
              title="Desktop preview"
              icon={<DesktopWindows />}
              onClick={onDesktopEditClick}
            >
              <img
                className={classes.image}
                src={desktopBannerUrls.original_url}
                alt="Desktop banner"
              />
            </EditBannerPreview>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <EditBannerPreview
              title="Mobile preview"
              icon={<SmartPhone />}
              onClick={onMobileEditClick}
            >
              <img
                className={classes.image}
                src={mobileBannerUrls.original_url}
                alt="Mobile banner"
              />
            </EditBannerPreview>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={() => onClose()}
        >
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDesktopAndMobileBannerDialog.propTypes = {
  desktopBannerUrls: PropTypes.object.isRequired,
  mobileBannerUrls: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
}

EditDesktopAndMobileBannerDialog.defaultProps = {
  open: false,
}

export default compose(
  withMobileDialog(),
  withStyles(styles)
)(EditDesktopAndMobileBannerDialog)
