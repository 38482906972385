import React, { PureComponent } from 'react'
import { connect } from 'src/common/connectWithPageContext'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'
import SplitPaneStickyRight from 'src/common/SplitPaneStickyRight'
import { isPresent } from 'src/common/ObjectHelper'
import { getServiceArea } from 'src/Resources/CompanyProfile/ServiceArea/Data'
import { getPhotosWithAltText } from 'src/Resources/CompanyProfile/Photos/Data'
import {
  getCompanyLogo,
  getCompanyPhoneNumber,
  getCompanyDescription,
  getOrganization,
  getAddress,
} from 'src/Resources/Organization/Data'
import {
  getBannerTextTitle,
  getBannerTextDescription,
  getShowReviews,
  getBookingTitle,
  getDescriptionTitle,
  getShowBookingCard,
} from 'src/Resources/WebsiteContent/Data'
import {
  getDesktopBannerUrls,
  getMobileBannerUrls,
  getFAQs,
  getBusinessHours,
  getYoutubeId,
  getTestimonials,
  getCompanyProfile,
} from 'src/Resources/CompanyProfile/Data'
import { getRatingsCount } from 'src/components/CompanyProfile/Reviews/Redux/Data'
import Header from '../Headers/Header'
import Title from './Title'
import Content from './Content'
import Footer from '../Footers/Footer'
import RightPaneContent from './RightPaneContent'
import ContentTemplate from './ContentTemplate'

const styles = theme => ({
  container: {
    width: 'calc(100% + 16px)',
    margin: -8,
  },
  footer: {
    zIndex: 1,
  },
  content: {
    width: '100%',
    maxWidth: theme.spacing.centerContentMaxWidth,
  },
})

@connect(state => ({
  companyProfile: getCompanyProfile(state),
  logoUrl: getCompanyLogo(state),
  desktopBannerUrls: getDesktopBannerUrls(state),
  mobileBannerUrls: getMobileBannerUrls(state),
  phoneNumber: getCompanyPhoneNumber(state),
  description: getCompanyDescription(state),
  faqs: getFAQs(state),
  photos: getPhotosWithAltText(state),
  serviceArea: getServiceArea(state),
  testimonials: getTestimonials(state),
  businessHours: getBusinessHours(state),
  organization: getOrganization(state),
  youtubeId: getYoutubeId(state),
  bookingTitle: getBookingTitle(state),
  descriptionTitle: getDescriptionTitle(state),
  showReviews: getShowReviews(state),
  reviewsCount: getRatingsCount(state),
  bannerText: {
    title: getBannerTextTitle(state),
    description: getBannerTextDescription(state),
  },
  address: getAddress(state),
  showBookingCard: getShowBookingCard(state),
}))
@withStyles(styles)
export default class CompanyProfileDesktop extends PureComponent {
  render() {
    const {
      classes,
      companyProfile,
      desktopBannerUrls,
      mobileBannerUrls,
      stickyHeader,
      inEditMode,
      phoneNumber,
      logoUrl,
      description,
      faqs,
      photos,
      serviceArea,
      testimonials,
      businessHours,
      organization,
      youtubeId,
      bookingTitle,
      descriptionTitle,
      bannerText,
      showReviews,
      reviewsCount,
      address,
      showBookingCard,
    } = this.props

    return (
      <Grid item container justify='center' className={classes.container}>
        <Grid item xs={12}>
          <Header
            logoUrl={logoUrl}
            phoneNumber={phoneNumber}
            stickyHeader={stickyHeader}
            inEditMode={inEditMode}
            showFAQ={isPresent(faqs)}
            showPhotos={isPresent(photos)}
            showReviews={showReviews}
            hasReviews={!!reviewsCount}
            showAboutUs={isPresent(description)}
            showServiceArea={isPresent(serviceArea)}
            showTestimonials={isPresent(testimonials)}
            showBookOnline={showBookingCard}
            companyName={organization.company_name}
          />
        </Grid>
        <Grid item xs={12}>
          <Title
            desktopBannerUrls={desktopBannerUrls}
            mobileBannerUrls={mobileBannerUrls}
            inEditMode={inEditMode}
            isMobile={false}
            title={bannerText.title}
            description={bannerText.description}
          />
        </Grid>
        <ContentTemplate
          leftPane={
            <Content
              companyProfile={companyProfile}
              description={description}
              inEditMode={inEditMode}
              faqs={faqs}
              photos={photos}
              youtubeId={youtubeId}
              descriptionTitle={descriptionTitle}
              showReviews={showReviews}
              address={address}
            />
          }
          rightPane={
            <RightPaneContent
              inEditMode={inEditMode}
              title={bookingTitle}
              showBookingCard={showBookingCard}
            />
          }
          showRightPane={inEditMode || showBookingCard}
        />
        <Grid item xs={12} className={classes.footer}>
          <Footer
            companyProfile={companyProfile}
            serviceArea={serviceArea}
            inEditMode={inEditMode}
            businessHours={businessHours}
            organization={organization}
          />
        </Grid>
      </Grid>
    )
  }
}

CompanyProfileDesktop.propTypes = {
  stickyHeader: PropTypes.bool,
}

CompanyProfileDesktop.defaultProps = {
  stickyHeader: true,
}
