import { receivedServerData } from 'data-fetcher'
import { organizationProfile } from './Api'
import { setUuid } from '../../utils/OrgConstants'

const ORGANIZATION_PROFILE = 'organizationProfile'

export const getOrganizationProfile = () => (dispatch) => (
  organizationProfile()
    .then((profile) => {
      setUuid(profile.data.id)
      return dispatch(receivedServerData(profile.data, ORGANIZATION_PROFILE))
  })
)
