import React, { PureComponent, Fragment } from 'react'
import Section from '../Sections/Section'
import SingleTestimonial from './SingleTestimonial'
import MobileStepper from '@material-ui/core/MobileStepper'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'

const AutoPlaySwipableViews = autoPlay(SwipeableViews)

const styles = {
  slide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  testimonialsBody: {
    paddingBottom: 12,
  },
  mobileStepperDots: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

@withStyles(styles)
class TestimonialsCarousel extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
    }
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep: activeStep })
  }

  render() {
    const { classes, testimonials } = this.props
    const { activeStep } = this.state

    if (isBlank(testimonials)) {
      return null
    }

    return (
      <Section
        title={this.props.sectionTitle}
        canEdit={this.props.canEdit}
        onEditClick={() => this.props.onEditClick()}
      >
        <Fragment>
          <AutoPlaySwipableViews
            index={activeStep}
            interval={7500}
            onChangeIndex={activeStep => this.handleStepChange(activeStep)}
            enableMouseEvents
            className={classes.testimonialsBody}
            slideClassName={classes.slide}
          >
            {testimonials.map((testimonial, key) => (
              <SingleTestimonial testimonial={testimonial} key={key} />
            ))}
          </AutoPlaySwipableViews>
          <MobileStepper
            steps={testimonials.length}
            position="static"
            activeStep={activeStep}
            classes={{ dots: classes.mobileStepperDots }}
          />
        </Fragment>
      </Section>
    )
  }
}

TestimonialsCarousel.propTypes = {
  testimonials: PropTypes.array,
  sectionTitle: PropTypes.string.isRequired,
}

TestimonialsCarousel.defaultProps = {
  testimonials: [],
  sectionTitle: '',
}

export default TestimonialsCarousel
