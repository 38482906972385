import { receivedServerData } from 'data-fetcher'
import { createSelector } from 'reselect'
import { isPresent } from 'src/common/ObjectHelper'

const POLYGON_EDITED_NAMESPACE = 'isPolygonEdited'

export const updatePolygonEditedState = isEdited =>
  receivedServerData({ isEdited: isEdited }, POLYGON_EDITED_NAMESPACE)

export const isPolygonEdited = createSelector(
  state => state[POLYGON_EDITED_NAMESPACE],
  polygonState => {
    return isPresent(polygonState) ? polygonState.isEdited : false
  }
)
