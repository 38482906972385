import { createSelector } from 'reselect'
import { isPresent } from 'src/common/ObjectHelper'

export const getLoginDialogOpen = createSelector(
  state => state.loginDialogOpen,
  loginDialogOpen => {
    if (isPresent(loginDialogOpen)) {
      return loginDialogOpen.open
    }
    return true
  }
)
