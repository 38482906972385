import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CompanyHighlights from './CompanyHighlights'
import CompanyHighlightsEditDialog from './CompanyHighlightsEditDialog'
import {
  getCompanyHighlights,
  getPaymentHighlights,
} from 'src/Resources/CompanyProfile/Highlights/Data'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import SectionWithoutHeader from '../../Sections/SectionWithoutHeader'
import { updateHighlights } from '../Redux/Actions'

@connect(
  state => ({
    companyHighlights: getCompanyHighlights(state),
    paymentHighlights: getPaymentHighlights(state),
  }),
  { updateHighlights }
)
export default class CompanyHighlightsParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false,
    }
  }

  openEditDialog() {
    this.setState({ isDialogOpen: true })
  }

  closeEditDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { inEditMode, companyHighlights, paymentHighlights } = this.props
    return (
      <Fragment>
        <SectionWithoutHeader
          showDivider={false}
          addButtonText="ADD A COMPANY HIGHLIGHT"
          showAddButton={inEditMode && isBlank(companyHighlights)}
          onAddButtonClick={() => this.openEditDialog()}
          canEdit={inEditMode && isPresent(companyHighlights)}
          onEditClick={() => this.openEditDialog()}
        >
          {isPresent(companyHighlights) && (
            <CompanyHighlights companyHighlights={companyHighlights} />
          )}
        </SectionWithoutHeader>
        <CompanyHighlightsEditDialog
          open={this.state.isDialogOpen}
          onClose={() => this.closeEditDialog()}
          companyHighlights={companyHighlights}
          paymentHighlights={paymentHighlights}
        />
      </Fragment>
    )
  }
}

CompanyHighlightsParent.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  companyHighlights: PropTypes.arrayOf(PropTypes.object),
  paymentHighlights: PropTypes.arrayOf(PropTypes.object),
}

CompanyHighlightsParent.defaultProps = {
  inEditMode: false,
  companyHighlights: [],
  paymentHighlights: [],
}
