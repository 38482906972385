import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import {
  getCompanyLogo,
  getOrganization,
} from 'src/Resources/Organization/Data'
import { getServiceLocation } from '../../Redux/ServiceLocation/Data'
import { updateServiceLocation } from '../../Redux/ServiceLocation/Actions'
import { getSelectedBookingWindow } from '../../Redux/SelectedBookingWindow/Data'
import { updateSelectedBookingWindow } from '../../Redux/SelectedBookingWindow/Actions'
import {
  getBookingWindows,
  getServiceDate,
} from '../../Redux/BookingWindowsForSelectedDate/Data'
import {
  getBookingWindowsForDate,
  updateBookingWindows,
} from '../../Redux/BookingWindowsForSelectedDate/Actions'
import BookingWindows from './BookingWindows/BookingWindows'
import ServiceDateTextField from './ServiceDateTextField'
import ServiceLocationPopUpDialog from './ServiceLocationPopUpDialog'
import ServiceLocationFullScreenDialog from './ServiceLocationFullScreenDialog'

@connect(
  state => ({
    serviceDate: getServiceDate(state),
    bookingWindows: getBookingWindows(state),
    serviceLocation: getServiceLocation(state),
    selectedBookingWindow: getSelectedBookingWindow(state),
    logoUrl: getCompanyLogo(state),
    organization: getOrganization(state),
  }),
  {
    getBookingWindowsForDate,
    updateBookingWindows,
    updateSelectedBookingWindow,
    updateServiceLocation,
  }
)
export default class BookContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      serviceDialogOpen: false,
      dateSelected: new Date(),
    }
  }

  componentDidMount() {
    this.fetchWindowsForDate()
  }

  fetchWindowsForDate(date) {
    const { getBookingWindowsForDate, updateBookingWindows } = this.props
    return getBookingWindowsForDate(date).then(response => {
      updateBookingWindows(
        response.data.start_date,
        response.data.booking_windows.data
      )
    })
  }

  bookingWindowSelected(bookingWindow) {
    const { updateSelectedBookingWindow } = this.props
    updateSelectedBookingWindow(bookingWindow)
    this.setState({ serviceDialogOpen: true })
  }

  closeDialog() {
    this.setState({ serviceDialogOpen: false })
  }

  render() {
    const {
      serviceDate,
      serviceLocation,
      selectedBookingWindow,
      updateServiceLocation,
      bookingWindows,
      onComplete,
      inEditMode,
      logoUrl,
      organization,
    } = this.props
    const { serviceDialogOpen, dateSelected } = this.state

    return (
      <Fragment>
        <Hidden smDown>
          <ServiceLocationPopUpDialog
            serviceLocation={serviceLocation}
            onLocationSelected={serviceLocation =>
              updateServiceLocation(serviceLocation)
            }
            open={serviceDialogOpen}
            onClose={() => this.closeDialog()}
            onComplete={() => onComplete()}
          />
        </Hidden>
        <Hidden mdUp>
          <ServiceLocationFullScreenDialog
            logoUrl={logoUrl}
            companyName={organization.company_name}
            serviceLocation={serviceLocation}
            onLocationSelected={serviceLocation =>
              updateServiceLocation(serviceLocation)
            }
            open={serviceDialogOpen}
            onClose={() => this.closeDialog()}
            onComplete={() => onComplete()}
          />
        </Hidden>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <ServiceDateTextField
              serviceDate={serviceDate}
              dateSelected={date => {
                this.setState({ dateSelected: date })
                this.fetchWindowsForDate(date)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {serviceDate && (
              <BookingWindows
                inEditMode={inEditMode}
                selectedBookingWindow={selectedBookingWindow}
                bookingWindows={bookingWindows}
                dateSelected={dateSelected}
                windowSelected={selectedBookingWindow =>
                  this.bookingWindowSelected(selectedBookingWindow)
                }
                onEditWindows={date => this.fetchWindowsForDate(date)}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

BookContent.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

BookContent.defaultProps = {
  inEditMode: false,
}
