import RestAPI from 'src/common/RestApi'
import { isBlank } from 'src/common/ObjectHelper'

export function updateFeaturedVideoApi(youtubeId) {
  let formData = new FormData()
  if (isBlank(youtubeId)) {
    formData.append('youtube_id', '')
  } else {
    formData.append('youtube_id', youtubeId)
  }
  return RestAPI.updateWithFormData('/alpha/organization/content_video', formData)
}
