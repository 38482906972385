import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isPresent } from './ObjectHelper'
import Button from '@material-ui/core/Button'

function CancelContinueButtons({
  saveButtonVariant,
  onCancelClick,
  onContinueClick,
  disabled,
}) {
  return (
    <Fragment>
      <Button
        onClick={() => {
          if (isPresent(onCancelClick)) {
            onCancelClick()
          }
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={disabled}
        variant={saveButtonVariant}
        color="primary"
        onClick={() => {
          if (isPresent(onContinueClick)) {
            onContinueClick()
          }
        }}
      >
        Continue
      </Button>
    </Fragment>
  )
}

CancelContinueButtons.propTypes = {
  saveButtonVariant: PropTypes.oneOf(['text', 'contained']),
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  disabled: PropTypes.bool,
}

CancelContinueButtons.defaultProps = {
  disabled: false,
  saveButtonVariant: 'contained',
}

export default CancelContinueButtons
