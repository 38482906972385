import React, { PureComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FetchData } from 'data-fetcher'
import { Typography } from '@material-ui/core'
import PaginatedItems from 'src/common/PaginatedItems'
import SectionWithSwitchToggle from '../Sections/SectionWithSwitchToggle'
import {
  getRatingsCount,
  getOverallRating,
  getReviewsList,
  getReviewsCount,
  fetchRatings,
  fetchReviews,
  DEFAULT_COUNT_PER_PAGE,
  DEFAULT_PAGE,
} from './Redux/Data'
import { updateWebsiteContent } from '../Book/Redux/Actions'
import { getMoreReviews } from './Redux/Actions'
import { ReviewItems } from 'housecall-ui'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import ReviewsStarsAndCount from './ReviewsStarsAndCount'

@FetchData(fetchRatings, true)
@FetchData(fetchReviews, true)
@connect(
  state => ({
    ratingsCount: getRatingsCount(state),
    overallRating: getOverallRating(state),
    reviewsList: getReviewsList(state),
    reviewsCount: getReviewsCount(state),
  }),
  { getMoreReviews, updateWebsiteContent }
)
export default class ReviewsParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      page: DEFAULT_PAGE,
      editDialogOpen: false,
      showReviews: this.props.showReviews,
      isSaving: false,
    }
  }

  onToggleSwitch() {
    this.setState(
      { isSaving: true, showReviews: !this.state.showReviews },
      () => {
        this.props
          .updateWebsiteContent({ show_reviews: this.state.showReviews })
          .then(() => {
            this.setState({ isSaving: false })
          })
      }
    )
  }

  convertIntToFloatNumber(overallRating) {
    const ratingString = overallRating.toString()
    return ratingString.length === 1 ? overallRating.toFixed(1) : overallRating
  }

  onChangePage(page) {
    this.props.getMoreReviews(page + 1)
    this.setState({ page: page })
  }

  render() {
    const {
      reviewsList,
      ratingsCount,
      overallRating,
      reviewsCount,
      inEditMode,
    } = this.props

    if (isBlank(ratingsCount) || ratingsCount == 0) {
      return null
    }

    return (
      <SectionWithSwitchToggle
        id="reviews"
        title="Reviews"
        canEdit={inEditMode}
        isToggledOn={this.state.showReviews}
        onToggleSwitch={() => this.onToggleSwitch()}
        disabled={this.state.isSaving}
      >
        <Grid container spacing={24}>
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="h2">{this.convertIntToFloatNumber(overallRating)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ReviewsStarsAndCount
                ratingsCount={ratingsCount}
                overallRating={overallRating}
              />
            </Grid>
          </Grid>
          {isPresent(reviewsCount) && reviewsCount != 0 && (
            <Grid item xs={12}>
              <PaginatedItems
                count={reviewsCount}
                onChangePage={page => this.onChangePage(page)}
                page={this.state.page}
                rowsPerPage={DEFAULT_COUNT_PER_PAGE}
              >
                <ReviewItems reviews={reviewsList} />
              </PaginatedItems>
            </Grid>
          )}
        </Grid>
      </SectionWithSwitchToggle>
    )
  }
}

ReviewsParent.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  showReviews: PropTypes.bool,
}

ReviewsParent.defaultProps = {
  inEditMode: false,
  showReviews: true,
}
