import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'
import { receivedCompanyProfileData } from 'src/Resources/CompanyProfile/Data'
import { getDefaultIndustryName } from 'src/Resources/Organization/Data'
import { createFAQ, removeFAQCreationFields, updateFAQ } from './Redux/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { FaqInEdit, CUSTOM_QUESTION } from 'housecall-ui'

@connect(
  state => ({
    industryName: getDefaultIndustryName(state),
  }),
  {
    createFAQ,
    updateFAQ,
    receivedCompanyProfileData,
    removeFAQCreationFields,
    displayErrorDialog,
  }
)
class IsEdittingFAQ extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      updatedQuestion: props.faq.question || '',
      updatedAnswer: props.faq.answer || '',
      isSaving: false,
      selectOther: false,
    }
  }

  handleCancel() {
    if (this.props.variant === 'create') {
      this.props.removeFAQCreationFields()
    }
    this.props.onCancelClick()
  }

  handleSave() {
    this.setState({ isSaving: true })
    if (this.props.variant === 'create') {
      this.onSaveCreate()
    } else {
      this.onSaveUpdate()
    }
  }

  resetState() {
    this.setState({
      isSaving: false,
      updatedQuestion: '',
      updatedAnswer: '',
      selectOther: false,
    })
  }

  onSaveCreate() {
    this.props
      .createFAQ(this.state.updatedQuestion, this.state.updatedAnswer)
      .then(response => {
        this.props.onSaveClick()
      })
      .catch(error => {
        this.props.displayErrorDialog('Failed to save new FAQ. Please try again.')
      })
      .finally(this.resetState())
  }

  onSaveUpdate() {
    this.props
      .updateFAQ(
        this.props.faq.id,
        this.state.updatedQuestion,
        this.state.updatedAnswer
      )
      .then(response => {
        this.props.onSaveClick()
      })
      .catch(error => {
        this.props.displayErrorDialog('Failed to update FAQ. Please try again.')
      })
      .finally(this.resetState())
  }

  handleSelect(event) {
    if (event.target.value === CUSTOM_QUESTION) {
      this.setState({
        updatedQuestion: '',
        selectOther: true,
        updatedAnswer: '',
      })
    } else {
      this.setState({
        updatedQuestion: event.target.value,
        selectOther: false,
        updatedAnswer: '',
      })
    }
  }

  render() {
    const { updatedQuestion, updatedAnswer, selectOther, isSaving } = this.state
    const { industryName } = this.props

    return (
      <FaqInEdit
        industry={industryName}
        updatedQuestion={updatedQuestion}
        updatedAnswer={updatedAnswer}
        onSelectQuestion={event => this.handleSelect(event)}
        onChangeQuestion={event =>
          this.setState({ updatedQuestion: event.target.value })
        }
        onChangeAnswer={event =>
          this.setState({ updatedAnswer: event.target.value })
        }
        isSaving={isSaving}
        disabled={isBlank(updatedQuestion) || isBlank(updatedAnswer)}
        onCancelClick={() => this.handleCancel()}
        onSaveClick={() => this.handleSave()}
        selectOther={selectOther}
      />
    )
  }
}

IsEdittingFAQ.propTypes = {
  faq: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['create', 'update']),
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
}

IsEdittingFAQ.defaultProps = {
  variant: 'update',
  onCancelClick: () => {},
  onSaveClick: () => {},
}

export default IsEdittingFAQ
