import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FrequentlyAskedQuestionsParent from './FrequentlyAskedQuestions/FrequentlyAskedQuestionsParent'
import ServiceAreaParent from './ServiceArea/ServiceAreaParent'
import FeaturedVideoCard from './FeaturedVideo/FeaturedVideoCard'
import PhotoGallery from './PhotoGallery/PhotoGallery'
import TestimonialsParent from './Testimonials/TestimonialsParent'
import ReviewsParent from './Reviews/ReviewsParent'
import CompanyDescription from './CompanyDescription/CompanyDescription'

function Content({
  companyProfile,
  inEditMode,
  isMobile,
  description,
  faqs,
  photos,
  youtubeId,
  descriptionTitle,
  showReviews,
  address
}) {
  return (
    <Fragment>
      <CompanyDescription
        description={description}
        inEditMode={inEditMode}
        descriptionTitle={descriptionTitle}
      />
      <FeaturedVideoCard
        videoId={youtubeId == '' ? null : youtubeId}
        inEditMode={inEditMode}
      />
      <ServiceAreaParent
        serviceArea={companyProfile.service_area}
        address={address}
        inEditMode={inEditMode}
      />
      <TestimonialsParent
        testimonials={companyProfile.testimonials}
        inEditMode={inEditMode}
      />
      {(showReviews || inEditMode) && (
        <ReviewsParent inEditMode={inEditMode} showReviews={showReviews} />
      )}
      <FrequentlyAskedQuestionsParent faqs={faqs} inEditMode={inEditMode} />
      <PhotoGallery
        photos={photos}
        isMobile={isMobile}
        inEditMode={inEditMode}
      />
    </Fragment>
  )
}

Content.propTypes = {
  companyProfile: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  description: PropTypes.string,
  isMobile: PropTypes.bool,
  faqs: PropTypes.array,
  photos: PropTypes.array,
  descriptionTitle: PropTypes.string,
  showReviews: PropTypes.bool,
  address: PropTypes.object
}

Content.defaultProps = {
  inEditMode: false,
  isMobile: false,
  showReviews: true,
  faqs: [],
  photos: [],
  descriptionTitle: 'About us',
  address: {}
}

export default React.memo(Content)
