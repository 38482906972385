import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isBlank } from 'src/common/ObjectHelper'
import Section from '../Sections/Section'
import PhotoGridListParent from './PhotoGridListParent'
import { uploadPhoto, deletePhoto } from './Redux/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { DragDropContext } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'

function PhotoGallery({
  inEditMode,
  photos,
  uploadPhoto,
  deletePhoto,
  displayErrorDialog,
}) {
  if (isBlank(photos) && !inEditMode) {
    return null
  } else {
    return (
      <Section id='photos' title='Photos'>
        <PhotoGridListParent
          photos={photos}
          canEdit={inEditMode}
          uploadPhoto={photo => uploadPhoto(photo)}
          deletePhoto={photo => deletePhoto(photo)}
          handlePhotoUploadError={errors => {
            if (errors.length) {
              displayErrorDialog(
                errors.length > 1 || isBlank(errors[0])
                  ? `${errors.length} image(s) failed to upload. Please try again.`
                  : errors[0]
              )
            }
          }}
        />
      </Section>
    )
  }
}

PhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object),
  inEditMode: PropTypes.bool.isRequired,
}

PhotoGallery.defaultProps = {
  photos: [],
  inEditMode: false,
}

const HOCs = compose(
  connect(
    null,
    { uploadPhoto, deletePhoto, displayErrorDialog }
  ),
  DragDropContext(MultiBackend(HTML5toTouch))
)

export default HOCs(PhotoGallery)
