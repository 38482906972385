import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Section from '../Sections/Section'
import { isBlank, isPresent } from '../../../common/ObjectHelper'
import { connect } from 'react-redux'
import { updateFeaturedVideo } from './Edit/Redux/Actions'
import EditBody from './EditBody'
import { ConfirmationDialog } from 'housecall-ui'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'

@connect(
  null,
  { updateFeaturedVideo, displayErrorDialog }
)
export default class FeaturedVideoCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showEdit: false,
      disabled: false,
      isSaving: false,
      newVideoLink: this.props.videoId,
      showConfirmation: false,
    }
  }

  onEditClick() {
    this.setState({ showEdit: true, isSaving: false })
  }

  onSave() {
    this.setState({ isSaving: true })
    this.props
      .updateFeaturedVideo(this.state.newVideoLink)
      .then(() => {
        this.setState({
          isSaving: false,
          showEdit: false,
        })
      })
      .catch(error => {
        this.setState({
          isSaving: false,
        })
        this.props.displayErrorDialog('Something is wrong. Please try again')
      })
      .finally(this.setState({ isSaving: false }))
  }

  render() {
    const { videoId, inEditMode } = this.props
    if (isBlank(videoId) && !inEditMode) {
      return null
    }
    return (
      <Fragment>
        <Section
          id="featured_video"
          title="Featured video"
          canEdit={isPresent(videoId) && inEditMode}
          onEditClick={() => this.onEditClick()}
          onDeleteClick={() => this.setState({ showConfirmation: true })}
          showAddButton={
            isBlank(videoId) && inEditMode === true && !this.state.showEdit
          }
          addButtonText={'ADD A YOUTUBE VIDEO'}
          onAddButtonClick={() => this.onEditClick()}
          deleteEnable={true}
          showDivider={true}
        >
          <EditBody
            showEdit={this.state.showEdit}
            videoId={videoId}
            onChange={youtubeId => {
              this.setState({
                newVideoLink: youtubeId,
              })
            }}
            disabled={this.state.disabled}
            handleCancel={() =>
              this.setState({
                disabled: false,
                isSaving: false,
                showEdit: false,
                newVideoLink: videoId,
              })
            }
            handleSave={() => this.onSave()}
            isSaving={this.state.isSaving}
            disableSave={disable => this.setState({ disabled: disable })}
          />
        </Section>
        <ConfirmationDialog
          open={this.state.showConfirmation}
          message_title="Are you sure you want to delete this video?"
          message_body=""
          cancelButtonMessage="No"
          confirmButtonMessage="Yes"
          onCancelClick={() => this.setState({ showConfirmation: false })}
          onConfirmClick={() => {
            this.setState(
              { newVideoLink: '', showEdit: false, showConfirmation: false },
              this.onSave
            )
          }}
        />
      </Fragment>
    )
  }
}

FeaturedVideoCard.propTypes = {
  videoId: PropTypes.string,
  inEditMode: PropTypes.bool,
}

FeaturedVideoCard.defaultProps = {
  inEditMode: false,
}
