import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import { PAYMENT_HIGHLIGHTS } from '../../CompanyDescription/Redux/Data'

const styles = theme => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

function PaymentHighlights({ classes, paymentHighlights }) {
  const displayOrder = ['accepts_credit_card', 'accepts_cash', 'accepts_checks']
  const selectedHighlightsText = []

  displayOrder.forEach(sortedHighlight => {
    if (currentHighlightSelected(paymentHighlights, sortedHighlight)) {
      selectedHighlightsText.push(PAYMENT_HIGHLIGHTS[sortedHighlight].text)
    }
  })

  return (
    <Grid container alignItems="center" spacing={8}>
      <Grid item className={classes.iconContainer}>
        <MonetizationOn />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {formatHighlightsText(selectedHighlightsText)}
        </Typography>
      </Grid>
    </Grid>
  )
}

function formatHighlightsText(selectedHighlightsText) {
  let formattedHighlightText = ''

  selectedHighlightsText.forEach((selectedHighlightText, index) => {
    let correctCaseHighlightText = selectedHighlightText

    if (index > 0) {
      correctCaseHighlightText = selectedHighlightText.toLowerCase()
    }

    formattedHighlightText = formattedHighlightText + correctCaseHighlightText

    if (index < selectedHighlightsText.length - 1) {
      formattedHighlightText = formattedHighlightText + ', '
    }
  })

  return formattedHighlightText
}

function currentHighlightSelected(paymentHighlights, sortedHighlight) {
  return paymentHighlights.some(
    highlightObject => highlightObject.name == sortedHighlight
  )
}

PaymentHighlights.propTypes = {
  paymentHighlights: PropTypes.arrayOf(PropTypes.object),
}

PaymentHighlights.defaultProps = {
  paymentHighlights: [],
}

export default withStyles(styles)(React.memo(PaymentHighlights))
