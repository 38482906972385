import React, { Fragment, PureComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Grid, withStyles, Tooltip } from '@material-ui/core'
import SchedulePopover from './SchedulePopover'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'
import { isBlank } from 'src/common/ObjectHelper'

const styles = theme => ({
  lastColumn: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid ' + theme.palette.divider,
    },
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid ' + theme.palette.divider,
    },
  },
  column: {
    borderLeft: '1px solid ' + theme.palette.divider,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid ' + theme.palette.divider,
    },
  },
  windowsWrapper: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    padding: 8,
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      borderRight: '1px solid ' + theme.palette.divider,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
  },
  header: {
    padding: 8,
    minWidth: 60,
    [theme.breakpoints.down('sm')]: {
      borderRight: '1px solid ' + theme.palette.divider,
    },
  },
  button: {
    background: theme.palette.background.paper,
    marginTop: theme.spacing.unit / 2,
    [theme.breakpoints.down('sm')]: {
      marginRight: 4,
    },
    '&:hover': {
      color: theme.palette.button.destructiveHover,
      background: theme.palette.button.destructiveLightHover,
      borderColor: theme.palette.button.destructiveHover,
    },
  },
  addButton: {
    marginTop: theme.spacing.unit / 2,
  },
})

class DayColumn extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { popoverOpen: false, popoverAnchorEl: null }
  }

  onWindowDeleted(index) {
    let windows = [...this.props.windows]
    windows.splice(index, 1)
    this.props.windowsDidUpdate(windows)
  }

  onWindowAdded(windowToAdd) {
    this.setState({ popoverOpen: false })
    const { windows } = this.props
    if (isBlank(windows)) {
      windows.push(windowToAdd)
      return
    }

    for (let index = 0; index < windows.length; index++) {
      if (
        this.windowIsLessThanOther(
          windowToAdd.startTime,
          windows[index].startTime
        )
      ) {
        windows.splice(index, 0, windowToAdd)
        return
      }
    }

    windows.push(windowToAdd)
    this.props.windowsDidUpdate(windows)
  }

  windowIsLessThanOther(firstWindow, secondWindow) {
    let firstParts = firstWindow.split(':')
    let secondParts = secondWindow.split(':')

    if (firstParts.length != 2 || secondParts.length != 2) {
      return false
    }

    if (parseInt(firstParts[0]) == parseInt(secondParts[0])) {
      return parseInt(firstParts[1]) < parseInt(secondParts[1])
    }

    return parseInt(firstParts[0]) < parseInt(secondParts[0])
  }

  isLastWindow(index, windows) {
    return index == windows.length - 1
  }

  startTimeIsBeforeStartOfOtherWindow(window, otherWindow) {
    return window.startTime > otherWindow.startTime
  }

  formateTimeForDisplay(time) {
    const DEFAULT_HOUR = 12
    const DEFAULT_MINUTE = 0

    let timeComponents = time.split(':')
    let hour = timeComponents[0] ? parseInt(timeComponents[0]) : DEFAULT_HOUR
    let minutes = timeComponents[1] ? parseInt(timeComponents[1]) : DEFAULT_MINUTE

    if (minutes < 10) {
      minutes = '0' + minutes
    }

    let maridian = hour > 11 ? 'pm' : 'am'
    hour = hour % 12
    if (hour == 0) {
      hour = 12
    }

    return '' + hour + ':' + minutes + ' ' + maridian
  }

  buildWindows() {
    return this.props.windows.map((window, index) => (
      <Tooltip title={'Remove window'} key={index}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={this.props.classes.button}
          fullWidth={!this.props.mobile}
          onClick={() => {
            this.onWindowDeleted(index)
          }}
        >
          {this.formateTimeForDisplay(window.startTime)}-
          {this.formateTimeForDisplay(window.endTime)}
        </Button>
      </Tooltip>
    ))
  }

  render() {
    const { classes, dayName, lastColumn } = this.props
    return (
      <Grid
        justify="center"
        container
        className={classNames(
          classes.column,
          lastColumn ? classes.lastColumn : ''
        )}
      >
        <Grid item xs={12} className={classes.header}>
          <Typography align="center" variant="h6">
            {dayName}
          </Typography>
        </Grid>
        <Grid item sx={12} className={classes.windowsWrapper}>
          {this.buildWindows()}
          <Button
            disabled={this.props.disableAdd}
            className={classes.addButton}
            size="small"
            variant="contained"
            color="primary"
            onClick={event =>
              this.setState({
                popoverOpen: true,
                popoverAnchorEl: event.currentTarget,
              })
            }
          >
            Add +
          </Button>
        </Grid>
        <SchedulePopover
          open={this.state.popoverOpen}
          anchorEl={this.state.popoverAnchorEl}
          onClose={() => this.setState({ popoverOpen: false })}
          onSave={window => {
            this.onWindowAdded(window)
          }}
        />
      </Grid>
    )
  }
}

export default withStyles(styles)(DayColumn)

DayColumn.propTypes = {
  disableAdd: PropTypes.bool,
  mobile: PropTypes.bool,
  dayName: PropTypes.string.isRequired,
  windows: PropTypes.array.isRequired,
  windowsDidUpdate: PropTypes.func.isRequired,
}

DayColumn.defaultProps = {
  disableAdd: false,
  mobile: false,
}
