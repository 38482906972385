import React, { PureComponent } from 'react'
import { CancelSaveButtons } from 'housecall-ui'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import PaymentHighlightsEditForm from './PaymentHighlightsEditForm'
import { updateHighlights } from '../../CompanyDescription/Redux/Actions'

@connect(
  null,
  { updateHighlights }
)
export default class PaymentHighlightsEditDialog extends PureComponent {
  constructor(props) {
    super(props)
    let preselectedCheckboxes = new Set()
    for (const item of props.paymentHighlights) {
      preselectedCheckboxes.add(item.name)
    }
    this.state = {
      selectedCheckboxes: preselectedCheckboxes,
    }
  }

  handleCheckboxToggle(paymentHighlight) {
    let updatedSelectedCheckboxes = this.state.selectedCheckboxes
    if (this.state.selectedCheckboxes.has(paymentHighlight)) {
      updatedSelectedCheckboxes.delete(paymentHighlight)
    } else {
      updatedSelectedCheckboxes.add(paymentHighlight)
    }
    this.setState({ selectedCheckboxes: updatedSelectedCheckboxes })
  }

  handleFormSubmit = event => {
    event.preventDefault()

    let paymentHighlights = []
    for (const checkbox of this.state.selectedCheckboxes) {
      paymentHighlights.push({ name: checkbox, highlight_value: null })
    }

    this.props
      .updateHighlights(paymentHighlights.concat(this.props.companyHighlights))
      .then(() => {
        this.props.onClose()
      })
  }

  render() {
    const { open, onClose, paymentHighlights } = this.props
    return (
      <Dialog open={open} onClose={() => onClose()}>
        <form onSubmit={event => this.handleFormSubmit(event)}>
          <DialogTitle>We accept the following payments</DialogTitle>
          <DialogContent>
            <PaymentHighlightsEditForm
              handleCheckboxToggle={event => this.handleCheckboxToggle(event)}
              paymentHighlights={paymentHighlights}
            />
          </DialogContent>
          <DialogActions>
            <CancelSaveButtons
              saveButtonVariant="text"
              onCancelClick={() => onClose()}
            />
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

PaymentHighlightsEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paymentHighlights: PropTypes.arrayOf(PropTypes.object),
}

PaymentHighlightsEditDialog.defaultProps = {
  open: false,
  onClose: () => {},
  paymentHighlights: [],
}
