import React from 'react'
import { withStyles, Divider, Button, Grid } from '@material-ui/core'

const styles = {
  container: {
    background: '#fff',
    padding: 20,
  },
}

function BookButtonMobile({ classes }) {
  return (
    <div>
      <Divider />
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            onClick={() => (window.location.href = '#booking_widget')}
          >
            Book us online
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(BookButtonMobile)
