import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles, TextField } from '@material-ui/core'
import { compose } from 'redux'
import Section from '../Sections/Section'
import { isBlank } from 'src/common/ObjectHelper'
import { connect } from 'react-redux'
import { updateProfile } from './Redux/Actions'
import { updateWebsiteContent } from 'src/Resources/WebsiteContent/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'

const styles = {
  descriptionText: {
    whiteSpace: 'pre-line',
  },
}

function AboutUs({
  inEditMode,
  description,
  descriptionTitle,
  updateProfile,
  updateWebsiteContent,
  displayErrorDialog,
  classes,
}) {
  const [saving, setSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [updatedDescription, setUpdatedDescription] = useState()
  const [updatedDescriptionTitle, setUpdatedDescriptionTitle] = useState()

  useEffect(() => {
    setUpdatedDescription(description)
    setUpdatedDescriptionTitle(descriptionTitle)
  }, [description, descriptionTitle])

  const componentForText = () => {
    if (isEditing) {
      return (
        <TextField
          variant="outlined"
          onChange={event => {
            setUpdatedDescription(event.target.value)}}
          defaultValue={description}
          multiline
          fullWidth
          rows={3}
        />
      )
    } else {
      return (
        <Typography
          className={classes.descriptionText}
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )
    }
  }

  const onSave = () => {
    setSaving(true)
    let saveChanges = []
    if (descriptionTitle !== updatedDescriptionTitle) {
      saveChanges.push(
        updateWebsiteContent({
          description_title: updatedDescriptionTitle,
        })
      )
    }
    if (description !== updatedDescription) {
      saveChanges.push(updateProfile({ profile: updatedDescription }))
    }

    Promise.all(saveChanges)
      .then(() => {
        setSaving(false)
        setIsEditing(false)
      })
      .catch(error => {
        setSaving(false)
        displayErrorDialog('Failed to update description. Please try again.')
      })
  }
  if (isBlank(description) && !inEditMode) {
    return null
  }

  return (
    <Section
      id="about"
      showDivider={false}
      title={
        isBlank(descriptionTitle) ? 'About us' : descriptionTitle
      }
      canEdit={inEditMode}
      isEditing={isEditing}
      isSaving={saving}
      onEditClick={() => setIsEditing(true)}
      onCancelClick={() => {
        setIsEditing(false)
        setUpdatedDescription(description)
        setUpdatedDescriptionTitle(descriptionTitle)
      }}
      onSaveClick={onSave}
      canEditHeader={true}
      onChangeTitle={event => setUpdatedDescriptionTitle(event.target.value)}
    >
      {componentForText()}
    </Section>
  )
}

AboutUs.propTypes = {
  description: PropTypes.string,
  descriptionTitle: PropTypes.string,
  inEditMode: PropTypes.bool.isRequired,
}

AboutUs.defaultProps = {
  description: '',
  descriptionTitle: '',
  inEditMode: false,
}

export default compose(
  connect(
    null,
    { updateProfile, updateWebsiteContent, displayErrorDialog }
  ),
  withStyles(styles)
)(AboutUs)
