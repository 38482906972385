import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, withStyles } from '@material-ui/core'
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth'
import EditIcon from '@material-ui/icons/Edit'
import { isPresent } from 'src/common/ObjectHelper'
import BannerText from './BannerText'
import { isIE } from 'src/common/DetectBrowser'

const styles = theme => ({
  banner: {
    width: '100%',
    height: 450,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw / 2)',
    },
  },
  bannerText: {
    position: 'absolute',
    width: '100%',
    bottom: 50,
    [theme.breakpoints.down('sm')]: {
      bottom: 10,
    },
  },
  button: {
    position: 'absolute',
    right: 16,
    top: 20,
  },
  editIcon: {
    marginRight: theme.spacing.unit,
  },
  inEdit: {
    backgroundColor: theme.palette.grey[900],
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  ieImageFit: {
    height: 'auto',
    width: '100%',
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

function Banner({
  classes,
  inEditMode,
  onPhotoEditButtonClick,
  title,
  description,
  isMobile,
  desktopBannerUrl,
  mobileBannerUrl,
  width,
}) {
  return (
    <div
      className={classNames(inEditMode ? classes.inEdit : '', classes.banner)}
    >
      <img
        className={classNames({
          [classes.ieImageFit]: isIE() && isWidthUp('sm', width),
          [classes.image]: !isIE() || isWidthDown('sm', width),
        })}
        src={isWidthDown('sm', width) ? mobileBannerUrl : desktopBannerUrl}
      />
      {(isPresent(title) || isPresent(description) || inEditMode) && (
        <div className={classes.bannerText}>
          <BannerText
            isMobile={isMobile}
            title={title}
            description={description}
            inEditMode={inEditMode}
          />
        </div>
      )}
      {inEditMode && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => onPhotoEditButtonClick()}
        >
          <EditIcon className={classes.editIcon} />
          Edit
        </Button>
      )}
    </div>
  )
}

Banner.propTypes = {
  desktopBannerUrl: PropTypes.string,
  mobileBannerUrl: PropTypes.string,
  inEditMode: PropTypes.bool,
  onPhotoEditButtonClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}

Banner.defaultProps = {
  onPhotoEditButtonClick: () => {},
  inEditMode: false,
  title: '',
  description: '',
}

export default compose(
  withWidth({ initialWidth: 'lg' }),
  withStyles(styles)
)(Banner)
