import { updateWebsiteContentApi } from './Api'
import { didUpdateContent } from '../../components/Edit/Redux/Actions'

export function updateWebsiteContent(websiteContent) {
  return dispatch => {
    return updateWebsiteContentApi(websiteContent ).then(response => {
      dispatch(didUpdateContent('website_content', response.data))
    })
  }
}
