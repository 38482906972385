import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ServiceAreaInEdit from './Edit/ServiceAreaInEdit'
import ServiceArea from './ServiceArea'
import SectionParent from '../Sections/SectionParent'
import { isBlank } from 'src/common/ObjectHelper'
import { PropTypes } from 'prop-types'
import { updateServiceArea } from './Redux/Actions'
import { updateProfile } from '../CompanyDescription/Redux/Actions'
import { isPolygonEdited } from './Redux/Data'
import { updateWebsiteContent } from 'src/Resources/WebsiteContent/Actions'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'

export const DEFAULT_LATITUDE = 32.8692613
export const DEFAULT_LONGITUDE = -117.22984
export const DEFAULT_RADIUS = 10

@connect(
  state => ({
    polygonEdited: isPolygonEdited(state),
  }),
  { updateServiceArea, updateProfile, updateWebsiteContent, displayErrorDialog }
)
export default class ServiceAreaParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
    }
  }

  handleSaveEdit(newData) {
    const { updateServiceArea, updateProfile, updateWebsiteContent, displayErrorDialog } = this.props

    this.setState({ isSaving: true })
    updateProfile(newData.service_area).then(() => {
      updateServiceArea(newData.service_area).then(() => {
        updateWebsiteContent({
          show_physical_address: newData.show_physical_address,
        }).then(() => this.setState({ isSaving: false }))
      })
    }).catch(error => {
      this.setState({
        isSaving: false,
      })
      displayErrorDialog(
        'Failed to update booking title. Please try again.'
      )
    })
  }

  render() {
    const { serviceArea, address, inEditMode } = this.props
    const { isSaving } = this.state

    if (isBlank(serviceArea)) {
      return null
    }
    const contentData = {serviceArea: serviceArea, address: address}

    return (
      <SectionParent
        sectionComponent={ServiceArea}
        sectionComponentInEdit={ServiceAreaInEdit}
        contentData={contentData}
        sectionTitle="Service area"
        inEditMode={inEditMode}
        onSaveEdit={newData => this.handleSaveEdit(newData)}
        isSaving={isSaving}
      />
    )
  }
}

ServiceAreaParent.propTypes = {
  serviceArea: PropTypes.object,
  address: PropTypes.object,
  inEditMode: PropTypes.bool,
}

ServiceAreaParent.defaultProps = {
  inEditMode: false,
  serviceArea: {},
  address: {}
}
