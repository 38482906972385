import React, { PureComponent } from 'react'
import { CancelSaveButtons } from 'housecall-ui'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CompanyHighlightsEditForm from './CompanyHighlightsEditForm'
import { isPresent } from 'src/common/ObjectHelper'
import { updateHighlights } from '../Redux/Actions'
import {
  getStartYear,
  getLicenseNumber,
  getOtherHighlights,
} from 'src/Resources/CompanyProfile/Highlights/Data'

@connect(
  state => ({
    startYear: getStartYear(state),
    licenseNumber: getLicenseNumber(state),
    otherBadges: getOtherHighlights(state),
  }),
  { updateHighlights }
)
export default class CompanyHighlightsEditDialog extends PureComponent {
  constructor(props) {
    super(props)

    let preselectedCheckboxes = new Set()
    for (const item of props.otherBadges) {
      preselectedCheckboxes.add(item.name)
    }

    this.state = {
      startYear: this.props.startYear,
      licenseNumber: this.props.licenseNumber,
      selectedCheckboxes: preselectedCheckboxes,
    }
  }

  handleCheckboxToggle(highlight) {
    let updatedSelectedCheckboxes = this.state.selectedCheckboxes
    if (this.state.selectedCheckboxes.has(highlight)) {
      updatedSelectedCheckboxes.delete(highlight)
    } else {
      updatedSelectedCheckboxes.add(highlight)
    }
    this.setState({ selectedCheckboxes: updatedSelectedCheckboxes })
  }

  handleFormSubmit = event => {
    event.preventDefault()

    let companyHighlights = []
    if (isPresent(this.state.startYear)) {
      companyHighlights.push({
        name: 'start_year',
        highlight_value: this.state.startYear,
      })
    }
    if (isPresent(this.state.licenseNumber)) {
      companyHighlights.push({
        name: 'license',
        highlight_value: this.state.licenseNumber,
      })
    }

    for (const checkbox of this.state.selectedCheckboxes) {
      companyHighlights.push({ name: checkbox })
    }
    this.props
      .updateHighlights(companyHighlights.concat(this.props.paymentHighlights))
      .then(() => {
        this.props.onClose()
      })
  }

  render() {
    const { open, onClose, companyHighlights } = this.props
    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        disableEnforceFocus
        maxWidth="sm"
      >
        <form onSubmit={event => this.handleFormSubmit(event)}>
          <DialogTitle>Our company highlights</DialogTitle>
          <DialogContent>
            <CompanyHighlightsEditForm
              handleCheckboxToggle={event => this.handleCheckboxToggle(event)}
              handleLicenseChange={event =>
                this.setState({ licenseNumber: event.target.value })
              }
              handleYearsChange={year => this.setState({ startYear: year })}
              companyHighlights={companyHighlights}
              startYear={this.state.startYear}
              licenseNumber={this.state.licenseNumber}
            />
          </DialogContent>
          <DialogActions>
            <CancelSaveButtons
              saveButtonVariant="text"
              onCancelClick={() => onClose()}
            />
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

CompanyHighlightsEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  companyHighlights: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentHighlights: PropTypes.arrayOf(PropTypes.object),
  saving: PropTypes.bool,
}

CompanyHighlightsEditDialog.defaultProps = {
  open: false,
  companyHighlights: [],
  paymentHighlights: [],
  saving: false,
}
