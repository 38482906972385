import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox, Grid, TextField } from '@material-ui/core'
import { COMPANY_HIGHLIGHTS } from '../Redux/Data'
import { isBlank } from 'src/common/ObjectHelper'
import { YearsDropdown } from 'housecall-ui'

export default class CompanyHighlightsEditForm extends PureComponent {
  buildHighlightsIcons() {
    return Object.keys(COMPANY_HIGHLIGHTS).map((highlight, index) => {
      if (isBlank(COMPANY_HIGHLIGHTS[highlight].text)) return

      return (
        <Grid item xs={6} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={this.isContainedHighlights(
                  highlight,
                  this.props.companyHighlights
                )}
                label={highlight}
                color="primary"
                onChange={() => this.props.handleCheckboxToggle(highlight)}
              />
            }
            label={`${COMPANY_HIGHLIGHTS[highlight].text}`}
          />
        </Grid>
      )
    })
  }

  isContainedHighlights(highlightName, companyHighlights) {
    for (const highlightItem of companyHighlights) {
      if (highlightItem.name == highlightName) {
        return true
      }
    }
    return false
  }

  render() {
    const { startYear, licenseNumber, handleYearsChange } = this.props
    return (
      <Grid container spacing={24} justify="center">
        <Grid item xs={12}>
          <YearsDropdown
            yearSelectionRange={50}
            defaultSelection="Don't show it on my website"
            selectedYear={startYear}
            handleChange={year => handleYearsChange(year)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            defaultValue={licenseNumber}
            label="License #"
            onChange={event => this.props.handleLicenseChange(event)}
          />
        </Grid>
        <Grid item xs={11} container>
          {this.buildHighlightsIcons()}
        </Grid>
      </Grid>
    )
  }
}

CompanyHighlightsEditForm.propTypes = {
  handleLicenseChange: PropTypes.func.isRequired,
  handleYearsChange: PropTypes.func.isRequired,
  handleCheckboxToggle: PropTypes.func.isRequired,
  companyHighlights: PropTypes.arrayOf(PropTypes.object).isRequired,
  startYear: PropTypes.string,
  licenseNumber: PropTypes.string,
}

CompanyHighlightsEditForm.defaultProps = {
  startYear: '',
  licenseNumber: '',
  companyHighlights: [],
}
