import RestAPI from 'src/common/RestApi'

export function updateScheduleAvailabilityApi(schedule) {
  return RestAPI.updateWithFormData(
    '/alpha/organization/schedule_availability',
    schedule
  )
}

export function getScheduleAvailabilityApi() {
  return RestAPI.get('/alpha/organization/schedule_availability')
}
