import { updateBannerApi } from './Api'
import { didUpdateContent } from '../../../Edit/Redux/Actions'
import { getErrorMessage } from 'src/common/ErrorDialog/ErrorHandler'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { BANNER_KIND_TO_NAMESPACE } from './Data'

export function updateBanner(bannerFile, kind) {
  return dispatch => {
    return updateBannerApi(bannerFile, kind)
      .then(response => {
        dispatch(didUpdateContent(BANNER_KIND_TO_NAMESPACE[kind], response.data))
      })
      .catch(error => dispatch(displayErrorDialog(getErrorMessage(error))))
  }
}
