import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  receivedCompanyProfileData,
  getCompanyProfile,
} from 'src/Resources/CompanyProfile/Data'
import {
  createTestimonial,
  removeTestimonialCreationFields,
  updateTestimonial,
} from './Redux/Actions'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import { NEW_TESTIMONIAL_UUID_PLACEHOLDER } from './EditTestimonialsList'
import { TestimonialInEdit } from 'housecall-ui'

const UPDATE = 'update'
const CREATE = 'create'

@connect(
  state => ({ companyProfile: getCompanyProfile(state) }),
  {
    createTestimonial,
    updateTestimonial,
    receivedCompanyProfileData,
    removeTestimonialCreationFields,
  }
)
class IsEdittingTestimonial extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      updatedQuote: isPresent(props.testimonial.quote)
        ? props.testimonial.quote
        : '',
      updatedAuthor: isPresent(props.testimonial.author)
        ? props.testimonial.author
        : '',
      updatedCity: isPresent(props.testimonial.city)
        ? props.testimonial.city
        : '',
      updatedState: isPresent(props.testimonial.state)
        ? props.testimonial.state
        : '',
    }
  }

  handleCancel() {
    if (this.props.variant === CREATE) {
      this.props.removeTestimonialCreationFields()
    }
    this.props.onCancelClick()
  }

  handleSave() {
    if (this.props.variant === CREATE) {
      this.onSaveCreate()
    } else {
      this.onSaveUpdate()
    }
  }

  onSaveCreate() {
    const createdTestimonial = {
      quote: this.state.updatedQuote,
      author: this.state.updatedAuthor,
      city: this.state.updatedCity,
      state: this.state.updatedState,
    }

    this.props.createTestimonial(createdTestimonial).then(response => {
      var newProfile = Object.assign({}, this.props.companyProfile)

      const newTestimonials = [...newProfile.testimonials].filter(
        test => test.id !== NEW_TESTIMONIAL_UUID_PLACEHOLDER
      )
      newTestimonials.push(response.data)
      newProfile.testimonials = newTestimonials

      this.props.receivedCompanyProfileData(newProfile)
      this.props.onSaveClick()
    })
  }

  onSaveUpdate() {
    const updatedTestimonial = {
      uuid: this.props.testimonial.id,
      quote: this.state.updatedQuote,
      author: this.state.updatedAuthor,
      city: this.state.updatedCity,
      state: this.state.updatedState,
    }

    this.props.updateTestimonial(updatedTestimonial).then(response => {
      var newProfile = Object.assign({}, this.props.companyProfile)

      const testimonialsCopy = [...newProfile.testimonials]
      let index = testimonialsCopy.findIndex(
        element => element.id === this.props.testimonial.id
      )
      testimonialsCopy[index] = response.data
      newProfile.testimonials = testimonialsCopy

      this.props.receivedCompanyProfileData(newProfile)
      this.props.onSaveClick()
    })
  }

  render() {
    const { updatedQuote, updatedAuthor, updatedCity, updatedState} = this.state
    return (
      <TestimonialInEdit 
        updatedQuote={updatedQuote}
        updatedAuthor={updatedAuthor}
        updatedCity={updatedCity}
        updatedState={updatedState}
        onChangeQuote={event => this.setState({ updatedQuote: event.target.value })}
        onChangeAuthor={event => this.setState({ updatedAuthor: event.target.value })}
        onChangeCity={event => this.setState({ updatedCity: event.target.value })}
        onChangeState={event => this.setState({ updatedState: event.target.value })}
        disabled={isBlank(updatedQuote) || isBlank(updatedAuthor)}
        onCancelClick={() => this.handleCancel()}
        onSaveClick={() => this.handleSave()} />
    )
  }
}

IsEdittingTestimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([UPDATE, CREATE]),
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
}

IsEdittingTestimonial.defaultProps = {
  variant: UPDATE,
}

export default IsEdittingTestimonial
