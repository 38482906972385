export const DRAGGABLE_PHOTO = 'draggablePhotoItem'

export const hoverStyle = {
  position: 'relative',
  '&::before': {
    content: `''`,
    position: 'absolute',
    height: '85%',
    marginLeft: -22.5,
    backgroundColor: 'black',
    width: 3,
    marginTop: '7.5%',
  },
}

export const photoDragSource = {
  beginDrag(props) {
    return { ...props.photo, index: props.index }
  },

  isDragging(props, monitor) {
    return props.photo.id === monitor.getItem().id
  },

  endDrag(props, monitor) {
    if (monitor.didDrop()) props.onDropBegin()
  },
}

export function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

export const photoDropSource = {
  drop(props, monitor) {
    const photo = monitor.getItem()
    const index = photo.index < props.index ? props.index - 1 : props.index
    Promise.all([
      props.updateViewPhotoOrdering(photo, index),
      props.updatePhoto(photo, index),
    ]).then(() => {
      props.onDropComplete()
    })
  },
}

export function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}
