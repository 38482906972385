import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Section from '../Sections/Section'
import { ServiceAreaMap } from 'housecall-ui'
import { isBlank } from 'src/common/ObjectHelper'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_RADIUS,
} from './ServiceAreaParent'
import { getShowPhysicalAddress } from 'src/Resources/WebsiteContent/Data.js'
import PhysicalAddress from './PhysicalAddress'
import { Grid } from '@material-ui/core'

@connect(
  state => ({
    showPhysicalAddress: getShowPhysicalAddress(state),
  }),
  null
)
export default class ServiceArea extends PureComponent {
  render() {
    const {
      sectionTitle,
      contentData,
      canEdit,
      onEditClick,
      showPhysicalAddress,
    } = this.props
    const serviceArea = contentData.serviceArea
    const address = contentData.address

    return (
      <Section
        id="service_area"
        title={sectionTitle}
        canEdit={canEdit}
        onEditClick={() => onEditClick()}
        showAddButton={isBlank(serviceArea)}
        addButtonText="ADD A SERVICE AREA"
        onAddButtonClick={() => {}}
      >
        <Grid container spacing={16}>
          {showPhysicalAddress && (
            <Grid item xs={12}>
              <PhysicalAddress address={address}/>
            </Grid>
          )}
          <Grid item xs={12}>
            <ServiceAreaMap
              latitude={
                isBlank(serviceArea)
                  ? DEFAULT_LATITUDE
                  : Number(serviceArea.latitude)
              }
              longitude={
                isBlank(serviceArea)
                  ? DEFAULT_LONGITUDE
                  : Number(serviceArea.longitude)
              }
              radius={
                isBlank(serviceArea)
                  ? DEFAULT_RADIUS
                  : Number(serviceArea.radius)
              }
              polygon={serviceArea.polygon}
              showMarker={showPhysicalAddress}
            />
          </Grid>
        </Grid>
      </Section>
    )
  }
}

ServiceArea.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  contentData: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
}

ServiceArea.defaultProps = {
  sectionTitle: '',
  contentData: {},
  canEdit: false,
}
