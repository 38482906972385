import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import { deleteFAQ, addFAQCreationFields } from './Redux/Actions'
import IsEdittingFAQ from './IsEdittingFAQ'
import Section from '../../Sections/Section'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { ConfirmationDialog, SingleFAQ } from 'housecall-ui'

const NEW_FAQ_UUID_PLACEHOLDER = 'NEW_FAQ_UUID_PLACEHOLDER'

@connect(
  null,
  { addFAQCreationFields, deleteFAQ, displayErrorDialog }
)
export default class EditModeFAQ extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      uuidBeingEditted: null,
      deletedFaqId: '',
      showConfirmation: false,
    }
  }

  closeEditFields() {
    this.setState({ uuidBeingEditted: null })
  }

  handleDelete(faq) {
    this.setState({ deletedFaqId: faq.id, showConfirmation: true })
  }

  buildFAQList() {
    const { faqs } = this.props
    const { uuidBeingEditted } = this.state
    return faqs.map((faq, index) => {
      if (uuidBeingEditted === faq.id) {
        return (
          <Grid item xs={12} key={index}>
            <IsEdittingFAQ
              faq={faq}
              variant={
                faq.id === NEW_FAQ_UUID_PLACEHOLDER ? 'create' : 'update'
              }
              onCancelClick={() => this.closeEditFields()}
              onSaveClick={() => this.closeEditFields()}
            />
          </Grid>
        )
      } else {
        return (
          <Grid item xs={12} key={index}>
            <SingleFAQ
              faq={faq}
              onDeleteClick={() => this.handleDelete(faq)}
              onEditClick={() => this.setState({ uuidBeingEditted: faq.id })}
              canEdit={isBlank(uuidBeingEditted)}
              canDelete={isBlank(uuidBeingEditted)}
            />
          </Grid>
        )
      }
    })
  }

  render() {
    const { uuidBeingEditted, showConfirmation, deletedFaqId } = this.state
    const { sectionTitle } = this.props
    return (
      <Fragment>
        <Section
          title={sectionTitle}
          showAddButton={isBlank(uuidBeingEditted)}
          addButtonText={'Add frequently asked question'}
          onAddButtonClick={() => {
            this.props.addFAQCreationFields()
            this.setState({
              uuidBeingEditted: NEW_FAQ_UUID_PLACEHOLDER,
            })
          }}
        >
          <Grid container spacing={24}>
            {this.buildFAQList()}
          </Grid>
        </Section>
        <ConfirmationDialog
          open={showConfirmation}
          message_title="Are you sure you want to delete this FAQ?"
          message_body=""
          confirmButtonMessage="Delete"
          confirmButtonColor="secondary"
          onCancelClick={() => this.setState({ showConfirmation: false })}
          onConfirmClick={() => {
            this.props.deleteFAQ(deletedFaqId).catch(error => {
              this.props.displayErrorDialog(
                'Failed to delete FAQ. Please try again.'
              )
            })
            this.closeEditFields()
            this.setState({ showConfirmation: false, deletedFaqId: '' })
          }}
        />
      </Fragment>
    )
  }
}

EditModeFAQ.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  faqs: PropTypes.arrayOf(PropTypes.object),
}

EditModeFAQ.defaultProps = {
  sectionTitle: '',
  faqs: [],
}
