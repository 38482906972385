import RestAPI from 'src/common/RestApi'

export function createTestimonialApi(data) {
  return RestAPI.postWithFormData(`/alpha/organization/testimonials`, data)
}

export function updateTestimonialApi(data) {
  return RestAPI.updateWithFormData(
    `/alpha/organization/testimonials/${data.uuid}`,
    data
  )
}

export function deleteTestimonialApi(testimonialUuid) {
  return RestAPI.deleteWithFormData(
    `/alpha/organization/testimonials/${testimonialUuid}`
  )
}
