import React, { PureComponent } from 'react'
import { validateOrganization, updateOrganization } from './Redux/Actions'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  getCompanyEmail,
  getCompanyPhoneNumber,
  getCompanyName,
} from 'src/Resources/Organization/Data'
import EditOrganizationForm from './EditOrganizationForm'
import EditFormDialog from './EditFormDialog'

export const EDIT_ORGANIZATION_FORM = 'EDIT_ORGANIZATION_FORM'

@connect(
  state => ({
    initialValues: {
      phone_number: getCompanyPhoneNumber(state),
      support_email: getCompanyEmail(state),
      name: getCompanyName(state),
    },
  }),
  {
    updateOrganization,
  }
)
@reduxForm({
  form: EDIT_ORGANIZATION_FORM,
  validate: validateOrganization,
})
export default class EditOrganizationDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
    }
  }
  submitForm(values) {
    const { updateOrganization, onClose } = this.props

    this.setState({ updating: true })
    updateOrganization(values)
      .then(() => {
        onClose()
      })
      .finally(() => {
        this.setState({ updating: false })
      })
  }

  render() {
    const { open, onClose, handleSubmit, reset } = this.props
    const { updating } = this.state

    return (
      <EditFormDialog
        updating={updating}
        open={open}
        onClose={() => onClose()}
        onSubmit={handleSubmit(values => this.submitForm(values))}
        title="Company Settings"
      >
        <EditOrganizationForm disabled={updating} />
      </EditFormDialog>
    )
  }
}
