import { createSelector } from 'reselect'
import { getScheduleAvailabilityApi } from './Api'
import { isBlank, isPresent } from 'src/common/ObjectHelper'

export const SCHEDULE_AVAILABILITY_NAMESPACE = 'scheduleAvailability'

export const getScheduleAvailability = createSelector(
  state => state[SCHEDULE_AVAILABILITY_NAMESPACE],
  scheduleAvailability => {
    return scheduleAvailability || {}
  }
)

export const getDailyAvailability = createSelector(
  state => getScheduleAvailability(state),
  scheduleAvailability => {
    if (isBlank(scheduleAvailability.daily_availabilities)) {
      return {}
    }

    let days = {}
    scheduleAvailability.daily_availabilities.data.forEach(day => {
      let windows = []
      day.schedule_windows.data.forEach(scheduleWindow => {
        windows.push({
          startTime: scheduleWindow.start_time,
          endTime: scheduleWindow.end_time,
        })
      })
      days[day.day_name.toLowerCase()] = windows
    })

    return days
  }
)

export const getAvailabilityBuffer = createSelector(
  state => getScheduleAvailability(state),
  scheduleAvailability => {
    if (isPresent(scheduleAvailability.availability_buffer_in_days)) {
      return scheduleAvailability.availability_buffer_in_days
    }

    return 3
  }
)

export const fetchScheduleAvailability = {
  fetchingApiFunc: getScheduleAvailabilityApi,
  selector: getScheduleAvailability,
  dataNamespace: SCHEDULE_AVAILABILITY_NAMESPACE,
}
