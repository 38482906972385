import { createSelector } from 'reselect'
import { getReviewsApi, getRatingsApi } from './Api'
import { isPresent } from 'src/common/ObjectHelper'

export const REVIEWS_NAMESPACE = 'reviews'
export const RATINGS_NAMESPACE = 'ratings'
export const DEFAULT_PAGE = 0
export const DEFAULT_COUNT_PER_PAGE = 4

export const getReviews = createSelector(
  state => state[REVIEWS_NAMESPACE],
  reviews => {
    return reviews || {}
  }
)

export const getReviewsCount = createSelector(
  state => getReviews(state),
  reviews => reviews.total_count || 0
)

export const getReviewsList = createSelector(
  state => getReviews(state),
  reviews => {
    let reviewsList = []

    if (isPresent(reviews.data)) {
      reviews.data.forEach(review => {
        reviewsList.push({
          comments: review.comments,
          date: review.created_at,
          firstName: review.first_name,
          lastName: review.last_name,
          rating: review.rating,
        })
      })
    }

    return reviewsList
  }
)

export const getRatings = createSelector(
  state => state[RATINGS_NAMESPACE],
  ratings => {
    return ratings || {}
  }
)

export const getOverallRating = createSelector(
  state => getRatings(state),
  ratings => {
    return ratings.overall_rating || 0
  }
)

export const getRatingsCount = createSelector(
  state => getRatings(state),
  ratings => {
    return ratings.total_ratings || 0
  }
)

export const fetchReviews = {
  fetchingApiFunc: getReviewsApi,
  selector: getReviews,
  dataNamespace: REVIEWS_NAMESPACE,
}

export const fetchRatings = {
  fetchingApiFunc: getRatingsApi,
  selector: getRatings,
  dataNamespace: RATINGS_NAMESPACE,
}
