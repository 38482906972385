import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const HousecallDoor = (props) => (
  <SvgIcon viewBox="0 0 21 38" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#2196F3" fillRule="nonzero">
        <polygon points="0 6.9 0 31.1 11.8 37.4 11.8 34 11.8 30.3 11.8 7.9 11.8 4.2 11.8 0"></polygon>
        <polygon points="20.1 33.9 15.2 33.9 15.2 31.1 17.3 31.1 17.4 6.8 15.2 6.8 15.2 4 20.2 4"></polygon>
      </g>
    </g>
  </SvgIcon>
)

export default React.memo(HousecallDoor)
