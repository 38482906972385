import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'
import Section from './Section'
import { CancelSaveButtons } from 'housecall-ui'

const styles = {
  footer: {
    paddingTop: 16,
  },
}

function IsEditingSection({
  classes,
  id,
  title,
  children,
  showDivider,
  onCancelClick,
  onSaveClick,
  disabled,
  isSaving,
}) {
  return (
    <Section title={title} id={id} showDivider={showDivider}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <CancelSaveButtons
            onCancelClick={() => onCancelClick()}
            onSaveClick={() => onSaveClick()}
            disabled={disabled}
            isSaving={isSaving}
          />
        </Grid>
      </Grid>
    </Section>
  )
}

IsEditingSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showDivider: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSaving: PropTypes.bool,
}

IsEditingSection.defaultProps = {
  showDivider: true,
  disabled: false,
  isSaving: false,
}

export default withStyles(styles)(IsEditingSection)
