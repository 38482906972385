import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { isPresent } from 'src/common/ObjectHelper'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import { receivedCompanyProfileData } from 'src/Resources/CompanyProfile/Data'
import CompanyProfileDesktop from './CompanyProfileDesktop'
import CompanyProfileMobile from './CompanyProfileMobile'
import { convertBusinessHour, convertPaymentMethod } from './SchemaHelper'
import ExecutionEnvironment from 'src/common/ExecutionEnvironment'

const styles = {
  topNavbar1: {
    top: 50,
  },
  bottomNavbar: {
    top: 'auto',
    bottom: 0,
  },
}

@connect(
  null,
  { receivedCompanyProfileData }
)
@withStyles(styles)
export default class CompanyProfile extends PureComponent {
  componentDidMount() {
    const { pageContext, receivedCompanyProfileData } = this.props

    if (isPresent(pageContext.companyProfile)) {
      receivedCompanyProfileData(pageContext.companyProfile)
    }
  }

  render() {
    const { companyProfile } = this.props.pageContext
    const {
      address,
      email,
      phone_number,
      url,
      company_name,
      logo_url,
    } = companyProfile.organization
    const { overall_rating, total_ratings } = companyProfile.aggregate_rating
    const schema = {
      '@context': 'http://schema.org',
      // comments by Gary, we can choose more than one type
      '@type': ['LocalBusiness'], //asking Eckstrum if this should be industry specific,
      openingHours: convertBusinessHour(
        companyProfile.business_hours.business_hours.data
      ), //business hours
      paymentAccepted: convertPaymentMethod(companyProfile.highlights.data), //payments accepted
      address: {
        '@type': 'PostalAddress',
        addressLocality: `${address.city}`, //city
        addressRegion: `${address.state}`, //state
        postalCode: `${address.zip}`, //zip
        streetAddress: `${address.street + address.street_line2}`, //street
      },
      email: `${email}`, //email
      telephone: `${phone_number}`, //phone
      url: `${url}`, //website address
      name: `${company_name}`, //company name
      image: `${logo_url}`, //photos? header image? apparently this is required. Needs to be a URL of an image or a 'fully described ImageObject'
    }
    const aggregateRating = {
      '@type': 'AggregateRating',
      ratingCount: `${total_ratings}`,
      ratingValue: `${overall_rating}`,
    }
    if (companyProfile.website_content.show_reviews && overall_rating >= 4)
      schema.aggregateRating = aggregateRating

    if (ExecutionEnvironment.onClient) {
      return (
        <Fragment>
          <Hidden mdDown initialWidth="lg">
            <div data-rerender="force_tree_rerender" />
            <CompanyProfileDesktop />
          </Hidden>
          <Hidden lgUp initialWidth="lg">
            <CompanyProfileMobile />
          </Hidden>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          />
        </Fragment>
      )
    } else {
      return <CompanyProfileDesktop />
    }
  }
}
