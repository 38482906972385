import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, Link } from '@material-ui/core'
import CharityBoxIcon from '../../../images/MaterialUIIcons/CharityBoxIcon'
import { isPresent } from 'src/common/ObjectHelper'

const styles = {
  container: {
    padding: 16,
    backgroundColor: '#fafafa',
  },
}

function modifyUrl(url) {
  if (!url.startsWith('http')) {
    url = '//' + url
  }
  return url
}

function CharityBox({ classes, description, title, url }) {
  return (
    <Grid
      container
      spacing={16}
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <CharityBoxIcon fontSize="large" />
      </Grid>
      <Grid item xs>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      <Grid item xs>
        <Typography align="center" variant="body1">
          {isPresent(url) ? (
            <Link href={modifyUrl(url)} target="_blank">
              {title}
            </Link>
          ) : (
            title
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CharityBox)
