import { updateScheduleAvailabilityApi } from './Api'
import { receivedServerData } from 'data-fetcher'
import { SCHEDULE_AVAILABILITY_NAMESPACE } from './Data'

export function updateScheduleAvailability(schedule) {
  return (dispatch, getState) => {
    let days = []
    days.push({
      day_name: 'monday',
      schedule_windows: buildWindows(schedule.monday),
    })
    days.push({
      day_name: 'tuesday',
      schedule_windows: buildWindows(schedule.tuesday),
    })
    days.push({
      day_name: 'wednesday',
      schedule_windows: buildWindows(schedule.wednesday),
    })
    days.push({
      day_name: 'thursday',
      schedule_windows: buildWindows(schedule.thursday),
    })
    days.push({
      day_name: 'friday',
      schedule_windows: buildWindows(schedule.friday),
    })
    days.push({
      day_name: 'saturday',
      schedule_windows: buildWindows(schedule.saturday),
    })
    days.push({
      day_name: 'sunday',
      schedule_windows: buildWindows(schedule.sunday),
    })

    var protocol = {
      daily_schedule_windows: days,
      availability_buffer_in_days: schedule.availabilityBuffer,
    }
    return updateScheduleAvailabilityApi(protocol).then(response => {
      dispatch(
        receivedServerData(response.data, SCHEDULE_AVAILABILITY_NAMESPACE)
      )
    })
  }
}

function buildWindows(windows) {
  let protocol_windows = []

  windows.forEach(aWindow => {
    protocol_windows.push({
      start_time: aWindow.startTime,
      end_time: aWindow.endTime,
    })
  })
  return protocol_windows
}
