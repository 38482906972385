import { createSelector } from 'reselect'
import { isPresent } from 'src/common/ObjectHelper'
import { getCompanyProfile } from '../../CompanyProfile/Data'

export const getHighlights = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    if (
      isPresent(companyProfile.highlights) &&
      isPresent(companyProfile.highlights.data)
    ) {
      return companyProfile.highlights.data
    } else {
      return []
    }
  }
)

export const getCompanyHighlights = createSelector(
  state => getHighlights(state),
  highlights => {
    if (isPresent(highlights)) {
      const badgesFilter = [
        'start_year',
        'insured',
        'bonded',
        'family_owned',
        'license',
        'veteran_owned',
        'emergency_services',
        'repairs_guaranteed',
      ]
      let highlight_badges = []
      highlights
        .filter(highlight => badgesFilter.includes(highlight.name))
        .forEach(highlight => {
          highlight_badges.push(highlight)
        })
      return highlight_badges
    } else {
      return []
    }
  }
)

export const getStartYear = createSelector(
  state => getHighlights(state),
  highlights => {
    for (const highlight of highlights) {
      if (highlight.name === 'start_year') {
        return highlight.highlight_value
      }
    }
    return ''
  }
)

export const getLicenseNumber = createSelector(
  state => getHighlights(state),
  highlights => {
    for (const highlight of highlights) {
      if (highlight.name === 'license') {
        return highlight.highlight_value
      }
    }
    return ''
  }
)

export const getOtherHighlights = createSelector(
  state => getHighlights(state),
  highlights => {
    if (isPresent(highlights)) {
      const filter = [
        'insured',
        'family_owned',
        'bonded',
        'veteran_owned',
        'emergency_services',
        'repairs_guaranteed',
      ]
      let otherHighlights = []
      highlights
        .filter(highlight => filter.includes(highlight.name))
        .forEach(highlight => {
          otherHighlights.push(highlight)
        })
      return otherHighlights
    } else {
      return []
    }
  }
)

export const getPaymentHighlights = createSelector(
  state => getHighlights(state),
  highlights => {
    if (isPresent(highlights)) {
      const paymentFilter = [
        'accepts_cash',
        'accepts_credit_card',
        'accepts_checks',
      ]
      let payment_options = []
      highlights
        .filter(highlight => paymentFilter.includes(highlight.name))
        .forEach(highlight => {
          payment_options.push(highlight)
        })
      return payment_options
    } else {
      return []
    }
  }
)
