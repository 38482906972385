import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  tileImage: {
    width: 110,
    height: 110,
    display: 'block',
    borderRadius: '5%',
    objectFit: 'cover',
  },
}

function Photo({ classes, photo }) {
  return (
    <img
      src={photo.thumb_url}
      alt={photo.altText}
      className={classes.tileImage}
    />
  )
}

Photo.propTypes = {
  photo: PropTypes.shape({
    altText: PropTypes.string,
    original_url: PropTypes.string,
    thumb_url: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(Photo)
