import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Banner from './Banner'
import EditDesktopBannerDialogContainer from './Edit/EditDesktopBannerDialogContainer'
import EditMobileBannerDialogContainer from './Edit/EditMobileBannerDialogContainer'
import EditDesktopAndMobileBannerDialog from './Edit/EditDesktopAndMobileBannerDialog'
import Dialog from 'housecall-ui/dist/library/PageElements/Dialog'
import DialogActions from 'housecall-ui/dist/library/PageElements/DialogActions'
import Typography from 'housecall-ui/dist/library/DataDisplay/Typography'
import DialogContent from 'housecall-ui/dist/library/PageElements/DialogContent'
import Button from 'housecall-ui/dist/library/Buttons/Button'
import CropImageDialog from 'housecall-ui/dist/core/Banner/CropImageDialog'
import { updateBanner } from './Redux/Actions'
import { BANNER_KIND_MOBILE } from './Redux/Data'

const DESKTOP_ASPECT_RATIO = 5
const MOBILE_ASPECT_RATIO = 2

const styles = {
  dialogContentText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
  },
}

function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_DESKTOP_AND_MOBILE_DIALOG':
      return {
        ...state,
        ...initialDialogState,
        showDesktopAndMobileDialog: true,
      }
    case 'SHOW_EDIT_DESKTOP_BANNER_DIALOG':
      return {
        ...state,
        ...initialDialogState,
        showEditDesktopBannerDialog: true,
      }
    case 'SHOW_EDIT_MOBILE_BANNER_DIALOG':
      return {
        ...state,
        ...initialDialogState,
        showEditMobileBannerDialog: true,
      }
    case 'SHOW_DESKTOP_IMAGE_ON_MOBILE_DIALOG':
      return {
        ...state,
        ...initialDialogState,
        showUseDesktopImageOnMobileDialog: true,
      }
    case 'SHOW_MOBILE_CROPPER':
      return { ...state, ...initialDialogState, showMobileCropper: true }
    case 'HIDE_ALL_DIALOGS':
      return { ...state, ...initialDialogState }
    case 'ADD_ORIGINAL_IMAGE_URL_AND_SHOW_CONFIRM_DIALOG':
      return {
        ...state,
        ...initialDialogState,
        showUseDesktopImageOnMobileDialog: true,
        imageUrl: URL.createObjectURL(action.imageUrl),
      }
    case 'REMOVE_ORIGINAL_IMAGE_URL_AND_SHOW_DESKTOP_AND_MOBILE_DIALOG':
      URL.revokeObjectURL(state.imageUrl)
      return {
        ...state,
        ...initialDialogState,
        showDesktopAndMobileDialog: true,
        imageUrl: null,
      }
    case 'SAVING_MOBILE_IMAGE':
      return { ...state, savingMobileImage: true }
    case 'FINISHED_SAVING_MOBILE_IMAGE':
      return {
        ...state,
        ...initialDialogState,
        savingMobileImage: false,
        showDesktopAndMobileDialog: true,
      }
    default:
      return { ...state }
  }
}

const createAction = type => ({ type })
const createAddImageAction = (type, imageUrl) => ({ type, imageUrl })

const initialDialogState = {
  showEditMobileBannerDialog: false,
  showEditDesktopBannerDialog: false,
  showDesktopAndMobileDialog: false,
  showUseDesktopImageOnMobileDialog: false,
  showMobileCropper: false,
}

const initialState = {
  ...initialDialogState,
  imageUrl: null,
  savingMobileImage: false,
}

function BannerParent({
  classes,
  inEditMode,
  desktopBannerUrls,
  mobileBannerUrls,
  isMobile,
  title,
  description,
}) {
  const [
    {
      showDesktopAndMobileDialog,
      showEditDesktopBannerDialog,
      showEditMobileBannerDialog,
      showUseDesktopImageOnMobileDialog,
      showMobileCropper,
      imageUrl,
      savingMobileImage,
    },
    dispatch,
  ] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()

  const promptToCropOriginalPhotoOrShowDesktopAndMobile = originalImage => {
    if (!!originalImage) {
      dispatch(
        createAddImageAction(
          'ADD_ORIGINAL_IMAGE_URL_AND_SHOW_CONFIRM_DIALOG',
          originalImage
        )
      )
    } else {
      dispatch(createAction('SHOW_DESKTOP_AND_MOBILE_DIALOG'))
    }
  }

  const saveMobileCroppedImage = blob => {
    dispatch(createAction('SAVING_MOBILE_IMAGE'))
    reduxDispatch(updateBanner(blob, BANNER_KIND_MOBILE)).then(() =>
      dispatch(createAction('FINISHED_SAVING_MOBILE_IMAGE'))
    )
  }

  return (
    <>
      <Banner
        desktopBannerUrl={desktopBannerUrls.original_url}
        mobileBannerUrl={mobileBannerUrls.original_url}
        inEditMode={inEditMode}
        onPhotoEditButtonClick={() =>
          dispatch(createAction('SHOW_DESKTOP_AND_MOBILE_DIALOG'))
        }
        isMobile={isMobile}
        title={title}
        description={description}
      />
      {inEditMode && (
        <>
          <EditDesktopAndMobileBannerDialog
            desktopBannerUrls={desktopBannerUrls}
            mobileBannerUrls={mobileBannerUrls}
            open={showDesktopAndMobileDialog}
            onDesktopEditClick={() =>
              dispatch(createAction('SHOW_EDIT_DESKTOP_BANNER_DIALOG'))
            }
            onMobileEditClick={() =>
              dispatch(createAction('SHOW_EDIT_MOBILE_BANNER_DIALOG'))
            }
            onClose={() => dispatch(createAction('HIDE_ALL_DIALOGS'))}
          />
          <EditDesktopBannerDialogContainer
            bannerUrls={desktopBannerUrls}
            open={showEditDesktopBannerDialog}
            aspectRatio={DESKTOP_ASPECT_RATIO}
            onClose={promptToCropOriginalPhotoOrShowDesktopAndMobile}
          />
          <EditMobileBannerDialogContainer
            bannerUrls={mobileBannerUrls}
            open={showEditMobileBannerDialog}
            aspectRatio={MOBILE_ASPECT_RATIO}
            onClose={() =>
              dispatch(createAction('SHOW_DESKTOP_AND_MOBILE_DIALOG'))
            }
          />
          <Dialog open={showUseDesktopImageOnMobileDialog} disableBackdropClick>
            <DialogContent>
              <Typography className={classes.dialogContentText}>
                Would you like to use the same picture when someone visits your
                site on their phone?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() =>
                  dispatch(createAction('SHOW_DESKTOP_AND_MOBILE_DIALOG'))
                }
              >
                No
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => dispatch(createAction('SHOW_MOBILE_CROPPER'))}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <CropImageDialog
            open={showMobileCropper}
            imageUrl={imageUrl}
            aspectRatio={MOBILE_ASPECT_RATIO}
            saveButtonDisabled={savingMobileImage}
            onClose={() => {
              dispatch(
                createAction(
                  'REMOVE_ORIGINAL_IMAGE_URL_AND_SHOW_DESKTOP_AND_MOBILE_DIALOG'
                )
              )
            }}
            onSaveCroppedImage={saveMobileCroppedImage}
          />
        </>
      )}
    </>
  )
}

BannerParent.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
}

BannerParent.defaultProps = {
  inEditMode: false,
  isMobile: false,
}

export default withStyles(styles)(BannerParent)
