import React, { PureComponent } from 'react'
import { connect as connectRedux } from 'react-redux'
import { PageContext } from './PageContext'
import ExecutionEnvironment from './ExecutionEnvironment'

export function connect(mapStateToProps, ...args) {
  return WrappedComponent => {
    class WrappedWithConnectPageContext extends PureComponent {
      render() {
        const propsFromPageContext = mapStateToProps(this.context)
        const mergedProps = Object.assign({}, propsFromPageContext, this.props)

        return (
          <WrappedComponent {...mergedProps} />
        )
      }
    }

    WrappedWithConnectPageContext.contextType = PageContext

    if (ExecutionEnvironment.onServer) {
      return WrappedWithConnectPageContext
    } else {
      return connectRedux(mapStateToProps, ...args)(WrappedWithConnectPageContext)
    }
  }
}
