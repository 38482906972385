import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormTextField from 'src/common/Form/FormTextField'

function EditOrganizationForm({ disabled }) {
  return (
    <Fragment>
      <FormTextField
        name="name"
        label="Company name"
        margin="normal"
        variant="outlined"
        disabled={disabled}
        fullWidth
        autoFocus
        focusOnNextFieldUponEnter
      />
      <FormTextField
        name="phone_number"
        label="Company phone"
        margin="normal"
        variant="outlined"
        normalize="phone"
        disabled={disabled}
        fullWidth
        focusOnNextFieldUponEnter
      />
      <FormTextField
        name="support_email"
        label="Company e-mail"
        margin="normal"
        variant="outlined"
        disabled={disabled}
        fullWidth
      />
    </Fragment>
  )
}

EditOrganizationForm.propTypes = {
  disabled: PropTypes.bool,
}

EditOrganizationForm.defaultProps = {
  disabled: false,
}

export default EditOrganizationForm
