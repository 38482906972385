import { didUpdateContent } from '../../Edit/Redux/Actions'
import { updateBusinessHoursApi } from './Api'
import { displayErrorDialog } from 'src/common/ErrorDialog/Actions'
import { isPresent } from 'src/common/ObjectHelper'
import { DAYS_IN_A_WEEK } from 'src/Resources/CompanyProfile/Data'

export function updateBusinessHours(hours) {
  var business_hours = []
  DAYS_IN_A_WEEK.forEach(day => {
    if (isPresent(hours[day])) {
      business_hours.push({
        day: day,
        open_time: hours[day].openTime,
        close_time: hours[day].closeTime,
      })
    }
  })
  return dispatch => {
    return updateBusinessHoursApi({
      business_hours,
    })
      .then(response => {
        dispatch(didUpdateContent('business_hours', response.data))
      })
      .catch(error => {
        dispatch(
          displayErrorDialog(
            'Failed to update business hours. Please try again.'
          )
        )
      })
  }
}
