import RestAPI from 'src/common/RestApi'

export function updateBannerApi(bannerFile, kind) {
  let formData = new FormData()
  formData.append('file', bannerFile)

  return RestAPI.updateWithFormData(`/alpha/organization/website/banners/${kind}`, formData)
}

export function getDefaultIndustryPhotosApi() {
  return RestAPI.getWithFormData('/alpha/industry_banner_photos')
}
