import {
  validatePresence,
  radiusFormValidator,
} from 'src/common/Form/FormValidations'
import { updatePolygonEditedState } from './Data'
import { updateServiceAreaApi } from './Api'
import { receivedCompanyProfileData } from 'src/Resources/CompanyProfile/Data'
import { didUpdateContent } from '../../../Edit/Redux/Actions'

export function validate(values) {
  const errors = {}
  const { streetAddress, city, state, postalCode, radius } = values

  errors.streetAddress = validatePresence(streetAddress)
  errors.city = validatePresence(city)
  errors.state = validatePresence(state)
  errors.postalCode = validatePresence(postalCode)
  errors.radius = validatePresence(radius) || radiusFormValidator(radius)

  return errors
}

export function updatePolygonEdited(isEdited) {
  return dispatch => {
    dispatch(updatePolygonEditedState(isEdited))
  }
}

export function updateServiceArea(serviceArea) {
  return dispatch => {
    dispatch(receivedCompanyProfileData({ service_area: serviceArea }))
    return updateServiceAreaApi({
      polygon: serviceArea.polygon,
      radius: serviceArea.radius,
    }).then(response => {
      dispatch(didUpdateContent('service_area', response.data))
    })
  }
}
