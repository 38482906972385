import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { PartialNumberStarRating } from 'housecall-ui'

function ReviewsStarsAndCount({ ratingsCount, overallRating }) {
  return (
    <Grid container spacing={8} alignItems="center">
      <Grid item>
        <PartialNumberStarRating rating={overallRating} size={24} />
      </Grid>
      <Grid item>
        <Typography variant="caption">{`(${ratingsCount})`}</Typography>
      </Grid>
    </Grid>
  )
}

ReviewsStarsAndCount.propTypes = {
  ratingsCount: PropTypes.number.isRequired,
  overallRating: PropTypes.number.isRequired,
}

export default React.memo(ReviewsStarsAndCount)
