import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import BookingWindow from './BookingWindow'
import BounceSpinner from 'src/common/BounceSpinner'
import CanEditSectionHeader from '../../Sections/Headers/CanEditSectionHeader'
import EditBookingWindows from './EditBookingWindows/EditBookingWindows'
import PropTypes from 'prop-types'
import {
  getDailyAvailability,
  getAvailabilityBuffer,
} from './EditBookingWindows/Redux/Data'
import { updateScheduleAvailability } from './EditBookingWindows/Redux/Actions'

@connect(
  state => ({
    availabilityBuffer: getAvailabilityBuffer(state),
    dailyAvailability: getDailyAvailability(state),
  }),
  { updateScheduleAvailability }
)
class BookingWindows extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
    }
  }

  windowsAreEqual(bookingWindow, selectedWindow) {
    return (
      bookingWindow.start_time == selectedWindow.start_time &&
      bookingWindow.end_time == selectedWindow.end_time
    )
  }

  handleClose() {
    this.setState({ openDialog: false })
  }

  handleSave(schedule) {
    const {
      updateScheduleAvailability,
      onEditWindows,
      dateSelected,
    } = this.props

    updateScheduleAvailability(schedule).then(() => {
      onEditWindows(dateSelected).then(() => this.handleClose())
    })
  }

  render() {
    const {
      bookingWindows,
      selectedBookingWindow,
      windowSelected,
      inEditMode,
      availabilityBuffer,
      dailyAvailability,
    } = this.props
    const { openDialog } = this.state
    let headerComponent
    let headerAlignment = bookingWindows.length == 0 ? 'center' : 'left'
    let headerTitle =
      bookingWindows.length == 0 ? 'No time available' : 'Select arrival time:'

    if (bookingWindows === null) {
      return <BounceSpinner />
    }

    if (inEditMode) {
      headerComponent = (
        <CanEditSectionHeader
          title={headerTitle}
          onEditClick={() => this.setState({ openDialog: true })}
        />
      )
    } else {
      headerComponent = (
        <Typography variant="h6" align={headerAlignment}>
          {headerTitle}
        </Typography>
      )
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          {headerComponent}
        </Grid>
        {bookingWindows.length > 0 &&
          bookingWindows.map((bookingWindow, index) => (
            <Grid item xs={6} key={index}>
              <BookingWindow
                selected={this.windowsAreEqual(
                  bookingWindow,
                  selectedBookingWindow
                )}
                bookingWindow={bookingWindow}
                onClick={() => {
                  if (!inEditMode) {
                    windowSelected(bookingWindow)
                  }
                }}
              />
            </Grid>
          ))}
        <EditBookingWindows
          open={openDialog}
          availabilityBuffer={availabilityBuffer}
          defaultDailyAvailabilities={dailyAvailability}
          onClose={() => this.handleClose()}
          onSave={schedule => this.handleSave(schedule)}
        />
      </Grid>
    )
  }
}

BookingWindows.propTypes = {
  bookingWindows: PropTypes.array,
  selectedBookingWindow: PropTypes.object,
  dateSelected: PropTypes.instanceOf(Date),
  onEditWindows: PropTypes.func,
}

BookingWindows.defaultProps = {
  dateSelected: new Date(),
  onEditWindows: () => {},
}

export default BookingWindows
