import React from 'react'
import { Button, TextField, Grid } from '@material-ui/core'
import GoogleMapsAutoComplete from 'src/common/GoogleMaps/GooglePlacesAutoComplete'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import { PropTypes } from 'prop-types'
import FullScreenDialog from 'src/common/FullScreenDialog'
import { stringifyLocation } from 'src/common/StringifyLocation'
import CompanyLogoOrName from '../CompanyLogo/CompanyLogoOrName'

function ServiceLocationFullScreenDialog({
  open,
  logoUrl,
  companyName,
  serviceLocation,
  onLocationSelected,
  onClose,
  onComplete,
}) {
  return (
    <FullScreenDialog
      dialogOpen={open}
      onClose={() => onClose()}
      dialogContent={
        <Grid container spacing={24}>
          <Grid item xs={12} container justify="center">
            <Grid item>
              <CompanyLogoOrName logoUrl={logoUrl} companyName={companyName} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              label="Enter your service address"
              autoFocus={true}
              onAutoComplete={serviceLocation => {
                onLocationSelected(serviceLocation)
              }}
              id="booking"
              inputComponent={TextField}
              inputProps={{ variant: 'outlined' }}
              value={stringifyLocation(serviceLocation)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => onComplete()}
              color="primary"
              variant="contained"
              disabled={isBlank(serviceLocation)}
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      }
    />
  )
}

ServiceLocationFullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  companyName: PropTypes.string,
  serviceLocation: PropTypes.object,
  onLocationSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default ServiceLocationFullScreenDialog
