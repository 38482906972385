import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SectionLayout from './SectionLayout'
import { Switch } from '@material-ui/core'
import SectionHeader from './Headers/SectionHeader'

export default function SectionWithSwitchToggle({
  children,
  showDivider,
  canEdit,
  disabled,
  title,
  id,
  isToggledOn,
  onToggleSwitch,
}) {
  return (
    <SectionLayout
      showDivider={showDivider}
      headerComponent={
        <SectionHeader title={title} id={id}>
          {canEdit && (
            <Switch
              checked={isToggledOn}
              onChange={() => onToggleSwitch()}
              disabled={disabled}
              color="primary"
            />
          )}
        </SectionHeader>
      }
      bodyContent={<Fragment>{isToggledOn && children}</Fragment>}
    />
  )
}

SectionWithSwitchToggle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  showDivider: PropTypes.bool,
  canEdit: PropTypes.bool,
  disabled: PropTypes.bool,
  isToggledOn: PropTypes.bool,
}

SectionWithSwitchToggle.defaultProps = {
  id: '',
  title: '',
  showDivider: true,
  canEdit: false,
  disabled: false,
  isToggledOn: true,
}
