import { updateFeaturedVideoApi } from './Api'
import { didUpdateContent } from 'src/components/Edit/Redux/Actions'

export function updateFeaturedVideo(youtubeId) {
  return dispatch => {
    return updateFeaturedVideoApi(youtubeId).then(response => {
      dispatch(didUpdateContent('video', response.data))
    })
  }
}
