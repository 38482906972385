import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const styles = {
  tileButton: {
    cursor: 'pointer',
    align: 'center',
  },
  tileSubmitting: {
    opacity: 0.5,
  },
}

function PhotoItem({ classes, children, isSubmitting }) {
  return (
    <div
      className={classNames(classes.tileButton, {
        [classes.tileSubmitting]: isSubmitting,
      })}
    >
      {children}
    </div>
  )
}

PhotoItem.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
}

export default withStyles(styles)(PhotoItem)
