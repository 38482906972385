import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { compose } from 'redux'
import { DropTarget } from 'react-dnd'
import {
  photoDropSource,
  dropCollect,
  DRAGGABLE_PHOTO,
  hoverStyle,
} from './PhotoDragAndDrop'
import { updateViewPhotoOrdering, updatePhoto } from './Redux/Actions'
import UploadPhotoButton from 'housecall-ui/dist/core/PhotoGridList/UploadPhotoButton'

const styles = {
  inputButton: {
    display: 'none',
  },
  addButton: {
    height: 110,
    width: 110,
    textAlign: 'center',
  },
  inputButtonHovered: hoverStyle,
}

function UploadPhotoButtonWithDrop({
  classes,
  index,
  handlePhotoUpload,
  connectDropTarget,
  isOver,
}) {
  return connectDropTarget(
    <div
      className={classNames({ [classes.inputButtonHovered]: isOver })}
      index={index}
    >
      <UploadPhotoButton
        handlePhotoUpload={event => {
          handlePhotoUpload(event)
        }}
      ></UploadPhotoButton>
    </div>
  )
}

UploadPhotoButtonWithDrop.propTypes = {
  index: PropTypes.number.isRequired,
  handlePhotoUpload: PropTypes.func.isRequired,
  onDropBegin: PropTypes.func.isRequired,
  onDropComplete: PropTypes.func.isRequired,
}

const HOCs = compose(
  connect(
    null,
    { updateViewPhotoOrdering, updatePhoto }
  ),
  DropTarget(DRAGGABLE_PHOTO, photoDropSource, dropCollect),
  withStyles(styles)
)

export default HOCs(UploadPhotoButtonWithDrop)
