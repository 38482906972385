import React from 'react'
import PropTypes from 'prop-types'
import CompanyHighlight from './CompanyHighlight'
import { Grid } from '@material-ui/core'

function CompanyHighlights({ companyHighlights }) {
  return (
    <Grid container spacing={16}>
      {companyHighlights.map((highlight, index) => {
        return (
          <Grid item xs={12} sm={6} key={index}>
            <CompanyHighlight highlight={highlight} />
          </Grid>
        )
      })}
    </Grid>
  )
}

CompanyHighlights.propTypes = {
  companyHighlights: PropTypes.arrayOf(PropTypes.object),
}

CompanyHighlights.defaultProps = {
  companyHighlights: []
}

export default React.memo(CompanyHighlights)
