import React, { PureComponent } from 'react'
import { Popover, Select, MenuItem, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TimeSelector from 'src/common/pickers/TimeSelector'
import { isBlank } from 'src/common/ObjectHelper'
import PropTypes from 'prop-types'

const styles = theme => ({
  buttonContainer: {
    padding: theme.spacing.unit,
  },
})
@withStyles(styles)
export default class SchedulePopover extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startTime: props.defaultStartTime,
      endTime: props.defaultEndTime,
    }
  }

  render() {
    const { open, anchorEl, onClose, classes, onSave } = this.props
    const { startTime, endTime } = this.state

    return (
      <Popover
        onClose={() => onClose()}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <TimeSelector
            label="Start time"
            time={startTime}
            onChange={time => {
              this.setState({ startTime: time })
            }}
          />
        </div>
        <div>
          <TimeSelector
            label="End time"
            time={endTime}
            onChange={time => {
              if (startTime.intValue > time.intValue) {
                return
              }
              this.setState({ endTime: time })
            }}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color={'primary'}
            fullWidth
            disabled={isBlank(startTime) || isBlank(endTime)}
            onClick={() => {
              onSave({
                startTime: startTime,
                endTime: endTime,
              })
              this.setState({
                startTime: this.props.defaultStartTime,
                endTime: this.props.defaultEndTime,
              })
            }}
          >
            Save
          </Button>
        </div>
      </Popover>
    )
  }
}

SchedulePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultStartTime: PropTypes.string,
  defaultEndTime: PropTypes.string,
}

SchedulePopover.defaultProps = {
  defaultStartTime: '8:00',
  defaultEndTime: '17:00',
}
