import React, { PureComponent } from 'react'
import { isBlank } from 'src/common/ObjectHelper'
import { PropTypes } from 'prop-types'

export default class SectionParent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isBeingEdited: false,
    }
  }

  handleCancelButton() {
    this.setState({ isBeingEdited: false })
  }

  handleSaveButton(newContentData) {
    const { onSaveEdit } = this.props
    onSaveEdit(newContentData)
    this.setState({ isBeingEdited: false })
  }

  handleEditButtonClick() {
    this.setState({ isBeingEdited: true })
  }

  render() {
    const { contentData, sectionTitle, inEditMode, isSaving } = this.props
    const { isBeingEdited } = this.state

    if (isBlank(contentData)) {
      return null
    }

    if (isBeingEdited || isSaving) {
      return (
        <this.props.sectionComponentInEdit
          sectionTitle={sectionTitle}
          contentData={contentData}
          exitBeingEdited={() => this.handleCancelButton()}
          onDataChange={newContentData => this.handleSaveButton(newContentData)}
          isSaving={isSaving}
        />
      )
    } else {
      return (
        <this.props.sectionComponent
          sectionTitle={sectionTitle}
          contentData={contentData}
          canEdit={inEditMode}
          onEditClick={() => this.handleEditButtonClick()}
        />
      )
    }
  }
}

SectionParent.propTypes = {
  sectionTitle: PropTypes.string,
  contentData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  inEditMode: PropTypes.bool,
  onSaveEdit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

SectionParent.defaultProps = {
  sectionTitle: '',
  inEditMode: false,
  isSaving: false,
}
