import React from 'react'
import PropTypes from 'prop-types'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = {
  panel: {
    boxShadow: 'none',
    marginBottom: 0,
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  answer: {
    whiteSpace: 'pre-line',
  },
  questionPadding: {
    padding: 0,
  },
}

function SingleFAQWithExpansionPanel({ classes, faq }) {
  return (
    <ExpansionPanel className={classes.panel}>
      <ExpansionPanelSummary className={classes.questionPadding} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{faq.question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography variant="body1" className={classes.answer}>
          {faq.answer}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

SingleFAQWithExpansionPanel.propTypes = {
  faq: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(SingleFAQWithExpansionPanel)
