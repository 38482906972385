import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import BannerParent from './Banner/BannerParent'

const styles = theme => ({
  container: {
    marginTop: 0,
    marginBottom: 30,
    overflow: 'hidden',
    position: 'relative',
  },
})

function Title({
  classes,
  inEditMode,
  desktopBannerUrls,
  mobileBannerUrls,
  isMobile,
  title,
  description,
}) {
  return (
    <div className={classes.container}>
      <BannerParent
        desktopBannerUrls={desktopBannerUrls}
        mobileBannerUrls={mobileBannerUrls}
        inEditMode={inEditMode}
        isMobile={isMobile}
        title={title}
        description={description}
      />
    </div>
  )
}

Title.propTypes = {
  inEditMode: PropTypes.bool,
  isMobile: PropTypes.bool,
  desktopBannerUrls: PropTypes.object,
  mobileBannerUrls: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
}

Title.defaultProps = {
  inEditMode: false,
}

export default withStyles(styles)(Title)
