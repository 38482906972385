import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import { CancelSaveButtons } from 'housecall-ui'

const styles = {
  actionContainer: {
    padding: 16,
  },
  container: {
    zIndex: 100,
  },
}

function EditFormDialog({
  classes,
  updating,
  open,
  onClose,
  onSubmit,
  title,
  children,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className={classes.container}
    >
      <form onSubmit={values => onSubmit(values)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <CancelSaveButtons
            onCancelClick={() => {
              onClose()
            }}
            disabled={updating}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}


EditFormDialog.propTypes = {
  updating: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
}

export default withStyles(styles)(EditFormDialog)