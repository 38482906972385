import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { isNumber } from 'src/common/Form/FormValidations'
import { isBlank } from 'src/common/ObjectHelper'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import IsEditingSection from '../../Sections/IsEditingSection'
import { ServiceAreaMap } from 'housecall-ui'
import AddressInformation from '../../../Checkout/CustomerServiceInformation/AddressInformation'
import ServiceAreaEditRadius from './ServiceAreaEditRadius'
import {
  getAddressStreet,
  getAddressStreetLineTwo,
  getAddressCity,
  getAddressState,
  getAddressZip,
} from 'src/Resources/Organization/Data'
import {
  getServiceAreaLatitude,
  getServiceAreaLongitude,
  getServiceAreaRadius,
  getServiceAreaPolygon,
} from 'src/Resources/CompanyProfile/ServiceArea/Data'
import { getShowPhysicalAddress } from 'src/Resources/WebsiteContent/Data.js'
import { validate } from '../Redux/Actions'
import { isPolygonEdited } from '../Redux/Data'
import { updatePolygonEdited } from '../Redux/Actions'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_RADIUS,
} from '../ServiceAreaParent'

export const SERVICE_AREA_FORM = 'SERVICE_AREA_FORM'

@connect(
  state => ({
    initialValues: {
      streetAddress: getAddressStreet(state),
      streetLineTwo: getAddressStreetLineTwo(state),
      city: getAddressCity(state),
      state: getAddressState(state),
      postalCode: getAddressZip(state),
      latitude: getServiceAreaLatitude(state),
      longitude: getServiceAreaLongitude(state),
      radius: getServiceAreaRadius(state),
      polygon: getServiceAreaPolygon(state),
    },
    updatedStreet: formValueSelector(SERVICE_AREA_FORM)(state, 'streetAddress'),
    updatedStreetLineTwo: formValueSelector(SERVICE_AREA_FORM)(
      state,
      'streetLineTwo'
    ),
    updatedCity: formValueSelector(SERVICE_AREA_FORM)(state, 'city'),
    updatedState: formValueSelector(SERVICE_AREA_FORM)(state, 'state'),
    updatedZip: formValueSelector(SERVICE_AREA_FORM)(state, 'postalCode'),
    updatedLatitude: formValueSelector(SERVICE_AREA_FORM)(state, 'latitude'),
    updatedLongitude: formValueSelector(SERVICE_AREA_FORM)(state, 'longitude'),
    updatedRadius: formValueSelector(SERVICE_AREA_FORM)(state, 'radius'),
    updatedPolygon: formValueSelector(SERVICE_AREA_FORM)(state, 'polygon'),
    polygonEdited: isPolygonEdited(state),
    showPhysicalAddress: getShowPhysicalAddress(state),
  }),
  { formValueSelector, isPolygonEdited, updatePolygonEdited }
)
@reduxForm({
  form: SERVICE_AREA_FORM,
  validate,
})
export default class ServiceAreaInEdit extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      previousPolygonEditedState: false,
      radiusInvalid: false,
      resetPolygonShape: false,
      polygon: [],
      showPhysicalAddress: props.showPhysicalAddress,
    }
  }

  componentDidMount() {
    const { contentData } = this.props
    this.setState({ polygon: contentData.serviceArea.polygon })
  }

  setPolygonCoordinates(coordinates) {
    this.setState({ polygon: coordinates })
  }

  handlePolygonEdit(coordinates) {
    const { updatePolygonEdited } = this.props
    this.setState({ previousPolygonEditedState: true, polygon: coordinates })
    updatePolygonEdited(true)
  }

  handleRadiusChange(newRadius) {
    this.setState({
      radiusInvalid:
        !isNumber(newRadius) || isBlank(newRadius) || newRadius === 0,
    })
  }

  handleClearMap() {
    const { updatePolygonEdited, polygonEdited } = this.props
    this.setState({
      previousPolygonEditedState: polygonEdited,
      resetPolygonShape: true,
    })
    Promise.resolve(updatePolygonEdited(false)).then(() => {
      this.setState({ resetPolygonShape: false })
    })
  }

  handleCancel() {
    const { exitBeingEdited, updatePolygonEdited } = this.props
    const { previousPolygonEditedState } = this.state
    updatePolygonEdited(previousPolygonEditedState)
    exitBeingEdited()
  }

  handleSave() {
    const {
      onDataChange,
      updatedStreet,
      updatedStreetLineTwo,
      updatedCity,
      updatedState,
      updatedZip,
      updatedLatitude,
      updatedLongitude,
      updatedRadius,
    } = this.props
    const { polygon, showPhysicalAddress } = this.state

    const newData = {
      service_area: {
        street: updatedStreet,
        street_line_2: updatedStreetLineTwo,
        city: updatedCity,
        state: updatedState,
        zip: updatedZip,
        polygon: polygon,
        latitude: updatedLatitude,
        longitude: updatedLongitude,
        radius: Number(updatedRadius),
      },
      show_physical_address: showPhysicalAddress
    }
    onDataChange(newData)
  }

  render() {
    const {
      sectionTitle,
      contentData,
      polygonEdited,
      updatedLatitude,
      updatedLongitude,
      updatedRadius,
      updatedStreet,
      isSaving,
    } = this.props
    const { radiusInvalid, resetPolygonShape, showPhysicalAddress } = this.state
    const serviceArea = contentData.serviceArea

    return (
      <IsEditingSection
        title={sectionTitle}
        onCancelClick={() => this.handleCancel()}
        onSaveClick={() => this.handleSave()}
        disabled={radiusInvalid}
        isSaving={isSaving}
      >
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <AddressInformation
              reduxFormName={SERVICE_AREA_FORM}
              streetAddress={updatedStreet}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPhysicalAddress}
                  onChange={event =>
                    this.setState({ showPhysicalAddress: event.target.checked })
                  }
                  color="primary"
                />
              }
              label="This is a physical store (show on my website)"
            />
          </Grid>
          <Grid item xs={12}>
            <ServiceAreaEditRadius
              onRadiusChange={newRadius => this.handleRadiusChange(newRadius)}
              onClearMap={() => this.handleClearMap()}
              isPolygonEdited={polygonEdited}
            />
          </Grid>
          <Grid item xs={12}>
            <ServiceAreaMap
              latitude={
                isBlank(serviceArea)
                  ? DEFAULT_LATITUDE
                  : Number(updatedLatitude)
              }
              longitude={
                isBlank(serviceArea)
                  ? DEFAULT_LONGITUDE
                  : Number(updatedLongitude)
              }
              radius={
                isBlank(serviceArea) || serviceArea.radius <= 0.0
                  ? DEFAULT_RADIUS
                  : Number(updatedRadius)
              }
              polygon={serviceArea.polygon}
              isBeingEdited={true}
              showMarker={true}
              resetPolygonShape={resetPolygonShape}
              onPolygonEdit={coordinates => this.handlePolygonEdit(coordinates)}
              onRadiusChange={coordinates =>
                this.setPolygonCoordinates(coordinates)
              }
              onResetPolygonShape={coordinates =>
                this.setPolygonCoordinates(coordinates)
              }
            />
          </Grid>
        </Grid>
      </IsEditingSection>
    )
  }
}

ServiceAreaInEdit.propTypes = {
  exitBeingEdited: PropTypes.func.isRequired,
  onDataChange: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  contentData: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
}

ServiceAreaInEdit.defaultProps = {
  sectionTitle: '',
  isSaving: false,
}
