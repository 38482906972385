import React from 'react'
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PropTypes } from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  editDeleteButtonText: {
    marginBottom: -40,
  },
}

function EditModeTestimonialHeader({
  classes,
  editting,
  onEditClick,
  onDeleteClick,
  canEdit,
  canDelete,
}) {
  return (
    <Grid container justify="flex-end" alignItems="center" spacing={8}>
      <Grid item className={classes.editDeleteButtonText}>
        <Grid container>
          {canEdit && !editting && (
            <Grid item>
              <Tooltip title="Edit" onClick={() => onEditClick()}>
                <IconButton>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {canDelete && (
            <Grid item>
              <Tooltip title="Delete" onClick={() => onDeleteClick()}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

EditModeTestimonialHeader.propTypes = {
  editting: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
}

EditModeTestimonialHeader.defaultProps = {
  editting: false,
  canEdit: false,
  canDelete: false,
}

export default withStyles(styles)(EditModeTestimonialHeader)
