import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import GoogleMapsAutoComplete from 'src/common/GoogleMaps/GooglePlacesAutoComplete'
import FormTextField from 'src/common/Form/FormTextField'
import { PropTypes } from 'prop-types'
import { stringifyLocation } from 'src/common/StringifyLocation'
import CancelContinueButtons from 'src/common/CancelContinueButtons'
import { isBlank } from 'src/common/ObjectHelper'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
} from '@material-ui/core'

const SERVICE_LOCATION_FORM = 'SERVICE_LOCATION_FORM'

const styles = {
  dialogContainer: {
    zIndex: 100,
  },
  dialogContent: {
    paddingTop: 24,
  },
}
@connect(
  null,
  {
    change,
  }
)
@reduxForm({ form: SERVICE_LOCATION_FORM })
@withStyles(styles)
export default class ServiceLocationPopUpDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showGoogleAutoComplete: true,
    }
  }

  onAutoComplete(serviceLocation) {
    const { onLocationSelected, change } = this.props

    change('serviceLocation', stringifyLocation(serviceLocation))
    this.setState({ showGoogleAutoComplete: false })
    onLocationSelected(serviceLocation)
  }

  render() {
    const { showGoogleAutoComplete } = this.state
    const {
      open,
      serviceLocation,
      onClose,
      onComplete,
      classes,
      handleSubmit,
    } = this.props

    return (
      <Dialog
        fullWidth
        open={open}
        onClose={() => onClose()}
        className={classes.dialogContainer}
      >
        <DialogTitle>Enter your service address</DialogTitle>
        <form onSubmit={handleSubmit(() => onComplete())}>
          <DialogContent className={classes.dialogContent}>
            {showGoogleAutoComplete ? (
              <GoogleMapsAutoComplete
                label="Enter your service address"
                name="serviceLocation"
                autoFocus={true}
                onAutoComplete={serviceLocation => {
                  this.onAutoComplete(serviceLocation)
                }}
                id="booking"
                inputComponent={TextField}
                inputProps={{ variant: 'outlined' }}
                value={stringifyLocation(serviceLocation)}
                fullWidth
              />
            ) : (
              <FormTextField
                label="Enter your service address"
                name="serviceLocation"
                id="booking"
                variant="outlined"
                autoFocus
                fullWidth
                onClick={() => {
                  this.setState({ showGoogleAutoComplete: true })
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <CancelContinueButtons
              saveButtonVariant="text"
              onCancelClick={() => onClose()}
              disabled={isBlank(serviceLocation)}
            />
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

ServiceLocationPopUpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  serviceLocation: PropTypes.object,
  onLocationSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}
